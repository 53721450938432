import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as Promise from 'bluebird';

import Spinner from '../../components/Spinner/Spinner';
import TrailersFilter from '../../components/Trailers/TrailersFilter';
import RolesConst from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import AccountStore from '../../stores/AccountStore';
import InterfaceStore from '../../stores/InterfaceStore';
import InterfacesTable from '../../components/NetworkInterfaces/InterfacesTable';
import InterfacesFilter from '../../components/NetworkInterfaces/InterfacesFilter';

interface Props {
	interface?: InterfaceStore;
	account: AccountStore;
}

interface State {
	loading?: boolean;
	skip: number;
	take: number;
	searchFilter: string;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('interface', 'account')
@observer
class InterfaceListContainer extends React.Component<Props, State> {

	static allowedRoles = [
		RolesConst.admin,
		RolesConst.assembly_line,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			skip: 0,
			take: 15,
			searchFilter: '',
			errorOpen: false,
			errorMessage: '',
		};
	}

	componentDidMount() {
		this.load(this.state.skip, this.state.take, '');
	}

	load(skip: number, take: number, filter: string) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		Promise.all([
			this.props.interface.loadApplication(),
			this.props.interface.load(skip, take, filter),
		])
			.then(() => {
				this.setState({
					loading: false,
					skip,
					take,
					searchFilter: filter,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Network Interface Devices: ${err.message}`,
				});
			});
	}

	onPrev() {
		let skip = this.state.skip - this.state.take;
		if (skip < 0) {
			skip = 0;
		}

		this.load(skip, this.state.take, this.state.searchFilter);
	}

	onNext() {
		let skip = this.state.skip + this.state.take;
		if (skip > this.props.interface.count) {
			skip = this.props.interface.count - this.state.take;
		}

		this.load(skip, this.state.take, this.state.searchFilter);
	}

	onSearchFilterChange(value: string) {
		// If this is not an admin role, and the search terms are empty or to small do nothing.
		// This is to prevent non-admin roles from doing large searches just to look at users.
		if (value.length < 3 && !this.props.account.userHasRole(RolesConst.admin)) {
			return;
		}

		this.load(0, this.state.take, value);
	}

	render() {
		const devices = this.props.interface.items;
		const application = this.props.interface.application;

		return (
			<div>
				<h1>Network Interface Devices</h1>
				<Spinner loading={this.state.loading} />
				<InterfacesFilter
					searchValue={this.state.searchFilter}
					onSearchFilterChange={this.onSearchFilterChange.bind(this)}
				/>
				<InterfacesTable
					devices={devices}
					application={application}
					skip={this.state.skip}
					take={this.state.take}
					total={this.props.interface.count}
					pagination={true}
					onPrev={this.onPrev.bind(this)}
					onNext={this.onNext.bind(this)}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default InterfaceListContainer;
