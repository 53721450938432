import * as React from 'react';
import { inject, observer } from 'mobx-react';

import Spinner from '../../components/Spinner/Spinner';
import UserStore from '../../stores/UserStore';
import UsersTable from '../../components/Users/UsersTable';
import UsersFilter from '../../components/Users/UsersFilter';
import RolesConst from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import AccountStore from '../../stores/AccountStore';

interface Props {
	users?: UserStore;
	account: AccountStore;
}

interface State {
	loading?: boolean;
	skip: number;
	take: number;
	searchFilter: string;
	selectedRole: Parse.Role;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('users', 'account')
@observer
class UsersListContainer extends React.Component<Props, State> {

	static allowedRoles = [
		RolesConst.admin,
		RolesConst.customer_service,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			skip: 0,
			take: 10,
			searchFilter: '',
			selectedRole: null,
			errorOpen: false,
			errorMessage: '',
		};
	}

	componentDidMount() {
		if (this.props.account.userHasRole(RolesConst.admin)) {
			this.loadUsers(this.state.skip, this.state.take, '', null);
		}
	}

	loadUsers(skip: number, take: number, filter: string, role: Parse.Role) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		this.props.users.loadUsers(skip, take, filter, role)
			.then(() => this.props.users.loadRoles())
			.then(() => {
				this.setState({
					loading: false,
					skip,
					take,
					searchFilter: filter,
					selectedRole: role,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Users: ${err.message}`,
				});
			});
	}

	onRoleChange(userId: string, selectedRoles: any) {
		this.setState({ loading: true });

		const roles = selectedRoles.map(r => r.value);

		this.props.users.updateUserRoles(userId, roles)
			// There is a bug in Parse JS SDK, so we need to re-load the roles when we update user roles
			// https://github.com/parse-community/Parse-SDK-JS/issues/300
			.then(() => this.props.users.loadRoles())
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error updating roles: ${err.message}`,
				});
			});
	}

	onPrev() {
		let skip = this.state.skip - this.state.take;
		if (skip < 0) {
			skip = 0;
		}

		this.loadUsers(skip, this.state.take, this.state.searchFilter, this.state.selectedRole);
	}

	onNext() {
		let skip = this.state.skip + this.state.take;
		if (skip > this.props.users.count) {
			skip = this.props.users.count - this.state.take;
		}

		this.loadUsers(skip, this.state.take, this.state.searchFilter, this.state.selectedRole);
	}

	onRoleFilterChange(selectedRole: any) {
		// If this is not an admin role, and no search filter exists, then do nothing.
		// This is to prevent non-admin roles to select a role and see everyone without searching.
		if (!this.props.account.userHasRole(RolesConst.admin) && !this.state.searchFilter) {
			return;
		}

		let role = (selectedRole === 'all') ? null : this.props.users.getRoleById(selectedRole.value);

		this.loadUsers(0, this.state.take, this.state.searchFilter, role);
	}

	onSearchFilterChange(value: string) {
		// If this is not an admin role, and the search terms are empty or to small do nothing.
		// This is to prevent non-admin roles from doing large searches just to look at users.
		if (value.length < 3 && !this.props.account.userHasRole(RolesConst.admin)) {
			return;
		}

		this.loadUsers(0, this.state.take, value, this.state.selectedRole);
	}

	render() {
		const users = this.props.users.users;
		const roles = this.props.users.roles;

		return (
			<div>
				<h1>Users</h1>
				<p>Change a user's role by adding and removing them with the dropdown list</p>
				<Spinner loading={this.state.loading} />
				<UsersFilter
					roles={roles}
					searchValue={this.state.searchFilter}
					selectedRole={this.state.selectedRole}
					onRoleFilterChange={this.onRoleFilterChange.bind(this)}
					onSearchFilterChange={this.onSearchFilterChange.bind(this)}
				/>
				<UsersTable
					skip={this.state.skip}
					take={this.state.take}
					total={this.props.users.count}
					users={users}
					roles={roles}
					onRoleChange={this.onRoleChange.bind(this)}
					onPrev={this.onPrev.bind(this)}
					onNext={this.onNext.bind(this)}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default UsersListContainer;
