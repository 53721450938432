import * as React from 'react';

import { CardListItem, CardLabel, CardValue } from './styles';

const Card = ({ className = '', label, value }) => (
	<CardListItem className={className}>
		<CardLabel>{label}</CardLabel>
		<CardValue>{value || <em>N/A</em>}</CardValue>
	</CardListItem>
);

export default Card;