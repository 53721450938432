import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { MobxRouter } from 'mobx-router';
import * as Parse from 'parse';
import { injectGlobal } from 'styled-components';

import AccountStore from './stores/AccountStore';

interface AppProps {
	account: AccountStore;
}

injectGlobal`
	html {
		box-sizing: border-box;
		cursor: default;
		height: 100%;
		width: 100%;
		line-height: 1.5;
		-ms-text-size-adjust: 100%;
		-webkit-text-size-adjust: 100%
	}
	
	body {
		display: block;
		height: 100%;
		width: 100%;
		margin: 0;
		color: #444;
		font-family: ProximaNova-Regular, Helvetica Neue, Helvetica, Arial, sans-serif;
	}

	h1, h2, h3, h4 {
		font-weight: 400;
		margin: 0.4rem 0 0.6rem 0;
	}

	h1 {
		font-size: 2em;
		margin: 0 0 .5em;
	}

	h2 {
		font-size: 1.5em;
		margin: .35em 0;
	}

	p {
		margin-top: 0.1rem;
	}

	#root {
		background-color: #fafafa;
		min-height: 100%;
		min-width: 100%;
		height: 100%;
	}

	#root > div {
		height: 100%;
		width: 100%;
	}
`;

@inject('account')
@observer
class App extends React.Component<AppProps, {}> {

	constructor(props: AppProps) {
		super(props);
		
		props.account.setUser(Parse.User.current());
		props.account.loadRoles();
	}

	render() {
		return (
			<MobxRouter />
		);
	}
}

export default App;
