import * as React from 'react';
import * as Modal from 'react-modal';

import { ModalStyles, OkButton, CancelButton } from './styles';

Modal.setAppElement('#root');

interface Props {
	isOpen: boolean;
	title: string;
	message: string | JSX.Element;
	okLabel: string;
	onOk: any;
	cancelButton?: boolean;
	cancelLabel?: string;
	onCancel?: any;
}

class ConfirmModal extends React.Component<Props, {}> {

	onCancel() {
		this.props.onCancel();
	}

	renderCancelButton() {
		const cancelLabel = this.props.cancelLabel || 'Cancel';

		if (this.props.cancelButton) {
			return (
				<CancelButton onClick={this.props.onCancel}>
					{cancelLabel}
				</CancelButton>
			);
		}
	}

	onRequestClose() {
		if (this.props.onCancel) {
			this.props.onCancel();
		}
	}

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				style={ModalStyles}
				onRequestClose={this.onRequestClose.bind(this)}
			>
				<h2>{this.props.title}</h2>
				<p>{this.props.message}</p>
				
				{this.renderCancelButton()}
				<OkButton onClick={this.props.onOk}>
					{this.props.okLabel}
				</OkButton>
			</Modal>
		);
	}

}

export default ConfirmModal;
