import * as React from 'react';
import { ClipLoader } from 'react-spinners';

import { Panel } from './styles';

const Spinner = ({ loading = true }) => {
	const style = { display: loading ? 'block' : 'none' };

	return (
		<Panel style={style}>
			<ClipLoader size={60} loading={loading} />
		</Panel>
	);
};

export default Spinner;