import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';
import { Promise } from 'bluebird';

import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import InterfaceStore from '../../stores/InterfaceStore';
import InterfaceDetails from '../../components/NetworkInterfaces/InterfaceDetails';
import TrailerStatus from '../../components/Trailers/TrailerStatus';
import InterfaceActions from '../../components/NetworkInterfaces/InterfaceActions';
import InterfaceLogs from '../../components/NetworkInterfaces/InterfaceLogs';

interface Props {
	store?: RouterStore;
	interface?: InterfaceStore;
}

interface State {
	loading: boolean;
	device: any;
	confirmTitle: string;
	confirmOpen: boolean;
	confirmMessage: string | JSX.Element;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('interface', 'store')
@observer
class InterfaceContainer extends React.Component<Props, State> {

	timer;

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			device: null,
			confirmTitle: '',
			confirmOpen: false,
			confirmMessage: '',
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	componentDidMount() {
		this.props.interface.setItem(null);
		this.props.interface.clearLogs();
		this.load();

		this.timer = setInterval(this.loadDevice.bind(this), 5000);
	}

	componentWillUnmount() {
		clearInterval(this.timer);
		this.timer = null;
	}

	load() {
		this.setState({ loading: true });

		Promise.all([
			this.props.interface.loadApplication(),
			this.props.interface.loadDeviceById(this.props.store.router.params.id),
			this.props.interface.loadDeviceLogsById(this.props.store.router.params.id),
		])
			.then(_ => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Network Interface: ${err.message}`,
				});
			});
	}

	loadDevice() {
		return Promise.all([
			this.props.interface.loadDeviceById(this.props.store.router.params.id),
			this.props.interface.loadDeviceLogsById(this.props.store.router.params.id),
		])
			.catch (err => {
				console.log('Error refreshing device status:', err);
			});
	}

	onRestart() {
		this.setState({ loading: true });

		this.props.interface.restartDeviceById(this.props.store.router.params.id)
			.then(_ => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error restarting Network Interface: ${err.message}`,
				});
			});
	}

	onReboot() {
		this.setState({ loading: true });

		this.props.interface.rebootDeviceById(this.props.store.router.params.id)
			.then(_ => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error restarting Network Interface: ${err.message}`,
				});
			});
	}

	onOsUpdate() {
		this.setState({ loading: true });

		this.props.interface.updateOsById(this.props.interface.item.uuid)
			.then(_ => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error restarting Network Interface: ${err.message}`,
				});
			});
	}

	render() {
		const device = this.props.interface.item;
		const application = this.props.interface.application;
		const logs = this.props.interface.logs;

		if (!device) {
			return (
				<div>
					<h2>About Network Interface Device</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		return (
			<div>
				<TrailerStatus online={device.online} />
				<h2>About Network Interface Device</h2>
				<Spinner loading={this.state.loading} />

				<InterfaceDetails device={device} application={application} />

				<InterfaceActions
					osVersion={device.os_version}
					onRestart={this.onRestart.bind(this)}
					onReboot={this.onReboot.bind(this)}
					onOsUpdate={this.onOsUpdate.bind(this)}
				/>

				<InterfaceLogs
					logs={logs}
				/>

				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel="Ok"
					onOk={() => this.setState({ confirmOpen: false })}
					cancelButton={false}
				/>
			</div>
		);
	}
}

export default InterfaceContainer;
