import * as React from 'react';
import { Route } from 'mobx-router';

import Main from './containers/MainContainer';
import OverviewContainer from './containers/OverviewContainer';
import TrailersListContainer from './containers/PhysicalTrailers/TrailersListContainer';
import UsersListContainer from './containers/Users/UsersListContainers';
import ProvisioningContainer from './containers/PhysicalTrailers/ProvisioningContainer';
import TrailerContainer from './containers/PhysicalTrailers/TrailerContainer';
import UserContainer from './containers/Users/UserContainer';
import TrailerModelListContainer from './containers/TrailerModels/TrailerModelListContainer';
import TrailerModelContainer from './containers/TrailerModels/TrailerModelContainer';
import RouterListContainer from './containers/Routers/RouterListContainer';
import RouterContainer from './containers/Routers/RouterContainer';
import ImportRoutersContainer from './containers/Routers/ImportRoutersContainer';
import AddTrailerModelContainer from './containers/TrailerModels/AddTrailerModelContainer';
import FleetLocationsContainer from './containers/PhysicalTrailers/FleetLocationsContainer';
import AddRouterContainer from './containers/Routers/AddRouterContainer';
import UploadConfigContainer from './containers/CzoneConfigs/UploadConfigContainer';
import ConfigContainer from './containers/CzoneConfigs/ConfigContainer';
import InterfaceListContainer from './containers/NetworkInterfaces/InterfaceListContainer';
import InterfaceContainer from './containers/NetworkInterfaces/InterfaceContainer';
import HistoryLogsListContainer from './containers/HistoryLogs/HistoryLogsListContainer';
import VehicleManagementContainer from './containers/VehicleManagement/VehicleManagementContainer';

const routes = {

	overview: new Route({
		path: '/',
		component: <Main pageContent={OverviewContainer} />,
	}),

	login: new Route({
		path: '/login',
		component: <Main />,
	}),

	logout: new Route({
		path: '/logout',
		component: <Main />,
	}),

	users: new Route({
		path: '/users',
		component: <Main pageContent={UsersListContainer} />,
	}),

	userDetail: new Route({
		path: '/users/:id',
		component: <Main pageContent={UserContainer} />,
	}),

	userHistoryLogs: new Route({
		path: '/users/:userId/logs',
		component: <Main pageContent={HistoryLogsListContainer} />,
	}),

	fleetLocations: new Route({
		path: '/fleet',
		component: <Main pageContent={FleetLocationsContainer} />,
	}),

	trailers: new Route({
		path: '/trailers',
		component: <Main pageContent={TrailersListContainer} />,
	}),

	trailerProvisioning: new Route({
		path: '/trailers/provisioning',
		component: <Main pageContent={ProvisioningContainer} />,
	}),

	trailerDetail: new Route({
		path: '/trailers/:id',
		component: <Main pageContent={TrailerContainer} />,
	}),

	trailerHistoryLogs: new Route({
		path: '/trailers/:vehicleId/logs',
		component: <Main pageContent={HistoryLogsListContainer} />,
	}),

	vehicleManagement: new Route({
		path: '/trailers/:vehicleId/manage',
		component: <Main pageContent={VehicleManagementContainer} />,
	}),

	trailerModels: new Route({
		path: '/trailermodels',
		component: <Main pageContent={TrailerModelListContainer} />,
	}),

	newTrailerModel: new Route({
		path: '/trailermodels/add',
		component: <Main pageContent={AddTrailerModelContainer} />,
	}),

	trailerModel: new Route({
		path: '/trailermodels/:id',
		component: <Main pageContent={TrailerModelContainer} />,
	}),

	routers: new Route({
		path: '/routers',
		component: <Main pageContent={RouterListContainer} />,
	}),

	newRouter: new Route({
		path: '/routers/add',
		component: <Main pageContent={AddRouterContainer} />,
	}),

	importRouters: new Route({
		path: '/routers/import',
		component: <Main pageContent={ImportRoutersContainer} />,
	}),

	routerDetail: new Route({
		path: '/routers/:id',
		component: <Main pageContent={RouterContainer} />,
	}),

	uploadConfig: new Route({
		path: '/trailermodels/:modelId/czoneconfigs/upload',
		component: <Main pageContent={UploadConfigContainer} />,
	}),

	czoneConfigDetail: new Route({
		path: '/trailermodels/:modelId/czoneconfigs/:id',
		component: <Main pageContent={ConfigContainer} />,
	}),

	interfaces: new Route({
		path: '/interfaces',
		component: <Main pageContent={InterfaceListContainer} />,
	}),

	interfaceDetail: new Route({
		path: '/interfaces/:id',
		component: <Main pageContent={InterfaceContainer} />,
	}),

	catchAll: new Route({
		path: '/*',
		component: <Main pageContent={null} />,
	}),

};

export default routes;
