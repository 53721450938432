import * as React from 'react';

import Input from '../Forms/Input';
import { FilterContainer, FilterSearch } from './styles';

interface Props {
	searchValue: string;
	onSearchFilterChange: (filter: any) => void;
}

interface State {
	filterValue: string;
}

class TrailersFilter extends React.Component<Props, State> {

	private debounceTimer;

	constructor(props) {
		super(props);

		this.state = {
			filterValue: props.searchValue,
		};
	}

	onFilterChange(e) {
		this.setState({ filterValue: e.target.value });

		// Debounce the props change callback
		if (this.debounceTimer) {
			clearTimeout(this.debounceTimer);
		}

		this.debounceTimer = setTimeout(() => {
			this.props.onSearchFilterChange(this.state.filterValue);
		}, 1000); // Only call the callback after no more changes in 1 second
	}

	render() {
		return (
			<FilterContainer>
				<FilterSearch>
					<Input
						id="search"
						placeholder="Search by trailer ID, QR code, router serial number or model"
						onChange={this.onFilterChange.bind(this)}
						value={this.state.filterValue}
					/>
				</FilterSearch>
			</FilterContainer>
		);
	}
}

export default TrailersFilter;
