import styled from 'styled-components';

export const FilterContainer = styled.div`
	display: flex;
	flex: 1;
	margin: 0 0 15px;
	justify-content: space-between;
	align-items: flex-end;
`;

export const FilterSearch = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-size: small;
	min-width: 240px;
	width: 240px;
	justify-content: flex-start;
	flex: 1;
`;

export const ProvisionContainer = styled.div`
	.field {
		margin: 0 0 20px;

		label {
			margin: 0;
			display: inline-block;
			width: 200px;
			text-align: left;
			box-sizing: border-box;
			padding: 12px 0 0 12px;
			vertical-align: top;
			font-weight: 400;
			font-size: 14px;
			height: 40px;
		}

		input {
			margin: 0;
			display: inline-block;
			background-color: #fff;
			height: 40px;
			width: 50%;
			max-width: 350px;
			margin-right: 20px;
			border: 1px solid #ccc;
			font-weight: 400;
			font-size: 14px;
			padding: 0 14px;
			outline: 0;

			&.checkbox {
				width: 40px;
			}

			&:focus {
				background-color: #f5f4f4;
				outline: 0;
			}
		}
	}
`;

export const Badge = styled.span`
	display: inline-block;
	padding: 2px 12px;
	color: white;
	background-color: green;
	border-radius: 4px;
	font-size: 11px;
`;

export const TrailerModel = styled.span`
	position: absolute;	
	right: 7px;
	bottom: 3px;
	color: #888888;
	font-size: 13px;
`;