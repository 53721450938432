import * as React from 'react';
import { inject, observer } from 'mobx-react';

import TrailerStore from '../../stores/TrailerStore';
import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import LocationMap from '../../components/FleetLocations/LocationMap';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	trailer?: TrailerStore;
}

interface State {
	loading: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('trailer')
@observer
class FleetLocationsContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
	];

	componentDidMount() {
		this.setState({ loading: true });

		return this.props.trailer.loadTrailers(0, 1000, '')
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Trailers: ${err.message}`,
				});
			});
	}

	render() {
		const trailers = this.props.trailer.trailers.map(t => t.toJSON());

		return (
			<div>
				<h2>Airstream locations</h2>
				<p>Click on map marker for details.</p>
				<Spinner loading={this.state.loading} />

				<LocationMap trailers={trailers} />
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default FleetLocationsContainer;
