import styled from 'styled-components';

import Button from '../Forms/Button';

export const ModalStyles = {
	content: {
		top: '35%',
		left: 'calc(50% - 90px)',
		right: 'auto',
		bottom: 'auto',
		width: '440px',
		height: 'auto',
		borderRadius: '5px',
		padding: '25px',
		transform: 'translate(0%, -50%)',
	},
};

export const OkButton = styled(Button)`
	width: calc(50% - .25em);
`;

export const CancelButton = styled(Button)`
	background-color: transparent;
	border: 1px solid #888888;
	color: #888888;
	width: calc(50% - .25em);
	margin-right: .5em;
	padding: 11px;

	&:hover {
		background-color: transparent;
		border: 1px solid #666666;
		color: #666666;
	}

	&:active {
		background-color: transparent;
		border: 1px solid #666666;
		color: #666666;
	}
`;

export const TextArea = styled.textarea`
	width: 100%;
	height: 100px;
	border: 1px solid #aaaaaa;
	margin: 8px 0 18px;
	padding: 8px;
	resize: none;
	outline: none;
`;