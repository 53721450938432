import styled from 'styled-components';
import { Link } from 'mobx-router';

export const Nav = styled.nav`
	height: 100%;
	display: block;
`;

export const Header = styled.section`
	height: 54px;
	width: 250px;
	background-color: #08527e;
`;

export const ItemsContainer = styled.section`
	font-size: 16px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100% - 54px);
	background-color: #0F6CA5;
	width: 250px;
	overflow-y: auto;
`;

export const MenuLink = styled(Link)`
	background-color: #08527E;
	color: $text-color;
	text-decoration: none;
	padding: 0.7em;
	color: #bce0e8;

	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	&.active {
		background-color: #4BB248;
		color: #ffffff;
	}

	&:hover:not(.active):not(.disabled) {
		background-color: #106192;
		a {
			text-decoration: none;
		}
	}

	&.disabled {
		background-color: inherit;
		opacity: .2;
	}
`;

export const LinkIcon = styled.div`
	display: inline-block;
	text-align: center;
	width: 2em;
	margin-right: 0.7em;

	svg {
		height: 31px;
		width: 31px;

		// For font awesome and other image types
		display: inline-block;
		vertical-align: middle;
	}
`;

export const LinkContent = styled.div`
	display: inline-block;
	width: calc(100% - 2.7em);
`;