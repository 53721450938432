import * as React from 'react';
import { inject } from 'mobx-react';
import { RouterStore, Route } from 'mobx-router';

import { MenuLink, LinkIcon, LinkContent } from './styles';

interface Props {
	store?: RouterStore;
	name: string;
	route: Route;
	disabled?: boolean;
	active?: boolean;
	icon: any;
}

@inject('store')
class MenuItem extends React.Component<Props, {}> {

	render() {
		let className = this.props.active ? 'active' : '';

		return (
			<MenuLink
				view={this.props.route}
				store={this.props.store}
				className={className}
			>
				<LinkIcon>
					<this.props.icon />
				</LinkIcon>
				<LinkContent>
					<span>{this.props.name}</span>
				</LinkContent>
			</MenuLink>
		);
	}
}

export default MenuItem;
