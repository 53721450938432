import * as React from 'react';
import { observer } from 'mobx-react';
import * as moment from 'moment';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import Card from '../Details/Card';
import EditableCard from '../Details/EditableCard';
import { TransparentButton, SaveButton } from '../Users/styles';
import { EditIcon, CheckIcon } from '../Icons';

interface Props {
	router?: any;
	newRouter?: boolean;
	editable: boolean;
	onUpdate?: (changes: any) => void;
	onInfoReveal?: (field: string) => void;
}

interface State {
	editing: boolean;
	inputsChanged: boolean;
	deviceId: number;
	name: string;
	imei: number;
	mac: string;
	serial: string;
	admin_username: string;
	admin_password: string;
	ssid: string;
	password: string;
	guest_ssid: string;
	guest_password: string;
	qrcode: string;
}

@observer
class RouterDetails extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = this.getState(false, false);
	}

	onInputChange(key, value) {
		const newState = {
			inputsChanged: true,
		};
		newState[key] = value;

		this.setState(newState);
	}

	onEditClick() {
		this.setState(this.getState(true, false));
	}

	onCancelClick() {
		this.setState(this.getState(false, false));
	}

	getState(editing: boolean, changed: boolean): State {
		return {
			editing: this.props.newRouter || editing,
			inputsChanged: changed,
			deviceId: this.props.router.deviceId,
			name: this.props.router.name,
			imei: this.props.router.imei,
			mac: this.props.router.mac,
			serial: this.props.router.serial,
			admin_username: this.props.router.admin_username,
			admin_password: this.props.router.admin_password,
			ssid: this.props.router.ssid,
			password: this.props.router.password,
			guest_ssid: this.props.router.guest_ssid,
			guest_password: this.props.router.guest_password,
			qrcode: this.props.router.qrcode,
		};
	}

	onSaveClick() {
		const changes: any = {};

		Object.keys(this.state).forEach(key => {
			if (key === 'editing' || key === 'inputsChanged') {
				return;
			}

			const value = this.state[key];
			if (value !== this.props.router[key]) {
				if (isNaN(value)) {
					changes[key] = value;
				} else {
					changes[key] = parseFloat(value);
				}
			}
		});

		this.props.onUpdate(changes);
		this.setState({
			editing: false,
			inputsChanged: false,
		});
	}

	renderButtons() {
		const cancelButton = !this.props.newRouter ? (
			<TransparentButton
				onClick={this.onCancelClick.bind(this)}
			>
				Cancel
			</TransparentButton>
		) : '';

		return (this.props.newRouter || this.state.editing) ? (
			<div style={{ float: 'right', marginTop: '-40px', paddingBottom: '10px' }}>
				{cancelButton}
				<SaveButton
					onClick={this.onSaveClick.bind(this)}
					disabled={!this.state.inputsChanged}
				>
					<CheckIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Save Changes
				</SaveButton>
			</div>
		) : (
				<div style={{ float: 'right', marginTop: '-40px', paddingBottom: '10px' }}>
					<TransparentButton
						onClick={this.onEditClick.bind(this)}
					>
						<EditIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Edit Router
				</TransparentButton>
				</div>
			);
	}

	render() {
		const router = this.props.router;

		if (!router) {
			return (<h2>No Router Information Available</h2>);
		}

		const routerLastUpdated = moment(router.updatedAt);

		return (
			<div>
				{this.props.editable && this.renderButtons()}

				<DetailsHeader>Router Details</DetailsHeader>
				<CardContainer>
					<EditableCard
						label="Device ID"
						id="deviceId"
						value={this.state.deviceId}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Device Name"
						id="name"
						value={this.state.name}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="IMEI"
						id="imei"
						value={this.state.imei}
						className="last" 
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="MAC Address"
						id="mac"
						value={this.state.mac}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Serial Number"
						id="serial"
						value={this.state.serial}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="QR Code"
						id="qrcode"
						value={this.state.qrcode}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<Card className="last" label="Last Updated" value={!this.props.newRouter && routerLastUpdated.format('llll')} />
				</CardContainer>

				<DetailsHeader>Router Login Details</DetailsHeader>
				<CardContainer>
					<EditableCard
						label="Admin Username"
						id="admin_username"
						value={this.state.admin_username}
						className="half"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Admin Password"
						id="admin_password"
						value={this.state.admin_password}
						className="half last"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
						revealable={true}
						onReveal={() => this.props.onInfoReveal('admin password')}
					/>

					<EditableCard
						label="Wi-fi SSID"
						id="ssid"
						value={this.state.ssid}
						className="half"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Wi-fi Password"
						id="password"
						value={this.state.password}
						className="half last"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
						revealable={true}
						onReveal={() => this.props.onInfoReveal('wi-fi password')}
					/>

					<EditableCard
						label="Guest Wi-fi SSID"
						id="guest_ssid"
						value={this.state.guest_ssid}
						className="half"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Guest Wi-fi Password"
						id="guest_password"
						value={this.state.guest_password}
						className="half last"
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
						revealable={true}
						onReveal={() => this.props.onInfoReveal('guest wi-fi password')}
					/>
				</CardContainer>
			</div>
		);
	}
}

export default RouterDetails;
