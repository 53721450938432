import styled from 'styled-components';

export const Panel = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60px;
	height: 60px;
	z-index: 1000;
`;