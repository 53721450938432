import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { RouterStore, startRouter } from 'mobx-router';
import { Provider } from 'mobx-react';
import * as Parse from 'parse';
import { useStrict } from 'mobx';

import config from './config';
import App from './App';
import routes from './routes';
import AccountStore from './stores/AccountStore';
import TrailerStore from './stores/TrailerStore';
import UserStore from './stores/UserStore';
import TrailerModelStore from './stores/TrailerModelStore';
import TrailerRouterStore from './stores/TrailerRouterStore';

import './components/Icons/favicons/favicons';
import { HistoryLogStore } from './stores/HistoryLogStore';
import CzoneConfigStore from './stores/CzoneConfigStore';
import InterfaceStore from './stores/InterfaceStore';

let parseConfig = config.dev;

// TODO 2021: Remove buddy URLs
const productionHosts: Array<string> = [
	'portal.smartcontrol.airstream.com',
	'portal.smartcontrol-prod1.airstream.com',
	'airstream.buddy.io',
	'airstream.buddy.com',
	'airstream-prod1.buddy.io',
];

const devHosts: Array<string> = [
	'portal.smartcontrol-dev.airstream.com',
	'airstream-dev1.buddy.io',
];

if (productionHosts.includes(window.location.hostname)) {
	parseConfig = config.production;
} else if (devHosts.includes(window.location.hostname)) {
	parseConfig = config.staging;
}
console.log(`The Parse Config: ${JSON.stringify(parseConfig)}`);

useStrict(true);

Parse.initialize(parseConfig.applicationId);
(Parse as any).serverURL = parseConfig.url;
(Parse.Object as any).disableSingleInstance();

const routerStore = {
	router: new RouterStore(),
};

const accountStore = new AccountStore();
const trailerStore = new TrailerStore();
const userStore = new UserStore();
const trailerModelStore = new TrailerModelStore();
const trailerRouterStore = new TrailerRouterStore();
const historyLogStore = new HistoryLogStore();
const czoneConfigStore = new CzoneConfigStore();
const interfaceStore = new InterfaceStore();

const renderApp = (root: Element, AppComponent: React.ComponentClass) => {
	ReactDOM.render((
		<Provider
			store={routerStore}
			account={accountStore}
			trailer={trailerStore}
			users={userStore}
			models={trailerModelStore}
			routers={trailerRouterStore}
			logs={historyLogStore}
			config={czoneConfigStore}
			interface={interfaceStore}
		>
			<AppComponent />
		</Provider>
	), root);
};

startRouter(routes, routerStore);

const rootEl = document.getElementById('root');
renderApp(rootEl, App);

if (module.hot) {
	module.hot.accept('./App', function() {
		const NewApp = require('./App');

		renderApp(rootEl, NewApp);
	});
}
