import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';
import * as Set from 'set';

class InterfaceStore {

	@observable
	item: any = {};

	@observable
	items: any[] = [];

	@observable
	application: any = {};

	@observable
	logs: any = new Set([]);

	@observable
	count: number = 0;

	load(skip: number, take: number, filter: string): Promise<boolean> {
		const options: any = {
			skip: skip,
			take: take,
		};

		if (filter) {
			options.filter = filter;
		}

		const query = Parse.Cloud.run('getBalenaDevices', options);

		return Promise.resolve(query)
			.then(result => {
				this.setItems(result.devices);
				this.setCount(result.count);

				return true;
			});
	}

	loadApplication(): Promise<boolean> {
		const query = Parse.Cloud.run('getBalenaApplication');

		return Promise.resolve(query)
			.then(result => {
				this.setApplication(result);

				return true;
			});
	}

	loadDeviceById(id: number): Promise<boolean> {
		const query = Parse.Cloud.run('getBalenaDeviceById', { id });

		return Promise.resolve(query)
			.then(result => {
				this.setItem(result);

				return true;
			});
	}

	loadDeviceLogsById(id: number): Promise<boolean> {
		const query = Parse.Cloud.run('getBalenaDeviceLogsById', { id });

		return Promise.resolve(query)
			.then(result => {
				this.appendLogs(result);

				return true;
			});
	}

	restartDeviceById(id: number): Promise<boolean> {
		const query = Parse.Cloud.run('manageBalenaDevice', {
			id,
			action: 'restart',
		});

		return Promise.resolve(query)
			.then(() => {
				return true;
			});
	}

	rebootDeviceById(id: number): Promise<boolean> {
		const query = Parse.Cloud.run('manageBalenaDevice', {
			id,
			action: 'reboot',
		});

		return Promise.resolve(query)
			.then(() => {
				return true;
			});
	}

	updateOsById(uuid: number): Promise<boolean> {
		const query = Parse.Cloud.run('manageBalenaDevice', {
			id: uuid,
			action: 'update_os',
		});

		return Promise.resolve(query)
			.then(() => {
				return true;
			});
	}

	@action
	setApplication(app) {
		this.application = app;
	}

	@action
	setItems(items) {
		this.items.length = 0;

		this.items.push(...items);
	}

	@action
	setItem(item) {
		this.item = item;
	}

	@action
	appendLogs(logs) {
		for (let x = 0; x < logs.length; x++) {
			this.logs.add(logs[x]);
		}
	}

	@action
	clearLogs() {
		this.logs = new Set([]);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

}

export default InterfaceStore;
