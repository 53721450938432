import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
	color: #fff;
	cursor: pointer;
	background-color: #0F6CA5;
	min-width: 100px;
	padding: 12px;
	font-size: 16px;
	border-radius: 5px;
	text-transform: none;
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;

	&:disabled {
		cursor: not-allowed;
		opacity: 0.6;
	}

	&:hover:enabled {
		background-color: #0c5380;
	}

	&:active:enabled {
		background-color: #083856;
	}

	&:focus {
		outline: 0;
	}

	&.small {
		font-size: 12px;
		padding: 8px 12px;
	}

	&.medium {
		font-size: 14px;
		padding: 8px 12px;
	}

	&.secondary {
		background-color: #b1bbce;
	}

	&.secondary:hover {
		background-color: #819dc7;
	}

	&.secondary:active {
		background-color: #819dc7;
	}

	&.hollow {
		color: #888;
		border: 1px solid #888;
		background-color: transparent;
	}

	&.hollow:hover {
		background-color: transparent;
	}

	&.hollow:active {
		background-color: transparent;
	}

	&.warning {
		background-color: #cc6c5c;
	}

	&.warning:hover:enabled {
		background-color: #9e4334;
	}

	&.warning:active:enabled {
		background-color: #9e4334;
	}

	&.transparent {
		color: #888;
		background-color: transparent;
	}

	&.transparent:hover {
		background-color: transparent;
	}

	&.transparent:active {
		background-color: transparent;
	}

	&.link {
		color: #5988a6;
	}
`;

const Button = ({ children, onClick, disabled = false, style = {}, className = '' }) => (
	<StyledButton
		className={className}
		style={style}
		type="button"
		onClick={onClick}
		disabled={disabled}
	>
		{children}
	</StyledButton>
);

export default Button;