import * as React from 'react';

const FleetLocationIcon = ({ fill = null, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 54.58 76.56"
    aria-labelledby="title"
    className={className}
  >
    <title>Fleet Location</title>
    <g fill={fill}>
      <path d="M27.29,14.45A12.88,12.88,0,0,0,18,18.25a12.45,12.45,0,0,0-3.92,9.21,12.24,12.24,0,0,0,3.92,9,13.18,13.18,0,0,0,18.52,0,12.25,12.25,0,0,0,3.92-9,12.46,12.46,0,0,0-3.93-9.21A12.88,12.88,0,0,0,27.29,14.45Zm6.47,19.18a9.18,9.18,0,0,1-12.94,0,8.27,8.27,0,0,1-2.71-6.17,8.47,8.47,0,0,1,2.72-6.34,9.18,9.18,0,0,1,12.93,0,8.48,8.48,0,0,1,2.72,6.34A8.28,8.28,0,0,1,33.76,33.63ZM46.61,7.74A26.77,26.77,0,0,0,27.29,0,26.77,26.77,0,0,0,8,7.74,25.38,25.38,0,0,0,0,26.53C0,40.89,5.41,50.2,24,74.79a4.19,4.19,0,0,0,3.25,1.77,4.24,4.24,0,0,0,3.29-1.83c18.59-24.54,24-33.84,24-48.2A25.38,25.38,0,0,0,46.61,7.74ZM27.44,72.26l-.15.17-.1-.12C9.22,48.61,4,39.72,4,26.53A21.24,21.24,0,0,1,10.76,10.6,22.61,22.61,0,0,1,27.29,4a22.61,22.61,0,0,1,16.53,6.6,21.24,21.24,0,0,1,6.76,15.93C50.58,39.72,45.35,48.61,27.44,72.26Z" />
    </g>
  </svg>
);

export default FleetLocationIcon;
