import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import Button from '../../components/Forms/Button';
import roles from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import CzoneConfigStore from '../../stores/CzoneConfigStore';
import UploadConfigForm from '../../components/Configs/UploadConfigForm';
import Spinner from '../../components/Spinner/Spinner';
import routes from '../../routes';

interface Props {
	config?: CzoneConfigStore;
	store?: RouterStore;
}

interface State {
	loading?: boolean;
	configFile: File;
	configBase64: string;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('config', 'store')
@observer
class UploadConfigContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	private inputRef;

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			configFile: null,
			configBase64: '',
			errorOpen: false,
			errorMessage: '',
		};

		this.inputRef = React.createRef();
	}

	onFileChange(e) {
		const reader = new FileReader();
		reader.onloadend = () => {
			this.setState({
				configBase64: reader.result.toString().replace(/(data:.*;base64,)/, ''),
			});
		};
		reader.readAsDataURL(e.target.files[0]);

		this.setState({
			configFile: e.target.files[0],
		});
	}

	onSubmit(name: string, version: string, description: string) {
		this.setState({ loading: true });

		return this.props.config.uploadNewConfig({
			name: name,
			version: version,
			desc: description,
			file: this.state.configBase64,
			model: this.props.store.router.params.modelId,
		})
			.then(id => {
				this.props.store.router.goTo(routes.trailerModel, { id: this.props.store.router.params.modelId });
				this.setState({ loading: false });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Uploading Config: ${err.message}`,
				});
			});
	}

	render() {
		return (
			<div>
				<Spinner loading={this.state.loading} />
				<h1>Upload Czone Config</h1>
				<p>Upload a new Czone config file</p>
				<input
					style={{ display: 'none' }}
					type="file"
					onChange={this.onFileChange.bind(this)}
					ref={this.inputRef}
				/>
				<Button
					style={{ margin: '0 14px 20px 0' }}
					className="small secondary"
					onClick={() => this.inputRef.current.click()}
				>
					Choose File
				</Button>
				<span>
					{this.state.configFile ? this.state.configFile.name : 'No file chosen'}
				</span>

				<UploadConfigForm
					onSubmit={this.onSubmit.bind(this)}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default UploadConfigContainer;
