import * as React from 'react';
import { observer } from 'mobx-react';

import { TableContainer } from './styles';
import Header from './Header';
import Row from './Row';
import Pagination from './Pagination';

export interface TableColumn {
	name: string;
	key: string;
	render?: ({ data }) => JSX.Element;
	cellStyle?: any;
}

export interface RowData {
	[key: string]: any;
}

interface Props {
	label?: string;
	data: RowData[];
	columns: TableColumn[];
	skip?: number;
	take?: number;
	total?: number;
	pagination?: boolean;
	onPrev?: () => void;
	onNext?: () => void;
}

@observer
export class Table extends React.Component<Props, {}> {

	renderPagination() {
		if (this.props.pagination) {
			return (
				<Pagination
					label={this.props.label}
					skip={this.props.skip}
					take={this.props.take}
					total={this.props.total}
					onNext={this.props.onNext}
					onPrev={this.props.onPrev}
				/>
			);
		}
	}

	render() {
		const rows = this.props.data.map((data, index) => (
			<Row
				key={index}
				data={data}
				columns={this.props.columns}
			/>
		));

		return (
			<TableContainer>
				<Header columns={this.props.columns} />
				<div>
					{rows}
				</div>
				{this.renderPagination()}
			</TableContainer>
		);
	}
}
