import * as React from 'react';
import * as Quagga from 'quagga';
import styled from 'styled-components';

const VideoContainer = styled.div`
	overflow: hidden;

	canvas {
		display: none;
	}

	video {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

interface Props {
	dimensions: any;
	guidelines: any;
	onCompleted: any;
	onError: any;
}

interface State {
	inputStream: any;
	locator: any;
	numOfWorkers: number;
	frequency: number;
	decoder: any;
	locate: boolean;
}

class BarcodeReader extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			inputStream: {
				type: 'LiveStream',
				constraints: {
					facingMode: 'environment',
				},
				area: {
					top: `${(props.guidelines.y / props.dimensions.height) * 100}%`,
					left: `${(props.guidelines.x / props.dimensions.width) * 100}%`,
					right: `${((props.dimensions.width - (props.guidelines.x + props.guidelines.width)) / props.dimensions.width) * 100}%`,
					bottom: `${((props.dimensions.height - (props.guidelines.y + props.guidelines.height)) / props.dimensions.height) * 100}%`,
				},
			},
			locator: {
				patchSize: 'x-large',
				halfSample: true,
			},
			numOfWorkers: 8,
			frequency: 10,
			decoder: {
				readers: [{
					format: 'code_128_reader',
					config: {},
				}],
			},
			locate: false,
		};
	}

	componentDidMount() {
		Quagga.onDetected(result => {
			const barcode = result.codeResult.code;
			this.props.onCompleted(barcode);
		});

		Quagga.init(this.state, (err) => {
			if (err) {
				if (this.props.onError) {
					this.props.onError(err);
				}
			}

			Quagga.start();
		});
	}

	componentWillUnmount() {
		Quagga.stop();
	}

	render() {
		return (
			<VideoContainer
				id="interactive"
				className="viewport"
				style={{ width: `${this.props.dimensions.width}px`, height: `${this.props.dimensions.height}px` }}>
				</VideoContainer>
		);
	}

}

export default BarcodeReader;
