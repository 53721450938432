import * as React from 'react';
import * as Modal from 'react-modal';

import Button from './Button';
import Scanner from './Scanner';

Modal.setAppElement('#root');

const qrScanModalStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		transform: 'translate(-50%, -50%)',
	},
};

interface Props {
	type: string;
	label: string;
	onScan: any;
}

interface State {
	isOpen: boolean;
}

class ScannerInput extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}

	handleScannerCompleted(value) {
		if (this.state.isOpen && value) {
			this.props.onScan(value);
			this.setState({ isOpen: false });
		}
	}

	render() {
		return (
			<div style={{ display: 'inline-block' }}>
				<Button onClick={() => this.setState({ isOpen: true })}>Scan</Button>
				<Modal
					isOpen={this.state.isOpen}
					contentLabel={`Scan ${this.props.label}`}
					style={qrScanModalStyles}
				>
					<h2>{this.props.label}</h2>
					<Scanner
						width={400}
						height={400}
						type={this.props.type}
						completed={this.handleScannerCompleted.bind(this)}
					/>
					<br />
					<Button onClick={() => this.setState({ isOpen: false })}>Cancel</Button>
				</Modal>
			</div>
		);
	}

}

export default ScannerInput;
