import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import TrailerModelStore from '../../stores/TrailerModelStore';
import Spinner from '../../components/Spinner/Spinner';
import TrailerModelDetails from '../../components/TrailerModels/TrailerModelDetails';
import roles from '../../roles';
import routes from '../../routes';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	store?: RouterStore;
	models?: TrailerModelStore;
}

interface State {
	loading: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('models', 'store')
@observer
class AddTrailerModelContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
	];

	onModelCreate(values) {
		this.setState({ loading: true });

		return this.props.models.createModel(values)
			.then(savedTrailer => {
				this.props.store.router.goTo(routes.trailerModel, { id: savedTrailer.id });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error saving new trailer model: ${err.message}`,
				});
			});
	}

	render() {
		return (
			<div>
				<h2>Add New Trailer Model</h2>
				<Spinner loading={this.state.loading} />
				<TrailerModelDetails
					model={{}}
					newModel={true}
					editable={true}
					onModelUpdate={this.onModelCreate.bind(this)}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default AddTrailerModelContainer;
