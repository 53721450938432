import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import TrailerRouterStore from '../../stores/TrailerRouterStore';
import RoutersTable from '../../components/Routers/RoutersTable';
import RoutersFilter from '../../components/Routers/RoutersFilter';
import Button from '../../components/Forms/Button';
import routes from '../../routes';
import { AddNoteIcon } from '../../components/Icons';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	store?: RouterStore;
	routers?: TrailerRouterStore;
}

interface State {
	loading?: boolean;
	skip: number;
	take: number;
	searchFilter: string;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('routers', 'store')
@observer
class RouterListContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			skip: 0,
			take: 15,
			searchFilter: '',
			errorOpen: false,
			errorMessage: '',
		};
	}

	componentDidMount() {
		this.loadRouters(this.state.skip, this.state.take, this.state.searchFilter);
	}

	loadRouters(skip: number, take: number, filter: string) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		this.props.routers.loadRouters(skip, take, filter)
			.then(() => {
				this.setState({
					loading: false,
					skip,
					take,
					searchFilter: filter,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Routers: ${err.message}`,
				});
			});
	}

	onPrev() {
		let skip = this.state.skip - this.state.take;
		if (skip < 0) {
			skip = 0;
		}

		this.loadRouters(skip, this.state.take, this.state.searchFilter);
	}

	onNext() {
		let skip = this.state.skip + this.state.take;
		if (skip > this.props.routers.count) {
			skip = this.props.routers.count - this.state.take;
		}

		this.loadRouters(skip, this.state.take, this.state.searchFilter);
	}

	onSearchFilterChange(value: string) {
		this.loadRouters(this.state.skip, this.state.take, value);
	}

	onImportRouterClick() {
		this.props.store.router.goTo(routes.importRouters);
	}

	render() {
		const routers = this.props.routers.routers.map(m => m.toJSON());

		return (
			<div>
				<Button
					style={{ float: 'right' }}
					className="small secondary"
					onClick={this.onImportRouterClick.bind(this)}
				>
					Import Router CSV
				</Button>

				<h1>Peplink Routers</h1>
				<Spinner loading={this.state.loading} />
				<Link
					view={routes.newRouter}
					store={this.props.store}
					style={{ float: 'right', marginLeft: '16px', textDecoration: 'none' }}
				>
					<AddNoteIcon style={{ width: '16px', height: '16px', marginRight: '4px' }} /> Add New Router
				</Link>
				<RoutersFilter
					searchValue={this.state.searchFilter}
					onSearchFilterChange={this.onSearchFilterChange.bind(this)}
				/>
				<RoutersTable
					routers={routers}
					skip={this.state.skip}
					take={this.state.take}
					total={this.props.routers.count}
					onPrev={this.onPrev.bind(this)}
					onNext={this.onNext.bind(this)}
					pagination={true}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default RouterListContainer;
