import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
	color: #7f919c;
	cursor: pointer;
	background-color: #f3cdcd;
	min-width: 70px;
	flex: 1;
	padding: 3px 6px;
	font-size: 19px;
	border-radius: 4px;
	text-transform: none;
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;
	margin: 0 4px 0 4px;

	&:hover {
		background-color: #b82600;
		color: #fff;
	}

	&:active {
		background-color: #b82600;
		color: #fff;
	}

	&:focus {
		outline: 0;
	}
`;

const DeleteButton = ({ onClick, disabled = false }) => (
	<StyledButton type="button" onClick={onClick} disabled={disabled}>
		<i className="far fa-times-circle"></i>
	</StyledButton>
);

export default DeleteButton;