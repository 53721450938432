import * as React from 'react';
import { observer } from 'mobx-react';
import Select from 'react-select';
import 'react-select/dist/react-select.css';
import styled from 'styled-components';
import * as Parse from 'parse';

const Selector = styled(Select)`
	width: 100%;
	background-color: white;
	font-weight: 300;
	font-size: small;

	.Select-value {
		color: #095e90 !important;
		font-weight: 400;
	}
`;

interface Props {
	id?: string;
	roles: Parse.Role[];
	selectedRoles?: Parse.Role[];
	onRoleChange: (roles: any) => void;
	multi: boolean;
	isFilter?: boolean;
}

@observer
class RoleSelector extends React.Component<Props, {}> {

	getSelectedRoles() {
		if (this.props.isFilter) {
			if (!this.props.selectedRoles[0]) {
				return { value: 'all', label: 'All' };
			}

			return {
				value: this.props.selectedRoles[0].id,
				label: this.capitalize(this.props.selectedRoles[0].getName()),
			};
		}

		return this.props.selectedRoles.map(role => ({
			value: role.id,
			label: this.capitalize(role.getName()),
			clearableValue: this.props.selectedRoles.length > 1,
		}));
	}

	getAllRoles() {
		const options = [];

		if (this.props.isFilter) {
			options.push({ value: 'all', label: 'All' });
		}

		options.push(...this.props.roles.map(role => ({
			value: role.id,
			label: this.capitalize(role.getName()),
		})));

		return options;
	}

	capitalize(str) {
		let segments = str.split('_');

		return segments.map(segment => 
			segment.charAt(0).toUpperCase() + segment.substr(1))
			.join(' ');
	}

	render() {
		return (
			<Selector
				name="role-selector"
				value={this.getSelectedRoles()}
				onChange={this.props.onRoleChange}
				options={this.getAllRoles()}
				multi={this.props.multi}
				clearable={false}
				searchable={false}
			/>
		);
	}
}

export default RoleSelector;
