import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';

class TrailerModelStore {

	@observable
	models: Parse.Object[] = [];

	@observable
	count: number = 0;

	@observable
	currentModel: Parse.Object;

	loadTrailerModels(skip: number, take: number): Promise<boolean> {

		const query = new Parse.Query('trailermodel')
			.include('czone_config')
			.descending('year')
			.skip(skip)
			.limit(take);

		const countQuery = new Parse.Query('trailermodel').count();

		return Promise.resolve(query.find())
			.then(results => {
				this.setModels(results);
				this.setCount(results.length);

				return true;
			});
	}

	updateModel(id: string, values: any): Promise<void> {
		const query = new Parse.Query('trailermodel')
			.get(id);

		return Promise.resolve(query)
			.then(model => {
				return Promise.resolve(model.save(values));
			})
			.then(savedModel => {
				this.setCurrentModel(savedModel);
			});
	}

	updateCzoneConfig(configId: string): Promise<any> {
		const query = Parse.Cloud.run('updateTrailerModelWithConfig', {
			configId,
		});

		return Promise.resolve(query)
			.then(_ => {
				return true;
			});
	}

	createModel(values: any): Promise<Parse.Object> {
		const model = new Parse.Object('trailermodel');

		return Promise.resolve(model.save(values))
			.then(savedModel => {
				return savedModel;
			});
	}

	deleteModel(id: string): Promise<boolean> {
		let index = -1;

		for (let x = 0; x < this.models.length; x++) {
			if (this.models[x].id === id) {
				index = x;
				break;
			}
		}

		if (index === -1) {
			throw new Error('Trailer model not found');
		}

		return Promise.resolve(this.models[index].destroy())
			.then(() => {
				this.removeModel(index);

				return true;
			});
	}

	getModelById(id: string): Promise<void> {
		const query = new Parse.Query('trailermodel')
			.get(id);

		return Promise.resolve(query)
			.then(model => {
				return this.setCurrentModel(model);
			});
	}

	getModelStringByVin(vin: string): Promise<string> {
		const query = Parse.Cloud.run('getTrailerModelByVin', {
			vin,
		});

		return Promise.resolve(query)
			.then(model => {
				if (!model) {
					return null;
				}

				return `${model.get('year')} ${model.get('name')} ${model.get('length')}`;
			})
			.catch(_ => {
				return null;
			});
	}

	@action
	setModels(models: Parse.Object[]) {
		this.models.length = 0;

		this.models.push(...models);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

	@action
	private removeModel(index: number) {
		this.models.splice(index, 1);
	}

	@action
	setCurrentModel(model: Parse.Object) {
		this.currentModel = model;
	}
}

export default TrailerModelStore;
