import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as PapaParse from 'papaparse';
import * as Promise from 'bluebird';
import { Line } from 'rc-progress';

import Button from '../../components/Forms/Button';
import roles from '../../roles';
import TrailerRouterStore from '../../stores/TrailerRouterStore';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	routers?: TrailerRouterStore;
}

interface State {
	importing: boolean;
	progress: number;
	totalRows: number;
	csvFile: File;
	doneOpen: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('routers')
@observer
class ImportRoutersContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	inputRef;

	constructor(props: Props) {
		super(props);

		this.state = {
			importing: false,
			progress: 0,
			totalRows: 0,
			csvFile: null,
			doneOpen: false,
			errorOpen: false,
			errorMessage: '',
		};

		this.inputRef = React.createRef();
	}

	onFileChange(e) {
		this.setState({
			csvFile: e.target.files[0],
		});
	}

	onImportClick() {
		if (!this.state.csvFile) {
			this.setState({
				errorOpen: true,
				errorMessage: 'No CSV file provided',
			});
			return;
		}

		PapaParse.parse(this.state.csvFile, {
			header: true,
			skipEmptyLines: true,
			complete: results => {
				this.importCsv(results.data);
				this.setState({
					importing: true,
					totalRows: results.data.length,
					progress: 0,
				});
			},
			error: err => {
				this.setState({
					errorOpen: true,
					errorMessage: `Error parsing CSV file: ${err.message}`,
				});
			},
		});
	}

	importCsv(rows: any[]) {
		Promise.each(rows, (row: any, index: number) => {
			if (!row['Serial Number'] || row['Serial Number'].length < 12) {
				this.setState({ progress: ((index + 1) / this.state.totalRows) * 100 });
				return Promise.resolve();
			}

			const newRouter = {
				deviceId: 0,
				serial: row['Serial Number'].replace(/[-]?/g, ''),
				name: row['Device ID'],
				qrcode: row['QR Code'],
				qrcode_int: row['Related QR Integer'],
				imei: String(row.IMEI),
				mac: row['LAN MAC Address'],
				sim_as: row['SIM AS (ICCID)'],
				sim_att: row['SIM ATT (ICCID)'],
				ssid: row['WIFI SSID'],
				password: row['WIFI Password'],
				guest_ssid: row['Guest WiFi SSID'],
				guest_password: row['Guest WIFI Password'],
				admin_username: row['Admin Login Username'],
				admin_password: row['Admin Login Password'],
				original_ssid: row['WIFI SSID'],
				original_password: row['WIFI Password'],
				original_guest_ssid: row['Guest WiFi SSID'],
				original_guest_password: row['Guest WIFI Password'],
				original_admin_username: row['Admin Login Username'],
				original_admin_password: row['Admin Login Password'],
			};

			return this.props.routers.importRouter(newRouter)
				.then(_ => {
					this.setState({ progress: ((index + 1) / this.state.totalRows) * 100 });
				});
		})
		.then(() => this.setState({ doneOpen: true }))
		.catch(err => {
			this.setState({
				errorOpen: true,
				errorMessage: `There was an error importing routers. Please try again: ${err.message}`,
			});
		});
	}

	render() {
		if (this.state.importing) {
			return (
				<div>
					<h1>Import Peplink Routers</h1>
					<p>Importing {this.state.totalRows} router(s)...</p>

					<Line
						percent={this.state.progress}
						strokeWidth={4}
						strokeColor="#587cb3"
						trailWidth={4}
						trailColor="#b1bbce"
						style={{ width: '70%' }}
					/>

					<ConfirmModal
						isOpen={this.state.doneOpen}
						title="Import Complete"
						message={`${this.state.totalRows} router(s) imported successfully!`}
						okLabel="Ok"
						onOk={() => this.setState({ doneOpen: false, importing: false, csvFile: null })}
					/>
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		return (
			<div>
				<h1>Import Peplink Routers</h1>
				<p>Import a CSV file of peplink routers</p>
				<input
					style={{ display: 'none' }}
					type="file"
					onChange={this.onFileChange.bind(this)}
					ref={this.inputRef}
					accept="text/csv"
				/>
				<Button
					style={{ margin: '0 14px 20px 0' }}
					className="small secondary"
					onClick={() => this.inputRef.current.click()}
				>
					Choose File
				</Button>
				<span>
					{this.state.csvFile ? this.state.csvFile.name : 'No file chosen'}
				</span>

				<br />
				
				<Button
					onClick={this.onImportClick.bind(this)}
				>
					Import CSV
				</Button>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default ImportRoutersContainer;
