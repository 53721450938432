import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouterStore } from 'mobx-router';
import styled from 'styled-components';

import AccountStore from '../stores/AccountStore';
import SideMenuLayout from '../components/Layout/SideMenuLayout';
import NotAuthorizedContainer from './NotAuthorizedContainer';
import LoginContainer from './Users/LoginContainer';
import NotFoundContainer from './NotFoundContainer';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
`;

interface Props {
	account?: AccountStore;
	store?: RouterStore;
	pageContent?: any;
}

@inject('account', 'store')
@observer
class MainContainer extends React.Component<Props, {}> {

	logout() {
		this.props.account.logout()
			.then(() => {
				window.location.href = '/login';
			});
	}

	checkRoles(allowedRoles: string[]) {
		const userRoles = this.props.account.roles;

		return userRoles.some(userRole => allowedRoles.indexOf(userRole) >= 0);
	}

	render() {
		const currentPath = this.props.store.router.currentPath;

		// If we are not logged in and not on login page then redirect to login page.
		if (!this.props.account.loggedin && window.location.pathname !== '/login') {
			window.location.href = '/login';
		}

		// If we are logged in, but on the /login route, then redirect to root url
		if (this.props.account.loggedin && window.location.pathname === '/login') {
			window.location.href = '/';
			return null;
		}

		if (currentPath === '/logout') {
			this.logout();
			return null;
		}

		if (currentPath === '/login') {
			return (<LoginContainer />);
		}

		let content;

		if (!this.checkRoles(this.props.pageContent.allowedRoles)) {
			content = () => <NotAuthorizedContainer />;
		} else if (this.props.pageContent !== null) {
			content = () => <this.props.pageContent />;
		} else {
			content = () => (<NotFoundContainer />);
		}

		return (
			<Wrapper>
				<SideMenuLayout content={content} userRoles={this.props.account.roles} />
			</Wrapper>
		);
	}
}

export default MainContainer;
