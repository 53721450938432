import * as React from 'react';
import InlineLogo from '../Logo/InlineLogo';
import styled from 'styled-components';

const Container = styled.div`
	padding: 0.7em;
	display: block;

	.headerimage {
		margin: 5px 0 0 11px;
	}
`;

const MenuHeader = () => (
	<Container>
		<a href="/">
			<InlineLogo fill={'#eee'} className="headerimage" />
		</a>
	</Container>
);

export default MenuHeader;
