import * as React from 'react';
import { observer } from 'mobx-react';
import * as moment from 'moment';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import Card from '../Details/Card';
import { Badge } from './styles';

interface Props {
	device: any;
	application: any;
}

@observer
class InterfaceDetails extends React.Component<Props, {}> {

	getDeviceStatusValue(device) {
		if (!device.update_status) {
			return device.status;
		}

		if (device.update_status) {
			return (
				<strong>{device.update_status} - ({device.update_progress}%)</strong>
			);
		}
	}

	getApplicationStatusValue(device) {
		if (device.image_progress === null) {
			return device.image_status;
		}

		return (
			<strong>{device.image_status} - ({device.image_progress}%)</strong>
		);
	}

	render() {
		const device = this.props.device;

		const release = (
			<React.Fragment>
				{device.release ? device.release.substring(0, 8) : <Badge className="gray">FACTORY BUILD</Badge>}
				&nbsp;
				{device.pinned_release && <i className="fas fa-thumbtack" />}
				&nbsp;
				{device.release === this.props.application.release && <Badge>CURRENT</Badge>}
			</React.Fragment>
		);

		const lastSeen = (
			<React.Fragment>
				{device.online ? 'Online for' : 'Offline for'}
				&nbsp;
				{moment(device.last_connectivity_event).fromNow(true)}
			</React.Fragment>
		);

		const osVersion = (
			<React.Fragment>
				{device.os_version}
				&nbsp;
				{device.os_version === 'Resin OS 2.12.7+rev2' && <Badge>CURRENT</Badge>}
			</React.Fragment>
		);

		return (
			<div>
				<DetailsHeader>Device Details</DetailsHeader>
				<CardContainer>
					<Card label="Device ID" value={(<a href={`https://dashboard.balena-cloud.com/devices/${device.uuid}/summary`} target="_blank">{device.id} <i className="fas fa-external-link-alt" /></a>)} />
					<Card label="Name" value={device.name} />
					<Card label="Release" className="last" value={release} />

					<Card label="Last Seen" value={lastSeen} />
					<Card label="IP Address" value={device.ip_address} />
					<Card label="OS Version" className="last" value={osVersion} />

					<Card label="Device Status" className="half" value={this.getDeviceStatusValue(device)} />
					<Card label="Application Status" className="half last" value={this.getApplicationStatusValue(device)} />
				</CardContainer>
			</div>
		);
	}
}

export default InterfaceDetails;
