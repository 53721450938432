import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';

import routes from '../../routes';
import RoleSelector from '../Forms/RoleSelector';
import { Table } from '../Table/Table';

interface Props {
	store?: RouterStore;
	onPrev: any;
	onNext: any;
	onRoleChange: any;
	users: any[];
	roles: any[];
	skip: number;
	take: number;
	total: number;
}

@inject('store')
@observer
class UsersTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: 'Name',
				key: 'name',
				cellStyle: { flex: 3 },
				render: ({ data }) => (
					<Link
						view={routes.userDetail}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.name}
					</Link>
				),
			},
			{
				name: 'Email',
				key: 'email',
				cellStyle: { flex: 3 },
			},
			{
				name: 'Trailers',
				key: 'trailers',
				cellStyle: { flex: 1 },
			},
			{
				name: 'Role',
				key: 'roles',
				cellStyle: { flex: 2 },
				render: ({ data }) => (
					<RoleSelector
						roles={this.props.roles}
						selectedRoles={data.roles}
						onRoleChange={(selected) => this.props.onRoleChange(data.user, selected)}
						multi={true}
					/>
				),
			},
		];
	}

	render() {
		const data = this.props.users.map(user => ({
			name: {
				id: user.user.id,
				name: `${user.user.get('firstName')} ${user.user.get('lastName')}`,
			},
			trailers: user.trailers,
			email: user.user.get('email'),
			roles: {
				user: user.user.id,
				roles: user.roles,
			},
		}));

		return (
			<Table
				label="Users"
				data={data}
				columns={this.getColumns()}
				skip={this.props.skip}
				take={this.props.take}
				total={this.props.total}
				pagination={true}
				onPrev={this.props.onPrev}
				onNext={this.props.onNext}
			/>
		);
	}
}

export default UsersTable;
