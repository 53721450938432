import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';

abstract class BaseStore {

	private className: string;

	@observable
	items: Parse.Object[] = [];

	@observable
	count: number = 0;

	@observable
	current: Parse.Object;

	constructor(className: string) {
		this.className = className;
	}

	protected loadItems(query: Parse.Query): Promise<boolean> {
		const countQuery = query.count();

		return Promise.all([
			query.find(),
			countQuery,
		])
			.then(results => {
				this.setItems(results[0]);
				this.setCount(results[1]);

				return true;
			});
	}

	create(values: any): Promise<Parse.Object> {
		const obj = new Parse.Object(this.className);

		return Promise.resolve(obj.save(values))
			.then(saved => {
				return saved;
			});
	}

	getById(id: string): Promise<void> {
		const query = new Parse.Query(this.className)
			.get(id);

		return Promise.resolve(query)
			.then(obj => {
				return this.setCurrent(obj);
			});
	}

	update(id: string, values: any) {
		const query = new Parse.Query(this.className)
			.get(id);

		return Promise.resolve(query)
			.then(item => {
				return Promise.resolve(item.save(values));
			})
			.then(saved => {
				this.setCurrent(saved);
			});
	}

	@action
	setItems(items: Parse.Object[]) {
		this.items.length = 0;

		this.items.push(...items);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

	@action
	setCurrent(obj: Parse.Object) {
		this.current = obj;
	}
}

export default BaseStore;
