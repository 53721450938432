import * as React from 'react';
import styled from 'styled-components';

const Icon = styled.i`
	height: 14px;
	width: 14px;
	display: inline-block;
	border-radius: 100%;
	margin-right: 5px;

	&.online {
		background-color: #006aa6;
	}

	&.offline {
		background-color: #ef0d0d;
	}

	&.unknown {
		background-color: #999999;
	}
`;

interface Props {
	online: boolean | string;
}

class StatusIcon extends React.Component<Props, {}> {

	render() {
		let status = this.props.online ? 'online' : 'offline';
		if (this.props.online === 'n/a') {
			status = 'unknown';
		}

		return (
			<Icon className={status} />
		);
	}
}

export default StatusIcon;
