import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';

class TrailerRouterStore {

	@observable
	routers: Parse.Object[] = [];

	@observable
	count: number = 0;

	@observable
	currentRouter: Parse.Object;

	@observable
	linkedTrailers: Parse.Object[] = [];

	loadRouters(skip: number, take: number, filter: string): Promise<boolean> {

		const nameFilterQuery = new Parse.Query('trailer_router')
			.startsWith('name', filter);
		const serialFilterQuery = new Parse.Query('trailer_router')
			.startsWith('serial', filter);

		const query = Parse.Query.or(nameFilterQuery, serialFilterQuery)
			.skip(skip)
			.limit(take);

		const countQuery = new Parse.Query('trailer_router').count();

		return Promise.all([
			query.find(),
			countQuery,
		])
			.then(results => {
				this.setRouters(results[0]);
				this.setCount(results[1]);

				return true;
			});
	}

	getRouterById(id: string): Promise<void> {
		const query = new Parse.Query('trailer_router')
			.get(id);

		return Promise.resolve(query)
			.then(router => {
				this.setCurrentRouter(router);
			});
	}

	isValidRouterSerial(serial: string): Promise<boolean> {
		const query = new Parse.Query('trailer_router')
			.equalTo('serial', serial)
			.first();

		return Promise.resolve(query)
			.then(router => {
				return router ? true : false;
			})
			.catch(() => {
				return false;
			});
	}

	getTrailerByRouter(router: string): Promise<void> {
		const routerObject = new Parse.Object('trailer_router');
		routerObject.id = router;

		const query = new Parse.Query('physical_trailer')
			.equalTo('router', routerObject)
			.include([ 'user', 'model', 'router' ])
			.find();

			return Promise.resolve(query)
				.then(trailers => {
					if (trailers.length > 0) {
						this.setLinkedTrailers(trailers);
					}
				});
	}

	importRouter(data: any): Promise<any> {
		const query = Parse.Cloud.run('updateRouter', data);

		return Promise.resolve(query);
	}

	updateRouter(id: string, values: any): Promise<void> {
		const query = new Parse.Query('trailer_router')
			.get(id);

		return Promise.resolve(query)
			.then(model => {
				return Promise.resolve(model.save(values));
			})
			.then(saved => {
				this.setCurrentRouter(saved);
			});
	}

	createRouter(values: any): Promise<Parse.Object> {
		const router = new Parse.Object('trailer_router');

		return Promise.resolve(router.save(values))
			.then(saved => {
				return saved;
			});
	}

	@action
	setRouters(routers: Parse.Object[]) {
		this.routers.length = 0;

		this.routers.push(...routers);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

	@action
	setCurrentRouter(router: Parse.Object) {
		this.currentRouter = router;
	}

	@action
	setLinkedTrailers(trailers: Parse.Object[]) {
		this.linkedTrailers = trailers;
	}
}

export default TrailerRouterStore;
