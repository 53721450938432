import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

const A = styled.a`
	font-size: medium;
	color: #5988a6;

	i {
		margin-left: 10px;
	}
`;

interface Props {
	serial: string;
	style: {};
}

@observer
class RouterLinks extends React.Component<Props, {}> {

	render() {
		const serial = this.props.serial.replace(/(.{4})(.{4})(.{4})/, '$1-$2-$3');

		return (
			<div style={this.props.style}>
				<A target="_blank" href={`https://incontrol.airstream.com/sn/${serial}`}>
					Router Admin Page <i className="fas fa-external-link-alt" />
				</A>
			</div>
		);
	}
}

export default RouterLinks;
