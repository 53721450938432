import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import routes from '../../routes';
import TrailerRouterStore from '../../stores/TrailerRouterStore';
import RouterDetails from '../../components/Routers/RouterDetails';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	store?: RouterStore;
	routers?: TrailerRouterStore;
}

interface State {
	loading: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('routers', 'store')
@observer
class AddRouterContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
	];

	onCreate(values) {
		this.setState({ loading: true });

		return this.props.routers.createRouter(values)
			.then(savedRouter => {
				this.props.store.router.goTo(routes.routerDetail, { id: savedRouter.id });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error saving new router: ${err.message}`,
				});
			});
	}

	render() {
		return (
			<div>
				<h2>Add New Peplink Router</h2>
				<Spinner loading={this.state.loading} />
				<RouterDetails
					router={{}}
					newRouter={true}
					onUpdate={this.onCreate.bind(this)}
					editable={true}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default AddRouterContainer;
