import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as Promise from 'bluebird';
import { RouterStore, Link } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import RolesConst from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import { HistoryLogStore } from '../../stores/HistoryLogStore';
import HistoryLogsTable from '../../components/HistoryLogs/HistoryLogsTable';
import routes from '../../routes';
import RoutersVehicleSelector from '../../components/Routers/RoutersVehicleSelector';

interface Props {
	logs?: HistoryLogStore;
	store?: RouterStore;
}

interface State {
	loading?: boolean;
	userId: string;
	vehicleId: string;
	page: number;
	take: number;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('logs', 'store')
@observer
class HistoryLogsListContainer extends React.Component<Props, State> {

	static allowedRoles = [
		RolesConst.admin,
		RolesConst.customer_service,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			userId: props.store.router.params.userId,
			vehicleId: props.store.router.params.vehicleId,
			page: 0,
			take: 20,
			errorOpen: false,
			errorMessage: '',
		};
	}

	componentDidMount() {
		this.props.logs.clearLogs();
		this.load(this.state.page, this.state.take);
	}

	load(page: number, take: number) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		let promise: Promise<void>;
		if (this.state.userId) {
			promise = this.props.logs.loadUserLogs(this.state.userId, page, take);
		}
		if (this.state.vehicleId) {
			promise = this.props.logs.loadVehicleLogs(this.state.vehicleId, page, take);
		}

		promise
			.then(() => {
				this.setState({
					loading: false,
					page,
					take,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Event Logs: ${err.message}`,
				});
			});
	}

	onPrev() {
		let page = this.props.logs.currentPage - 1;
		if (page < 0) {
			page = 0;
		}

		this.load(page, this.state.take);
	}

	onNext() {
		let page = this.props.logs.currentPage + 1;
		if (page > this.props.logs.totalPages) {
			page = this.props.logs.totalPages;
		}

		this.load(page, this.state.take);
	}

	render() {
		const logs = this.props.logs.logs;

		return (
			<div>
				<div style={{  display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<h2>{this.state.userId ? 'User' : 'Trailer'} History Logs</h2>
					{
						!this.state.userId ? (
							<RoutersVehicleSelector
								router={this.props.store.router}
								vehicleId={this.state.vehicleId}
							/>
						) : (
							<Link
								view={routes.userDetail}
								store={this.props.store}
								style={{ float: 'right', marginTop: '24px', marginRight: '10px' }}
								params={{ id: this.state.userId }}
							>
								Back to User
							</Link>
						)
					}
				</div>
				<Spinner loading={this.state.loading} />
				<HistoryLogsTable
					logs={logs}
					skip={this.state.page * this.state.take}
					take={this.state.take}
					total={this.props.logs.total}
					pagination={true}
					onPrev={this.onPrev.bind(this)}
					onNext={this.onNext.bind(this)}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default HistoryLogsListContainer;
