import * as React from 'react';
import { observer } from 'mobx-react';

import roles from '../roles';

interface Props {
	onError: (message: string) => void;
}

@observer
class OverviewContainer extends React.Component<Props, {}> {

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
		roles.customer_service,
		roles.dealer,
	];

	render() {
		return (
			<div>Overview Page</div>
		);
	}
}

export default OverviewContainer;
