import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';

import routes from '../../routes';
import { Table } from '../Table/Table';
import DeleteButton from '../Forms/DeleteButton';

interface Props {
	store?: RouterStore;
	onAction: any;
	actionButton: any;
	users: any[];
}

@inject('store')
@observer
class TrailerUsersTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: 'Name',
				key: 'name',
				cellStyle: { flex: 3 },
				render: ({ data }) => (
					<Link
						view={routes.userDetail}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.name}
					</Link>
				),
			},
			{
				name: 'Email',
				key: 'email',
				cellStyle: { flex: 3 },
			},
			{
				name: 'Actions',
				key: 'actions',
				cellStyle: { flex: 1 },
				render: ({ data }) => (
					<this.props.actionButton
						onClick={() => this.props.onAction(data.id)}
					/>
				),
			},
		];
	}

	render() {
		const data = this.props.users.map(user => ({
			name: {
				id: user.objectId,
				name: `${user.firstName} ${user.lastName}`,
			},
			email: user.email,
			actions: {
				id: user.objectId,
			},
		}));

		return (
			<Table
				data={data}
				columns={this.getColumns()}
				pagination={false}
			/>
		);
	}
}

export default TrailerUsersTable;
