import * as Parse from 'parse';
import * as Promise from 'bluebird';
import BaseStore from './BaseStore';

const CLASS_NAME = 'czone_config';

class CzoneConfigStore extends BaseStore {

	constructor() {
		super(CLASS_NAME);
	}

	load(skip: number, take: number): Promise<boolean> {
		const query = new Parse.Query(CLASS_NAME)
			.include('model')
			.descending('createdAt')
			.skip(skip)
			.limit(take);

		return this.loadItems(query);
	}

	getById(id: string): Promise<void> {
		const query = new Parse.Query(CLASS_NAME)
			.include('model')
			.get(id);

		return Promise.resolve(query)
			.then(obj => {
				return this.setCurrent(obj);
			});
	}

	getByModelId(id: string): Promise<boolean> {
		const model = new Parse.Object('trailermodel');
		model.id = id;

		const query = new Parse.Query(CLASS_NAME)
			.equalTo('model', model)
			.descending('createdAt');

		return this.loadItems(query);
	}

	uploadNewConfig(values: any): Promise<string> {
		const query = Parse.Cloud.run('uploadNewCzoneConfig', values);

		return Promise.resolve(query)
			.then(result => {
				return result.objectId;
			});
	}

	getDownloadLink(configId: string): Promise<string> {
		const query = Parse.Cloud.run('getConfigDownloadLink', {
			configId,
		});

		return Promise.resolve(query)
			.then(result => {
				return result.url;
			});
	}
	
}

export default CzoneConfigStore;
