import * as React from 'react';
import { observer } from 'mobx-react';

import { PaginationContainer, PaginationLabel, PaginationButtonContainer, PaginationButton } from './styles';

interface Props {
	label: string;
	skip: number;
	take: number;
	total: number;
	onPrev: () => void;
	onNext: () => void;
}

@observer
class Pagination extends React.Component<Props, {}> {

	render() {
		const showBackButton = this.props.skip > 0;
		const showNextButton = this.props.total - this.props.skip > this.props.take;
		
		const end = this.props.skip + this.props.take;
		const startCount = this.props.total === 0 ? 0 : this.props.skip + 1;
		const endCount = end > this.props.total ? this.props.total : end;

		return (
			<PaginationContainer>
				<PaginationLabel>
					{this.props.label} {startCount} through {endCount} of {this.props.total}
				</PaginationLabel>
				<PaginationButtonContainer>
					<PaginationButton
						disabled={!showBackButton}
						onClick={this.props.onPrev}
					>
						Prev
					</PaginationButton>
					<PaginationButton
						disabled={!showNextButton}
						onClick={this.props.onNext}
					>
						Next
					</PaginationButton>
				</PaginationButtonContainer>
			</PaginationContainer>
		);
	}
}

export default Pagination;
