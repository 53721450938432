import styled from 'styled-components';

export const TableContainer = styled.div`
	position: relative;
	margin-bottom: 1.2rem;
`;

const Row = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 4px 0;

	a {
		color: #5988a6;
	}
`;

export const HeaderRow = Row.extend`
	background-color: #095e90;
	color: #fff;
`;

export const ContentRow = Row.extend`
	&:nth-of-type(odd) {
		background-color: rgba(0,0,0,.025);
	}
`;

export const Cell = styled.div`
	flex: 1;
	padding: 6px 10px;
	font-size: 14px;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const PaginationContainer = styled.div`
	margin-top: 1.2rem;
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

export const PaginationLabel = styled.div`
	color: gray;
	font-size: 0.9rem;
`;

export const PaginationButtonContainer = styled.div`
	margin-left: 1.8rem;
	width: 225px;
`;

export const PaginationButton = styled.button`
	color: #ffffffcc;
	cursor: pointer;
	background-color: #0F6CA5;
	width: calc(50% - .3rem);
	padding: 6px 12px;
	margin: 0 .3rem 0 0;
	font-size: 13px;
	border-radius: 0.2rem;
	min-width: auto;
	text-transform: none;
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;

	&:hover {
		background-color: #0c5380;
	}

	&:active {
		background-color: #083856;
	}

	&:disabled {
		background-color: #d3d3d3;
		cursor: not-allowed;
	}
`;