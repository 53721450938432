import * as React from 'react';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';

import SubmitButton from '../Forms/SubmitButton';
import ScannerInput from '../Forms/ScannerInput';
import { ProvisionContainer, TrailerModel } from './styles';

interface FormValues {
	router: string;
	qrcode: string;
	czone: string;
	vin: string;
	aftermarket: boolean;
}

interface Props {
	onRouterSerialChange?: (serial: string) => void;
	onTrailerVinChange?: (vin: string) => void;
	onSubmit?: (router: string, qrcode: string, czone: string, vin: string, aftermarket: boolean) => void;
	trailerModel?: string;
}

class ProvisionForm extends React.Component<Props, {}> {

	render() {
		const onSubmit = this.props.onSubmit;

		return (
			<ProvisionContainer>
				<Formik
					initialValues={{ router: '', qrcode: '', czone: '', vin: '', aftermarket: false }}
					onSubmit={(values: FormValues) => onSubmit(values.router.replace(/[-]?/g, ''), values.qrcode, values.czone, values.vin, values.aftermarket)}
					render={(props: FormikProps<FormValues>) => {
						return (
							<Form>
								<Field
									name="aftermarket"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="aftermarket">Aftermarket Provisioning?</label>
											<input
												{...field}
												id="aftermarket"
												className="checkbox" 
												type="checkbox"
												defaultChecked={props.initialValues.aftermarket}
												onClick={e => {
													props.setFieldValue('aftermarket', e.currentTarget.value)
												}}
											/>
										</div>
									)}
								/>

								<Field
									name="router"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="router">Router Serial Number</label>
											<input
												{...field}
												id="router"
												type="text"
												placeholder="Enter serial number"
												onBlur={e => {
													const serial = e.currentTarget.value.replace(/[-]?/g, '');
													props.setFieldValue('router', serial);
													this.props.onRouterSerialChange(serial);
												}}
											/>
											<ScannerInput
												type="barcode"
												label="Router Serial Number"
												onScan={(value) => {
													const serial = value.replace(/[-]?/g, '');
													this.props.onRouterSerialChange(serial);
													props.setFieldValue('router', serial);
												}}
											/>
										</div>
									)}
								/>

								<Field
									name="qrcode"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="qrcode">{`${props.values.aftermarket ? 'Router' : 'Trailer'} QR Code`}</label>
											<input 
												{...field}
												id="qrcode" 
												type="text"  
												placeholder={`Enter ${props.values.aftermarket ? 'router' : 'trailer'}'s QR code`}
												style={{ width: '100%' }}
												onBlur={e => {
													props.setFieldValue('qrcode', e.currentTarget.value);
												}}
											/>
											<ScannerInput
												type="qr"
												label={`${props.values.aftermarket ? 'Router' : 'Trailer'} QR Code`}
												onScan={(value) => props.setFieldValue('qrcode', value)}
											/>
										</div>
									)}
								/>

								<Field
									name="vin"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="vin">Full Airstream VIN Number</label>
											<span style={{
												position: 'relative',
												display: 'inline-block',
												width: '50%',
												maxWidth: '350px',
												marginRight: '20px',
									}}>
												<input
													{...field}
													id="vin"
													type="text"
													placeholder="Enter trailer VIN"
													style={{ width: '100%' }}
													onBlur={e => {
														props.setFieldValue('vin', e.currentTarget.value);
														this.props.onTrailerVinChange(e.currentTarget.value);
													}}
												/>
												<TrailerModel>{this.props.trailerModel}</TrailerModel>
											</span>
											<ScannerInput
												type="barcode"
												label="Trailer VIN Number"
												onScan={(value) => {
													this.props.onTrailerVinChange(value);
													props.setFieldValue('vin', value);
												}}
											/>
										</div>
									)}
								/>

								{
									!props.values.aftermarket &&
									(
										<Field
											name="czone"
											render={({ field }: FieldProps<FormValues>) => (
												<div className="field">
													<label htmlFor="czone">CZone Serial Number</label>
													<input 
														{...field}
														id="czone" 
														type="text" 
														placeholder="Enter serial number"
														style={{ width: '100%' }}
														onBlur={e => {
															props.setFieldValue('czone', e.currentTarget.value);
														}}
													/>
													<ScannerInput
														type="barcode"
														label="CZone Serial Number"
														onScan={(value) => props.setFieldValue('czone', value)}
													/>
												</div>
											)}
										/>
									)
								}

								<SubmitButton>Provision Trailer</SubmitButton>
							</Form>
						);
					}}
				/>
			</ProvisionContainer>
		);
	}
}

export default ProvisionForm;
