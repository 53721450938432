import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';
import * as moment from 'moment';

import { Table } from '../Table/Table';
import StatusIcon from '../Details/StatusIcon';
import routes from '../../routes';
import { Badge } from './styles';

interface Props {
	store?: RouterStore;
	onPrev?: any;
	onNext?: any;
	pagination?: boolean;
	devices: any[];
	application: any;
	skip?: number;
	take?: number;
	total?: number;
}

@inject('store')
@observer
class InterfacesTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: '',
				key: 'online',
				cellStyle: { flex: 0.35, overflowX: 'hidden' },
				render: ({ data }) => {
					if (typeof data === undefined) {
						data = 'n/a';
					}
					
					return (
						<StatusIcon online={data} />
					);
				},
			},
			{
				name: 'Device ID',
				key: 'id',
				cellStyle: { flex: 0.5, overflowX: 'hidden' },
			},
			{
				name: 'Name',
				key: 'name',
				cellStyle: { flex: 3, overflowX: 'hidden' },
				render: ({ data }) => (
					<Link
						view={routes.interfaceDetail}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.name}
					</Link>
				),
			},
			{
				name: 'Last Seen',
				key: 'last_connected',
				cellStyle: { flex: 1, overflowX: 'hidden' },
				render: ({ data }) => (
					<React.Fragment>
						{data.online ? 'Online for' : 'Offline for'}
						&nbsp;
						{moment(data.last_connected).fromNow(true)}
					</React.Fragment>
				),
			},
			{
				name: 'Release',
				key: 'release',
				cellStyle: { flex: 1, overflowX: 'hidden' },
				render: ({ data }) => (
					<React.Fragment>
						{data.release ? data.release.substring(0, 8) : <Badge className="gray">FACTORY BUILD</Badge>}
						&nbsp;
						{data.pinned_release === data.release && <i className="fas fa-thumbtack" />}
						&nbsp;
						{data.release === this.props.application.release && <Badge>CURRENT</Badge>}
					</React.Fragment>
				),
			},
			{
				name: 'OS Version',
				key: 'os',
				cellStyle: { flex: 2, overflowX: 'hidden' },
				render: ({ data }) => (
					<React.Fragment>
						{data}
						&nbsp;
						{data === 'Resin OS 2.12.7+rev2' && <Badge>CURRENT</Badge>}
					</React.Fragment>
				),
			},
		];
	}

	render() {
		const data = this.props.devices.map(device => ({
			online: device.online,
			id: device.id,
			name: { id: device.id, name: device.name },
			last_connected: {
				online: device.online,
				last_connected: device.last_connectivity_event,
			},
			release: {
				release: device.release,
				pinned_release: device.pinned_release,
			},
			os: device.os_version,
		}));

		return (
			<Table
				label="Network Interfaces"
				data={data}
				columns={this.getColumns()}
				skip={this.props.skip}
				take={this.props.take}
				total={this.props.total}
				pagination={this.props.pagination}
				onPrev={this.props.onPrev}
				onNext={this.props.onNext}
			/>
		);
	}
}

export default InterfacesTable;
