import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Route, Link } from 'mobx-router';

import routes from '../../routes';

export interface NoteLink {
	route: Route;
	params: { [key: string]: unknown };
	label: string;
}

export interface Props {
	store?: RouterStore;
	userId?: string;
	userName?: string;
	note: string;
	secondaryLink?: NoteLink;
	secondaryNote?: string;
	endingLink?: NoteLink;
}

@inject('store')
@observer
export class HistoryNote extends React.Component<Props, {}> {
	render() {
		return (
			<span>
				{this.props.userId && (
					<>
						<Link
							view={routes.userDetail}
							store={this.props.store}
							params={{ id: this.props.userId }}
						>
							{this.props.userName}
						</Link>
						&nbsp;
					</>
				)}

				{this.props.note}&nbsp;

				{this.props.secondaryLink && (
					<>
						<Link
							view={this.props.secondaryLink.route}
							store={this.props.store}
							params={this.props.secondaryLink.params}
						>
							{this.props.secondaryLink.label}
						</Link>
						&nbsp;
					</>
				)}

				{this.props.secondaryNote && (<>{this.props.secondaryNote}&nbsp;</>)}

				{this.props.endingLink && (
					<Link
						view={this.props.endingLink.route}
						store={this.props.store}
						params={this.props.endingLink.params}
					>
						{this.props.endingLink.label}
					</Link>
				)}
			</span>
		);
	}
}
