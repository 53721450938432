import * as React from 'react';

const SettingsIcon = ({ fill = null, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 68.28 71.82"
    aria-labelledby="title"
    className={className}
  >
    <title>Settings</title>
    <g fill={fill}>
      <path d="M34.14,21.22A14.73,14.73,0,0,0,19.43,35.93,14.58,14.58,0,0,0,34.14,50.64,14.73,14.73,0,0,0,48.85,35.93,14.73,14.73,0,0,0,34.14,21.22ZM41.7,43.5a10.21,10.21,0,0,1-7.56,3.14A10.58,10.58,0,0,1,23.43,35.93,10.73,10.73,0,0,1,34.14,25.22,10.73,10.73,0,0,1,44.85,35.93,10.21,10.21,0,0,1,41.7,43.5Zm25.3.44-5.62-4.87a28.82,28.82,0,0,0,0-6.27L67,27.92a3.08,3.08,0,0,0,1-3.74,33.63,33.63,0,0,0-6.76-11.71,3.14,3.14,0,0,0-3.77-1l-7.07,2.45a28,28,0,0,0-5.35-3.11L43.64,3.44A3.18,3.18,0,0,0,41,.71a33.29,33.29,0,0,0-13.6,0,3.19,3.19,0,0,0-2.73,2.69L23.2,10.79a25.88,25.88,0,0,0-5.37,3.11l-7.05-2.44a3.12,3.12,0,0,0-3.74,1A33.77,33.77,0,0,0,.25,24.19a3.08,3.08,0,0,0,1,3.74l5.62,4.87a28.82,28.82,0,0,0,0,6.27L1.27,43.94a3.09,3.09,0,0,0-1,3.73A33.67,33.67,0,0,0,7,59.42a3.11,3.11,0,0,0,3.75,1l7-2.44a25.88,25.88,0,0,0,5.37,3.11l1.43,7.36a3.2,3.2,0,0,0,2.7,2.74,37.22,37.22,0,0,0,6.8.66,37.21,37.21,0,0,0,6.76-.66,3.19,3.19,0,0,0,2.73-2.69l1.45-7.42a27.91,27.91,0,0,0,5.35-3.11l7.06,2.45a3.09,3.09,0,0,0,3.72-.94A33.69,33.69,0,0,0,68,47.67,3.09,3.09,0,0,0,67,43.94ZM58.48,56.5l-7.76-2.69a2,2,0,0,0-1.91.33,23.88,23.88,0,0,1-6.22,3.62,2,2,0,0,0-1.23,1.48L39.78,67.3a33,33,0,0,1-5.65.52,33,33,0,0,1-5.65-.52l-1.57-8.06a2,2,0,0,0-1.24-1.48,21.9,21.9,0,0,1-6.18-3.59,2,2,0,0,0-1.94-.36L9.8,56.5a29.67,29.67,0,0,1-5.64-9.76l6.19-5.37A2,2,0,0,0,11,39.47a19.26,19.26,0,0,1-.28-3.55A19.26,19.26,0,0,1,11,32.38a2,2,0,0,0-.65-1.89L4.15,25.12A29.78,29.78,0,0,1,9.8,15.36L17.56,18a2,2,0,0,0,1.94-.36,21.9,21.9,0,0,1,6.18-3.59,2,2,0,0,0,1.24-1.48l1.57-8.07a29.55,29.55,0,0,1,11.29,0l1.57,8.07a2,2,0,0,0,1.23,1.48,24,24,0,0,1,6.23,3.62,2,2,0,0,0,1.9.33l7.76-2.69a29.78,29.78,0,0,1,5.64,9.76L57.93,30.5a2,2,0,0,0-.65,1.89,19.26,19.26,0,0,1,.28,3.55,19.26,19.26,0,0,1-.28,3.55,2,2,0,0,0,.65,1.89l6.19,5.37A29.78,29.78,0,0,1,58.48,56.5Z" />
    </g>
  </svg>
);

export default SettingsIcon;
