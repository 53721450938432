import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';

import TrailerModelStore from '../../stores/TrailerModelStore';
import Spinner from '../../components/Spinner/Spinner';
import TrailerModelDetails from '../../components/TrailerModels/TrailerModelDetails';
import roles from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import ModelConfigsTable from '../../components/TrailerModels/ModelConfigsTable';
import CzoneConfigStore from '../../stores/CzoneConfigStore';
import routes from '../../routes';
import { AddNoteIcon } from '../../components/Icons';

interface Props {
	store?: RouterStore;
	models?: TrailerModelStore;
	config?: CzoneConfigStore;
}

interface State {
	loading: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('models', 'store', 'config')
@observer
class TrailerModelContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
	];

	componentDidMount() {
		this.props.models.setCurrentModel(null);
		this.load();
	}
	
	load() {
		this.setState({ loading: true });

		return this.props.models.getModelById(this.props.store.router.params.id)
			.then(() => this.props.config.getByModelId(this.props.store.router.params.id))
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Model: ${err.message}`,
				});
			});
	}

	onModelUpdate(values) {
		this.setState({ loading: true });

		return this.props.models.updateModel(this.props.models.currentModel.id, values)
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Updating Model: ${err.message}`,
				});
			});
	}

	onSetCurrentConfig(configId: string) {
		this.setState({ loading: true });

		return this.props.models.updateCzoneConfig(configId)
			.then(() => this.load())
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Setting current config: ${err.message}`,
				});
			});
	}

	render() {
		let model = this.props.models.currentModel;
		const configs = this.props.config.items || [];

		if (!model) {
			return (
				<div>
					<h2>About Trailer Model</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		const currentConfig = model.get('czone_config');

		return (
			<div>
				<h2>About Trailer Model</h2>
				<Spinner loading={this.state.loading} />
				<TrailerModelDetails
					model={model.toJSON()}
					editable={true}
					onModelUpdate={this.onModelUpdate.bind(this)}
				/>

				<h3>Czone Configs</h3>
				<Link
					view={routes.uploadConfig}
					store={this.props.store}
					params={{ modelId: model.id }}
					style={{ float: 'right', marginTop: '-40px', textDecoration: 'none' }}
				>
					<AddNoteIcon style={{ width: '16px', height: '16px', marginRight: '4px' }} /> Upload New Config
				</Link>

				<ModelConfigsTable
					configs={configs}
					currentConfigId={currentConfig ? currentConfig.id : ''}
					modelId={model.id}
					onSetCurrent={this.onSetCurrentConfig.bind(this)}
				/>

				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default TrailerModelContainer;
