import * as React from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
	display: flex;
	list-style: none;
	margin: 0 -1.5em 1em -1.5em;
	justify-content: flex-start;
	padding: 0 1.5em 0 1.5em;
	flex-wrap: wrap;
`;

const CardContainer = ({ className = '', children }) => (
	<Ul className={className}>
		{children}
	</Ul>
);

export default CardContainer;