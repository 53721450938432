import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';

class AccountStore {

	@observable
	loggedin: boolean = false;

	@observable
	roles: string[] = [];

	@observable
	user: Parse.User = null;

	login(username, password): Promise<boolean> {
		let user = null;
		console.log(`Current Parse URL: ${Parse.serverURL}`);
		return Promise.resolve(Parse.User.logIn(username, password))
			.then(loggedinUser => {
				user = loggedinUser;
				return this.getUserRoles();
			})
			.then(userRoles => {
				localStorage.setItem('userRoles', JSON.stringify(userRoles));

				this.setRoles(userRoles);
				this.setUser(user);
				return true;
			});
	}

	logout(): Promise<boolean> {
		return Promise.resolve(Parse.User.logOut())
			.then(() => {
				this.setUser(null);
				this.setRoles([]);
				localStorage.setItem('userRoles', '[]');
				
				return true;
			});
	}

	resetPassword(email: string) {
		return Promise.resolve(Parse.User.requestPasswordReset(email));
	}

	private getUserRoles(): Promise<string[]> {
		return Promise.resolve(Parse.Cloud.run('getUserRoles'));
	}

	public userHasRole(role: string) {
		return this.roles.indexOf(role) !== -1;
	}

	@action
	setUser(user: Parse.User) {
		this.loggedin = user !== null;
		this.user = user;
	}

	@action
	private setRoles(userRoles: string[]) {
		this.roles.length = 0;

		this.roles.push(...userRoles);
	}

	@action
	loadRoles() {
		const storedRoles = localStorage.getItem('userRoles');
		if (!storedRoles) {
			this.roles.length = 0;
		}

		const userRoles: string[] = JSON.parse(storedRoles);

		this.roles.length = 0;
		this.roles.push(...userRoles);
	}
}

export default AccountStore;
