import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import TrailerUsersTable from '../Trailers/TrailerUsersTable';
import DeleteButton from '../Forms/DeleteButton';
import AddButton from '../Forms/AddButton';
import ConfirmModal from '../Modal/ConfirmModal';
import { FilterContainer, FilterSearch } from '../Users/styles';
import Input from '../Forms/Input';
import AccountStore from '../../stores/AccountStore';
import RolesConst from '../../roles';

interface Props {
	store?: RouterStore;
	account?: AccountStore;
	users: any[];
	claimed: boolean;
	searchValue?: string;
	onTrailerClaim: any;
	onTrailerUnclaim: any;
	onUserFilterChange: any;
}

interface State {
	filterValue: string;
	confirmOpen: boolean;
	confirmLabel: string;
	confirmMessage: string;
	onConfirm: () => void;
}

@inject('store', 'account')
@observer
class TrailerClaims extends React.Component<Props, State> {

	private debounceTimer;

	constructor(props) {
		super(props);

		this.state = {
			filterValue: props.searchValue,
			confirmOpen: false,
			confirmLabel: '',
			confirmMessage: '',
			onConfirm: null,
		};
	}

	onFilterChange(e) {
		this.setState({ filterValue: e.target.value });

		// Debounce the props change callback
		if (this.debounceTimer) {
			clearTimeout(this.debounceTimer);
		}

		this.debounceTimer = setTimeout(() => {
			this.props.onUserFilterChange(this.state.filterValue);
		}, 1000); // Only call the callback after no more changes in 1 second
	}

	onTrailerClaim(userId: string) {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Link Trailer',
			confirmMessage: 'Link this user to this trailer?',
			onConfirm: this.claimTrailer.bind(this, userId),
		});
	}

	claimTrailer(userId: string) {
		this.setState({ confirmOpen: false });
		this.props.onTrailerClaim(userId);
	}

	onTrailerUnclaim() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Unpair User',
			confirmMessage: 'Unpair this user from this trailer?',
			onConfirm: this.unclaimTrailer.bind(this),
		});
	}

	unclaimTrailer() {
		this.setState({ confirmOpen: false });
		this.props.onTrailerUnclaim();
	}

	renderClaimFilter() {
		if (!this.props.account.userHasRole(RolesConst.admin)) {
			return null;
		}

		return (
			<div>
				<FilterContainer>
					<FilterSearch>
						<Input
							id="search"
							placeholder="Search by name or email"
							onChange={this.onFilterChange.bind(this)}
							value={this.state.filterValue}
						/>
					</FilterSearch>
				</FilterContainer>
				<TrailerUsersTable
					users={this.props.users}
					onAction={this.onTrailerClaim.bind(this)}
					actionButton={AddButton}
				/>
			</div>
		);
	}

	render() {
		const modal = (
			<ConfirmModal
				isOpen={this.state.confirmOpen}
				title="Confirm Pairing"
				message={this.state.confirmMessage}
				okLabel={this.state.confirmLabel}
				onOk={this.state.onConfirm}
				onCancel={() => this.setState({ confirmOpen: false })}
				cancelButton={true}
			/>
		);

		if (!this.props.claimed) {
			return (
				<div>
					<p><strong>There are no users paired to this trailer.</strong></p>
					{this.renderClaimFilter()}
					{modal}
				</div>
			);
		}

		return (
			<div>
				<TrailerUsersTable
					users={this.props.users}
					onAction={this.onTrailerUnclaim.bind(this)}
					actionButton={DeleteButton}
				/>
				{modal}
			</div>
		);
	}
}

export default TrailerClaims;
