import * as React from 'react';
import { observer } from 'mobx-react';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import EditableCard from '../Details/EditableCard';
import { TransparentButton, SaveButton } from '../Users/styles';
import { EditIcon, CheckIcon } from '../Icons';

interface Props {
	config: any;
	newConfig?: boolean;
	onUpdate: (changes: any) => void;
}

interface State {
	editing: boolean;
	inputsChanged: boolean;
	uuid: string;
	name: string;
	version: string;
	description: string;
	url: string;
	model: any;
}

@observer
class ConfigDetails extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = this.getState(false, false);
	}

	onInputChange(key, value) {
		const newState = {
			inputsChanged: true,
		};
		newState[key] = value;

		this.setState(newState);
	}

	onEditClick() {
		this.setState(this.getState(true, false));
	}

	onCancelClick() {
		this.setState(this.getState(false, false));
	}

	getState(editing: boolean, changed: boolean): State {
		return {
			editing: this.props.newConfig || editing,
			inputsChanged: changed,
			uuid: this.props.config.uuid,
			name: this.props.config.name,
			version: this.props.config.version,
			description: this.props.config.description,
			url: this.props.config.url,
			model: this.props.config.model,
		};
	}

	onSaveClick() {
		const changes: any = {};

		Object.keys(this.state).forEach(key => {
			if (key === 'editing' || key === 'inputsChanged') {
				return;
			}

			const value = this.state[key];
			if (value !== this.props.config[key]) {
				if (isNaN(value)) {
					changes[key] = value;
				} else {
					changes[key] = parseFloat(value);
				}
			}
		});

		this.props.onUpdate(changes);
		this.setState({
			editing: false,
			inputsChanged: false,
		});
	}

	renderButtons() {
		const cancelButton = !this.props.newConfig ? (
			<TransparentButton
				onClick={this.onCancelClick.bind(this)}
			>
				Cancel
			</TransparentButton>
		) : '';

		return (this.props.newConfig || this.state.editing) ? (
			<div style={{ float: 'right', marginTop: '-40px' }}>
				{cancelButton}
				<SaveButton
					onClick={this.onSaveClick.bind(this)}
					disabled={!this.state.inputsChanged}
				>
					<CheckIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Save Changes
				</SaveButton>
			</div>
		) : (
				<div style={{ float: 'right', marginTop: '-35px' }}>
					<TransparentButton
						onClick={this.onEditClick.bind(this)}
					>
						<EditIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Edit Czone Config
				</TransparentButton>
				</div>
			);
	}

	render() {
		return (
			<div>
				{this.renderButtons()}

				<DetailsHeader>Czone Config Details</DetailsHeader>
				<CardContainer>
					<EditableCard
						label="Name"
						id="name"
						value={this.state.name}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Version"
						id="version"
						value={this.state.version}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="UUID"
						id="uuid"
						className="last"
						value={this.state.uuid}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="Description"
						id="description"
						className="half last"
						value={this.state.description}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
				</CardContainer>
			</div>
		);
	}
}

export default ConfigDetails;
