import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import TrailerRouterStore from '../../stores/TrailerRouterStore';
import RouterDetails from '../../components/Routers/RouterDetails';
import RouterLinks from '../../components/Routers/RouterLinks';
import TrailersTable from '../../components/Trailers/TrailersTable';
import ErrorModal from '../../components/Modal/ErrorModal';
import { HistoryLogStore } from '../../stores/HistoryLogStore';

interface Props {
	store?: RouterStore;
	routers?: TrailerRouterStore;
	logs?: HistoryLogStore;
}

interface State {
	loading: boolean;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('routers', 'store', 'logs')
@observer
class RouterContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	componentDidMount() {
		this.props.routers.setCurrentRouter(null);
		this.props.routers.setLinkedTrailers([]);

		this.setState({ loading: true });
		const routerId = this.props.store.router.params.id;

		this.props.routers.getRouterById(routerId)
			.then(() => this.props.routers.getTrailerByRouter(routerId))
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Router: ${err.message}`,
				});
			});
	}

	onUpdate(values) {
		this.setState({ loading: true });

		return this.props.routers.updateRouter(this.props.routers.currentRouter.id, values)
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Updating Router: ${err.message}`,
				});
			});
	}

	onInfoReveal(field: string) {
		const vehicleId = this.props.routers.linkedTrailers.length > 0 ? this.props.routers.linkedTrailers[0].id : undefined;

		this.props.logs.saveDataRevealedLog(vehicleId, field)
			.catch(err => {
				console.log(`Error saving reveal location log: ${err.message}`);
			});
	}

	render() {
		let router: any = this.props.routers.currentRouter;
		const trailers = this.props.routers.linkedTrailers;

		if (!router) {
			return (
				<div>
					<h2>About this Router</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		router = router.toJSON();

		return (
			<div>
				<h2>
					About this Router
					<RouterLinks serial={router.serial} style={{ display: 'inline-block', marginLeft: '20px' }} />
				</h2>
				<Spinner loading={this.state.loading} />
				<RouterDetails
					router={router}
					editable={true}
					onUpdate={this.onUpdate.bind(this)}
					onInfoReveal={this.onInfoReveal.bind(this)}
				/>

				<h2>Trailers containing this router</h2>
				<TrailersTable
					trailers={trailers.map(t => t.toJSON())}
					pagination={false}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default RouterContainer;
