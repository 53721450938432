import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import routes from '../../routes';
import { Table } from '../Table/Table';
import { EventLog } from '../../stores/HistoryLogStore';
import {
	VehiclePairEvent,
	VehicleUnpairEvent,
	VehicleProvisionEvent,
	VehicleProvisionUpdateEvent,
	PrivateDataRevealedEvent,
	UserNewSessionEvent,
	UserEndSessionEvent,
	VehicleReleaseUpdateEvent,
	ApplicationLogUploadEvent,
	CustomNoteEvent,
} from 'airstream-common';
import { HistoryNote } from './HistoryNote';

interface Props {
	onPrev?: any;
	onNext?: any;
	pagination?: boolean;
	skip?: number;
	take?: number;
	total?: number;
	store?: RouterStore;
	logs: EventLog[];
}

@inject('store')
@observer
class HistoryLogsTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: 'Date',
				key: 'date',
				cellStyle: { flex: 2 },
			},
			{
				name: 'Action',
				key: 'type',
				cellStyle: { flex: 2 },
			},
			{
				name: 'Notes',
				key: 'notes',
				cellStyle: { flex: 5, whiteSpace: 'normal' },
			},
		];
	}

	private getRowData(event: EventLog) {
		let data = {
			date: event.timestamp?.local().format('YYYY-MM-DD H:mm A'),
			type: '',
			notes: (<span />),
		};

		switch (event.type) {
			case CustomNoteEvent.subject:
				data.type = 'Custom Note';
				data.notes = (
					<HistoryNote
						userId={event.data.addedBy}
						userName={event.data.addedByName}
						note="added note for"
						secondaryLink={{
							route: event.userId ? routes.userDetail : routes.trailerDetail,
							params: { id: event.data.userId || event.vehicleId },
							label: event.data.userName || event.vehicleId,
						}}
						secondaryNote={`: ${event.data.note}`}
					/>
				);
				break;
			case 'vehicle.config.update':
				data.type = 'Config Updated';
				data.notes = (
					<HistoryNote
						note="The trailer's remote configuration was updated"
					/>
				);
				break;
			case UserNewSessionEvent.subject:
				data.type = 'Login';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note="logged in"
					/>
				);
				break;
			case UserEndSessionEvent.subject:
				data.type = 'Logout';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note="logged out"
					/>
				);
				break;
			case VehicleProvisionEvent.subject:
				data.type = 'Provisioned';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note="provisioned trailer"
						secondaryLink={{
							route: routes.trailerDetail,
							params: { id: event.vehicleId },
							label: event.vehicleId,
						}}
					/>
				);
				break;
			case VehicleProvisionUpdateEvent.subject:
				data.type = 'Provisioning Updated';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note="updated the provisioning for trailer"
						secondaryLink={{
							route: routes.trailerDetail,
							params: { id: event.vehicleId },
							label: event.vehicleId,
						}}
					/>
				);
				break;
			case PrivateDataRevealedEvent.subject:
				data.type = 'Private Data Viewed';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note={`viewed the ${event.data.field} for trailer`}
						secondaryLink={{
							route: routes.trailerDetail,
							params: { id: event.vehicleId },
							label: event.vehicleId,
						}}
					/>
				);
				break;
			case VehiclePairEvent.subject:
				data.type = 'Paired';
				data.notes = (
					<HistoryNote
						userId={event.data.pairedBy}
						userName={event.data.pairedByName}
						note="paired"
						secondaryLink={{
							route: routes.userDetail,
							params: { id: event.userId },
							label: event.userName,
						}}
						secondaryNote="to trailer"
						endingLink={{
							route: routes.trailerDetail,
							params: { id: event.vehicleId },
							label: event.vehicleId,
						}}
					/>
				);
				break;
			case VehicleUnpairEvent.subject:
				data.type = 'Unpaired';
				data.notes = (
					<HistoryNote
						userId={event.data.unpairedBy}
						userName={event.data.unpairedByName}
						note="unpaired"
						secondaryLink={{
							route: routes.userDetail,
							params: { id: event.userId },
							label: event.userName,
						}}
						secondaryNote="from trailer"
						endingLink={{
							route: routes.trailerDetail,
							params: { id: event.vehicleId },
							label: event.vehicleId,
						}}
					/>
				);
				break;
			case VehicleReleaseUpdateEvent.subject:
				data.type = 'Update Release';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note={`updated trailer to release ${event.data.balenaReleaseTag}`}
					/>
				);
				break;
			case ApplicationLogUploadEvent.subject:
				data.type = 'Application Logs Sent';
				data.notes = (
					<HistoryNote
						userId={event.data.userId}
						userName={event.data.userName}
						note="sent application logs"
					/>
				);
				break;
			default:
				data.type = event.type;
		}

		return data;
	}

	render() {
		const data = this.props.logs.map(log => this.getRowData(log));

		return (
			<div>
				<Table
					data={data}
					columns={this.getColumns()}
					skip={this.props.skip}
					take={this.props.take}
					total={this.props.total}
					pagination={this.props.pagination}
					onPrev={this.props.onPrev}
					onNext={this.props.onNext}
				/>
			</div>
		);
	}
}

export default HistoryLogsTable;
