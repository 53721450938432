import * as React from 'react';

const PrivisioningIcon = ({ fill = null, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30px"
    height="26.5px"
    viewBox="0 0 30 26.5"
    aria-labelledby="title"
    className={className}
  >
    <title>Trailer Provisioning</title>
    <path
      fill={fill}
      d="M2.5,26.3c-1.3,0-2.4-1.1-2.4-2.5v-16c0-1.4,1.1-2.5,2.4-2.5h5.5v2.2H2.5c-0.1,0-0.3,0.1-0.3,0.3v16c0,0.1,0.1,0.3,0.3,0.3h25.1c0.1,0,0.3-0.1,0.3-0.3v-16c0-0.1-0.1-0.3-0.3-0.3h-5V5.3h5c1.3,0,2.4,1.1,2.4,2.5v16c0,1.4-1.1,2.5-2.4,2.5H2.5z"
    />
    <path d="M8.5,9.8C7.1,9.8,6,8.8,6,7.5V5.4C6,4.1,7.1,3,8.5,3h1.9V2.2c0-1.3,1.1-2.4,2.5-2.4h4.4c1.4,0,2.5,1.1,2.5,2.4V3h1.9C22.9,3,24,4.1,24,5.4v2.1c0,1.3-1.1,2.4-2.5,2.4H8.5z M8.5,5.1c-0.1,0-0.3,0.1-0.3,0.3v2.1c0,0.1,0.1,0.3,0.3,0.3h13.1c0.1,0,0.3-0.1,0.3-0.3V5.4c0-0.1-0.1-0.3-0.3-0.3h-4.1V2.2c0-0.1-0.1-0.3-0.3-0.3h-4.4c-0.2,0-0.3,0.1-0.3,0.3v2.9H8.5z" />
    <circle cx="15" cy="3.8" r="1" />
    <rect
      x="16.3"
      y="5.8"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 30.1188 -3.4521)"
      width="1"
      height="15"
    />
    <rect
      x="6.3"
      y="12.3"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 20.1188 6.5479)"
      width="1"
      height="2"
    />
    <rect
      x="16.3"
      y="9.3"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 33.6188 4.787248e-02)"
      width="1"
      height="15"
    />
    <rect
      x="6.3"
      y="15.8"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 23.6188 10.0479)"
      width="1"
      height="2"
    />
    <rect
      x="16.3"
      y="12.8"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 37.1188 3.5479)"
      width="1"
      height="15"
    />
    <rect
      x="6.3"
      y="19.3"
      transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 27.1188 13.5479)"
      width="1"
      height="2"
    />
  </svg>
);

export default PrivisioningIcon;
