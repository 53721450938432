import * as React from 'react';
import * as Modal from 'react-modal';

import { ModalStyles, OkButton, CancelButton, TextArea } from './styles';
import Input from '../Forms/Input';

Modal.setAppElement('#root');

interface Props {
	isOpen: boolean;
	title: string;
	message: string;
	okLabel: string;
	onOk: (input: string) => void;
	onCancel: any;
	type: string;
}

interface State {
	input: string;
}

class InputModal extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			input: '',
		};
	}

	onCancel() {
		this.props.onCancel();
	}

	onInput(e) {
		this.setState({
			input: e.target.value,
		});
	}

	onOk() {
		this.props.onOk(this.state.input);
	}

	render() {
		const input = this.props.type === 'textarea' ? (
			<TextArea
				onChange={this.onInput.bind(this)}
				value={this.state.input}
			/>
		) : (
			<Input
				onChange={this.onInput.bind(this)}
				value={this.state.input}
				style={{ marginBottom: '20px' }}
			/>
		);

		return (
			<Modal
				isOpen={this.props.isOpen}
				style={ModalStyles}
				onRequestClose={this.props.onCancel}
			>
				<h2>{this.props.title}</h2>
				<p>{this.props.message}</p>
				{input}
				
				<CancelButton onClick={this.props.onCancel}>
					Cancel
				</CancelButton>
				<OkButton onClick={this.onOk.bind(this)}>
					{this.props.okLabel}
				</OkButton>
			</Modal>
		);
	}

}

export default InputModal;
