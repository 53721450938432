import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';
import * as moment from 'moment';

import routes from '../../routes';
import { Table } from '../Table/Table';
import Button from '../Forms/Button';
import ConfirmModal from '../Modal/ConfirmModal';
import { Badge } from './styles';

interface Props {
	store?: RouterStore;
	configs: any[];
	currentConfigId: string;
	modelId: string;
	onSetCurrent: (id: string) => void;
}

interface State {
	confirmOpen: boolean;
	confirmLabel: string;
	confirmTitle: string;
	confirmMessage: string;
	onConfirm: () => void;
}

@inject('store')
@observer
class ModelConfigsTable extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			confirmOpen: false,
			confirmLabel: '',
			confirmTitle: '',
			confirmMessage: '',
			onConfirm: null,
		};
	}

	getColumns() {
		return [
			{
				name: 'Name',
				key: 'name',
				cellStyle: { flex: 2 },
				render: ({ data }) => (
					<Link
						view={routes.czoneConfigDetail}
						store={this.props.store}
						params={{ id: data.id, modelId: this.props.modelId }}
					>
						{data.name}
					</Link>
				),
			},
			{
				name: 'Version',
				key: 'version',
				cellStyle: { flex: 1 },
			},
			{
				name: 'UUID',
				key: 'uuid',
				cellStyle: { flex: 3 },
			},
			{
				name: 'Uploaded On',
				key: 'date',
				cellStyle: { flex: 2 },
			},
			{
				name: 'Actions',
				key: 'actions',
				cellStyle: { flex: 2 },
				render: ({ data }) => {
					if (data.currentConfigId === data.configId) {
						return (<Badge>CURRENT</Badge>);
					}

					return (
						<Button
							className="transparent"
							onClick={() => this.onSetCurrentClick(data.configId)}
						>
							Set Current
						</Button>
					);
				},
			},
		];
	}

	onSetCurrentClick(id: string) {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Set Current',
			confirmTitle: 'Set Config as Current',
			confirmMessage: 'This will set this config as the current config and all trailers of this model will update to this version.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onSetCurrent(id);
			},
		});
	}

	render() {
		const data = this.props.configs.map(config => ({
			name: {
				id: config.id,
				name: config.get('name'),
			},
			version: config.get('version'),
			uuid: config.get('uuid'),
			date: moment(config.get('createdAt')).local().format('YYYY-MM-DD H:mm A'),
			actions: {
				currentConfigId: this.props.currentConfigId,
				configId: config.id,
			},
		}));

		return (
			<div>
				<Table
					label="Czone Configs"
					data={data}
					columns={this.getColumns()}
					pagination={false}
				/>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel={this.state.confirmLabel}
					onOk={this.state.onConfirm}
					onCancel={() => this.setState({ confirmOpen: false })}
					cancelButton={true}
				/>
			</div>
		);
	}
}

export default ModelConfigsTable;
