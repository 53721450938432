import * as React from 'react';

import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Forms/Button';
import { ActionContainer, ActionCard } from './styles';

interface Props {
	osVersion: string;
	onRestart: () => void;
	onReboot: () => void;
	onOsUpdate: () => void;
}

interface State {
	confirmOpen: boolean;
	confirmLabel: string;
	confirmTitle: string;
	confirmMessage: string;
	onConfirm: () => void;
}

class InterfaceActions extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			confirmOpen: false,
			confirmLabel: '',
			confirmTitle: '',
			confirmMessage: '',
			onConfirm: null,
		};
	}

	onRestartClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Restart',
			confirmTitle: 'Restart Application',
			confirmMessage: 'This will restart the application running on the device.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onRestart();
			},
		});
	}

	onRebootClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Reboot',
			confirmTitle: 'Reboot Device',
			confirmMessage: 'This will perform a full device reboot.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onReboot();
			},
		});
	}

	onUpdateClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Update',
			confirmTitle: 'Update OS Version',
			confirmMessage: 'This will perform an update to the device\'s system OS to the latest version.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onOsUpdate();
			},
		});
	}

	render() {
		return (
			<ActionContainer>
				<ActionCard>
					<h3>Restart Application</h3>
					<p>This will restart the application running on the device.</p>
					<Button
						className="small"
						onClick={this.onRestartClick.bind(this)}
					>
						Restart Application
					</Button>
				</ActionCard>
				<ActionCard>
					<h3>Reboot Device</h3>
					<p>This will perform a full device reboot.</p>
					<Button
						className="small"
						onClick={this.onRebootClick.bind(this)}
					>
						Reboot Device
					</Button>
				</ActionCard>
				<ActionCard>
					<h3>Update System OS</h3>
					<p>This will perform an update to the device's system OS to the latest version (Resin OS 2.12.7+rev2).</p>
					<Button
						className="small"
						onClick={this.onUpdateClick.bind(this)}
						disabled={this.props.osVersion === 'Resin OS 2.12.7+rev2'}
					>
						Update OS
					</Button>
				</ActionCard>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel={this.state.confirmLabel}
					onOk={this.state.onConfirm}
					onCancel={() => this.setState({ confirmOpen: false })}
					cancelButton={true}
				/>
			</ActionContainer>
		);
	}
}

export default InterfaceActions;
