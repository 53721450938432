import * as React from 'react';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';

import SubmitButton from '../Forms/SubmitButton';
import { LoginContainer, FormLink } from './styles';

interface FormValues {
	email: string;
	password: string;
}

interface Props {
	onSubmit: (email: string, password: string) => void;
	gotoResetForm: () => void;
}

class LoginForm extends React.Component<Props, {}> {

	render() {
		const onSubmit = this.props.onSubmit;

		return (
			<LoginContainer>
				<Formik
					initialValues={{ email: '', password: '' }}
					onSubmit={(values: FormValues) => onSubmit(values.email, values.password)}
					render={(props: FormikProps<FormValues>) => (
						<Form>
							<Field
								name="email"
								render={({ field }: FieldProps<FormValues>) => (
									<div className="field">
										<label htmlFor="email">Email</label>
										<input id="email" type="text" {...field} placeholder="Email Address" />
									</div>
								)}
							/>

							<Field
								name="password"
								render={({ field }: FieldProps<FormValues>) => (
									<div className="field">
										<label htmlFor="password">Password</label>
										<input id="password" type="password" {...field} placeholder="Password" />
									</div>
								)}
							/>

							<div>
								<FormLink
									type="button"
									onClick={this.props.gotoResetForm}
								>
									Don't remember your password?
								</FormLink>
							</div>
							<br />

							<SubmitButton>Login</SubmitButton>
						</Form>
					)}
				/>
			</LoginContainer>
		);
	}
}

export default LoginForm;
