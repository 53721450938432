import * as React from 'react';
import styled from 'styled-components';

const Button = styled.button`
	color: #fff;
	cursor: pointer;
	background-color: #0F6CA5;
	width: 50%;
	max-width: 250px;
	min-width: 150px;
	padding: 12px;
	font-size: 16px;
	border-radius: 5px;
	text-transform: none;
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;

	&:hover {
		background-color: #0c5380;
	}

	&:active {
		background-color: #083856;
	}

	&:focus {
		outline: 0;
	}
`;

const SubmitButton = ({ children, disabled = false }) => (
	<Button type="submit" disabled={disabled}>
		{children}
	</Button>
);

export default SubmitButton;