import styled from 'styled-components';

export const Container = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
`;

export const Content = styled.section`
	position: relative;
	flex-grow: 1;
	overflow-y: scroll;
	padding: 1.5em;
`;