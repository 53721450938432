import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import AccountStore from '../../stores/AccountStore';
import LoginForm from '../../components/Login/LoginForm';
import { Background, Panel, Copyright } from '../../components/Login/styles';
import * as backgroundImage from '../../components/Login/login-background.jpg';
import InlineLogoPNG from '../../components/Logo/InlineLogoPNG';
import Spinner from '../../components/Spinner/Spinner';
import PasswordResetForm from '../../components/Login/PasswordResetForm';
import LoginError from '../../components/Login/LoginError';
import routes from '../../routes';

interface Props {
	account?: AccountStore;
	store?: RouterStore;
}

interface State {
	loading: boolean;
	showLoginForm: boolean;
	showResetForm: boolean;
	errorVisible: boolean;
	errorMessage: string;
}

@inject('account', 'store')
@observer
class LoginContainer extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			showLoginForm: true,
			showResetForm: false,
			errorVisible: false,
			errorMessage: '',
		};
	}

	login(email: string, password: string) {
		this.setState({ loading: true });

		this.props.account.login(email, password)
			.then(() => {
				this.props.store.router.goTo(routes.overview);
			})
			.catch(() => {
				this.setState({
					loading: false,
					errorVisible: true,
					errorMessage: 'Error: Login Failed',
				});
			});
	}

	resetPassword(email: string) {
		this.setState({ loading: true });

		this.props.account.resetPassword(email)
			.then(() => {
				this.setState({ loading: false });
			})
			.catch (err => {
				this.setState({
					loading: false,
					errorVisible: true,
					errorMessage: 'Error: Failed to send password reset email',
				});
			});
	}

	showResetForm() {
		this.setState({
			showLoginForm: false,
			showResetForm: true,
			errorVisible: false,
			errorMessage: '',
		});
	}

	showLoginForm() {
		this.setState({
			showLoginForm: true,
			showResetForm: false,
			errorVisible: false,
			errorMessage: '',
		});
	}

	renderForm() {
		if (this.state.showLoginForm) {
			return (
				<LoginForm
					onSubmit={this.login.bind(this)}
					gotoResetForm={this.showResetForm.bind(this)}
				/>
			);
		}

		if (this.state.showResetForm) {
			return (
				<PasswordResetForm
					onSubmit={this.resetPassword.bind(this)}
					gotoLoginForm={this.showLoginForm.bind(this)}
				/>
			);
		}
	}

	render() {
		if (this.state.loading) {
			return (
				<Background style={{ backgroundImage: `url("${backgroundImage}")` }}>
					<Spinner loading={this.state.loading} />
				</Background>
			);
		}

		return (
			<Background style={{ backgroundImage: `url("${backgroundImage}")` }}>
				<Panel>
					<div className="logoHeaderSection">
						<InlineLogoPNG className="logo" />
						<div className="title">Access your dashboard</div>
					</div>

					<LoginError visible={this.state.errorVisible} message={this.state.errorMessage} />

					{this.renderForm()}

					<Copyright>
						© 2018 Airstream, Inc.
						<br />
						A subsidiary of Thor Industries, Inc. All Rights Reserved.
					</Copyright>
				</Panel>
			</Background>
		);
	}
}

export default LoginContainer;
