import styled from 'styled-components';
import Button from '../Forms/Button';

export const FilterContainer = styled.div`
	display: flex;
	flex: 1;
	margin: 0 0 15px;
	justify-content: space-between;
	align-items: flex-end;
`;

export const FilterSearch = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-size: small;
	min-width: 240px;
	width: 240px;
	justify-content: flex-start;
	flex: 1;
`;

export const FilterRole = styled.div`
	margin-left: 15px;
	padding-right: 0;
	flex-basis: 225px;
	align-items: center;

	label {
		font-size: small;
	}
`;

export const TransparentButton = styled(Button)`
	background-color: transparent;
	color: #5988a6;
	padding: 4px 10px;
	font-size: 14px;

	&:hover:enabled {
		background-color: transparent;
		color: #2a546f;
	}

	&:active:enabled {
		background-color: transparent;
		color: #2a546f;
	}

	&:focus {
		outline: 0;
	}
`;

export const SaveButton = styled(Button)`
	background-color: #ecb71a;
	color: white;
	padding: 6px 12px;
	font-size: 14px;

	&[disabled] {
		opacity: 0.4;
		color: white;
		background-color: #888888;
	}

	&[disabled]:hover {
		background-color: #888888;
	}

	&:hover {
		background-color: #ecb71a;
		color: white;
	}

	&:active {
		background-color: #ecb71a;
		color: white;
	}

	&:focus {
		outline: 0;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3em;
`;

export const ActionCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin: 1em 1em 0 0;
	flex: 1 1 auto;
	width: 0;
	min-width: 200px;

	h3 {
		font-size: 14px;
		margin: 0;
		font-weight: 500;
		margin-bottom: 4px;
		color: #444;
	}

	p {
		font-size: 14px;
	}
`;

export const ActionButtonRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	margin: 1em 1em 0 0;
	flex: 1 1 auto;
`;