import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';

import { Table } from '../Table/Table';
import routes from '../../routes';
import DeleteButton from '../Forms/DeleteButton';

interface Props {
	store?: RouterStore;
	onPrev?: any;
	onNext?: any;
	models: any[];
	skip?: number;
	take?: number;
	total?: number;
	pagination: boolean;
	onDelete: (id: string) => void;
}

@inject('store')
@observer
class TrailerModelsTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: 'Trailer Model',
				key: 'model',
				cellStyle: { flex: 2, overflowX: 'hidden' },
				render: ({ data }) => (
					<Link
						view={routes.trailerModel}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.year} { data.name} {data.length}
					</Link>
				),
			},
			{
				name: 'Owner\'s Manual',
				key: 'manual',
				cellStyle: { flex: 4, overflowX: 'hidden' },
				render: ({ data }) => (
					<a href={data} target="_blank">{data}</a>
				),
			},
			{
				name: 'Czone Config',
				key: 'config',
				cellStyle: { flex: 2, overflowX: 'hidden' },
				render: ({ data }) => {
					if (!data.config) {
						return (<span>&nbsp;</span>);
					}

					return (
						<Link
							view={routes.czoneConfigDetail}
							store={this.props.store}
							params={{ id: data.config.objectId, modelId: data.modelId }}
						>
							{data.config.name}
						</Link>
					);
				},
			},
			{
				name: 'Actions',
				key: 'actions',
				cellStyle: { flex: 1, overflowX: 'hidden' },
				render: ({ data }) => (
					<div>
						<DeleteButton
							onClick={() => this.props.onDelete(data.id)}
						/>
					</div>
				),
			},
		];
	}

	render() {
		const data = this.props.models.map(model => ({
			model: {
				id: model.objectId,
				name: model.name,
				length: model.length,
				year: model.year,
			},
			manual: model.manual,
			config: {
				config: model.czone_config,
				modelId: model.objectId,
			},
			actions: { id: model.objectId },
		}));

		return (
			<Table
				label="Trailer Models"
				data={data}
				columns={this.getColumns()}
				skip={this.props.skip}
				take={this.props.take}
				total={this.props.total}
				pagination={this.props.pagination}
				onPrev={this.props.onPrev}
				onNext={this.props.onNext}
			/>
		);
	}
}

export default TrailerModelsTable;
