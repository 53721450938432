import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';
import TrailerStore from '../../stores/TrailerStore';
import UserStore from '../../stores/UserStore';
import Spinner from '../../components/Spinner/Spinner';
import TrailerDetails from '../../components/Trailers/TrailerDetails';
import RouterDetails from '../../components/Routers/RouterDetails';
import RouterLinks from '../../components/Routers/RouterLinks';
import RoutersVehicleSelector from '../../components/Routers/RoutersVehicleSelector';
import TrailerStatus from '../../components/Trailers/TrailerStatus';
import TrailerClaims from '../../components/Trailers/TrailerClaims';
import roles from '../../roles';
import routes from '../../routes';
import ErrorModal from '../../components/Modal/ErrorModal';
import { Badge } from '../../components/Trailers/styles';
import Button from '../../components/Forms/Button';
import { AddNoteIcon } from '../../components/Icons';
import InputModal from '../../components/Modal/InputModal';
import { HistoryLogStore } from '../../stores/HistoryLogStore';

interface Props {
	trailer?: TrailerStore;
	users?: UserStore;
	store?: RouterStore;
	logs?: HistoryLogStore;
}

interface State {
	loading?: boolean;
	errorOpen: boolean;
	errorMessage: string;
	noteModalOpen: boolean;
}

@inject('trailer', 'users', 'store', 'logs')
@observer
class TrailerContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.customer_service,
		roles.assembly_line,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			errorOpen: false,
			errorMessage: '',
			noteModalOpen: false,
		};
	}

	componentDidMount() {
		this.props.users.clearUsers();
		this.props.trailer.clearCurrentTrailer();
		this.loadTrailer();
	}

	loadTrailer() {
		this.setState({ loading: true });

		this.props.trailer.getTrailerById(this.props.store.router.params.id)
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Trailer: ${err.message}`,
				});
			})
			.then(() => {
				return this.props.trailer.loadResinDeviceInfo(this.props.trailer.currentTrailer.get('resinUuid'));
			})
			.then(_ => this.setState({ loading: false }))
			.catch(_ => this.setState({ loading: false }));
	}

	searchUsers(filter: string) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		return this.props.users.loadUsers(0, 5, filter, null)
			.then(() => {
				this.setState({
					loading: false,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Users: ${err.message}`,
				});
			});
	}

	renderRouterDetails(router) {
		if (!router) {
			return (
				<h3>No Router Information Available</h3>
			);
		}

		return (
			<div>
				<Link
					view={routes.routerDetail}
					store={this.props.store}
					params={{ id: router.objectId }}
					style={{ float: 'right', fontSize: '14px', textDecoration: 'underline' }}
				>
					Go To Router
				</Link>
				<h3>
					About The Router
					<RouterLinks serial={router.serial} style={{ display: 'inline-block', marginLeft: '20px' }} />
				</h3>
				<RouterDetails
					router={router}
					editable={false}
					onInfoReveal={this.onInfoReveal.bind(this)}
				/>
			</div>
		);
	}

	claimTrailer(qrcode: string, userId: string) {
		this.setState({ loading: true });

		return this.props.trailer.claimTrailer(qrcode, userId)
			.then(result => {
				if (!result) {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: 'Failed to claim trailer',
					});
					return;
				}

				return this.loadTrailer();
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Claiming Trailer: ${err.message}`,
				});
			});
	}

	unclaimTrailer(trailerId: string) {
		this.setState({ loading: true });
		
		return this.props.trailer.unclaimTrailer(trailerId)
			.then(result => {
				if (!result) {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: 'Failed to unclaim trailer',
					});
					return;
				}

				return this.loadTrailer();
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Unclaiming Trailer: ${err.message}`,
				});
			});
	}

	onSearchFilterChange(value: string) {
		this.searchUsers(value);
	}

	onInfoReveal(field: string) {
		const vehicleId = this.props.trailer.currentTrailer.id;

		this.props.logs.saveDataRevealedLog(vehicleId, field)
			.catch(err => {
				console.log(`Error saving reveal location log: ${err.message}`);
			});
	}

	onSaveCustomNote(note: string) {
		this.setState({ loading: true, noteModalOpen: false });

		this.props.logs.saveCustomNote(null, this.props.trailer.currentTrailer.id, note)
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Adding Note: ${err.message}`,
				});
			});
	}

	render() {
		const trailer = this.props.trailer.currentTrailer;
		const resinDevice = this.props.trailer.resinDevice;

		if (!trailer) {
			return (
				<div>
					<h2>About This Trailer</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		const searchedUsers = this.props.users.users.map(u => u.user.toJSON());
		const router = trailer.get('router') ? trailer.get('router').toJSON() : null;
		const claimed = trailer.get('user') ? true : false;
		const users = claimed ? [ trailer.get('user').toJSON() ] : searchedUsers;
		const online = trailer.get('online');

		return (
			<div>
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<h2>About This Trailer {(!trailer.get('resinUuid') && !trailer.get('czoneSerial')) && <Badge>AFTERMARKET</Badge>} <TrailerStatus online={online} /></h2>
					<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1 }}>
						<Button
							className="transparent medium link"
							style={{ float: 'right', marginTop: '5px' }}
							onClick={() => this.setState({ noteModalOpen: true })}
						>
							<AddNoteIcon style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Add Note
						</Button>
						<RoutersVehicleSelector
							router={this.props.store.router}
							vehicleId={this.props.trailer.currentTrailer.id}
						/>
					</div>
				</div>
				<Spinner loading={this.state.loading} />

				<TrailerDetails
					trailer={trailer.toJSON()}
					onInfoReveal={this.onInfoReveal.bind(this)}
					resinDevice={resinDevice}
				/>

				{this.renderRouterDetails(router)}

				<h3>Trailer Owner</h3>
				<p>A trailer owner is a user who has claimed ownership of this trailer. The trailer owner is able to access this trailer from the mobile app.</p>
				<TrailerClaims
					users={users}
					claimed={claimed}
					onUserFilterChange={this.onSearchFilterChange.bind(this)}
					onTrailerClaim={this.claimTrailer.bind(this, trailer.get('qrcode'))}
					onTrailerUnclaim={this.unclaimTrailer.bind(this, trailer.id)}
				/>

				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
				<InputModal
					isOpen={this.state.noteModalOpen}
					onCancel={() => this.setState({ noteModalOpen: false })}
					okLabel="Save Note"
					title="Add Note"
					message="This note will be stored in the History, and will be visible by Customer Service Representatives."
					onOk={this.onSaveCustomNote.bind(this)}
					type="textarea"
				/>
			</div>
		);
	}
}

export default TrailerContainer;
