import * as React from 'react';
import { observer } from 'mobx-react';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import Card from '../Details/Card';
import EditableCard from '../Details/EditableCard';
import { TransparentButton, SaveButton } from './styles';
import { EditIcon, CheckIcon } from '../Icons';

interface Props {
	user: any;
	onUserUpdate: any;
}

interface State {
	editing: boolean;
	inputsChanged: boolean;
	firstName: string;
	lastName: string;
	email: string;
	zipcode: string;
	phone: string;
	bio: string;
}

@observer
class UserDetails extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = this.getState(false, false);
	}

	onInputChange(key, value) {
		const newState = {
			inputsChanged: true,
		};
		newState[key] = value;

		this.setState(newState);
	}

	onEditClick() {
		this.setState(this.getState(true, false));
	}

	onCancelClick() {
		this.setState(this.getState(false, false));
	}

	getState(editing: boolean, changed: boolean): State {
		return {
			editing: editing,
			inputsChanged: changed,
			firstName: this.props.user.firstName,
			lastName: this.props.user.lastName,
			email: this.props.user.email,
			zipcode: this.props.user.zipcode,
			phone: this.props.user.phone,
			bio: this.props.user.bio,
		};
	}

	onSaveClick() {
		const changes: any = {};

		Object.keys(this.state).forEach(key => {
			if (key === 'editing' || key === 'inputsChanged') {
				return;
			}

			if (this.state[key] !== this.props.user[key]) {
				changes[key] = this.state[key];
			}
		});

		this.props.onUserUpdate(changes);
		this.setState({
			editing: false,
			inputsChanged: false,
		});
	}

	render() {
		const buttons = this.state.editing ? (
			<div style={{ float: 'right', marginTop: '-40px' }}>
				<TransparentButton
					onClick={this.onCancelClick.bind(this)}
				>
					Cancel
				</TransparentButton>
				<SaveButton
					onClick={this.onSaveClick.bind(this)}
					disabled={!this.state.inputsChanged}
				>
					<CheckIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Save Changes
				</SaveButton>
			</div>
		) : (
			<div style={{ float: 'right', marginTop: '-35px' }}>
				<TransparentButton
					onClick={this.onEditClick.bind(this)}
				>
					<EditIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Edit User
				</TransparentButton>
			</div>
		);

		return (
			<div>
				{buttons}

				<DetailsHeader>User Details</DetailsHeader>
				<CardContainer>
					<Card label="Username" value={this.props.user.username} />

					<EditableCard
						label="First Name"
						id="firstName"
						value={this.state.firstName}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						className="last"
						label="Last Name"
						id="lastName"
						value={this.state.lastName}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label={'Email' + (this.props.user.emailVerified ? ' (VERIFIED)' : ' (UNVERIFIED)')}
						id="email"
						value={this.state.email}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="Zip Code"
						id="zipcode"
						value={this.state.zipcode}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						className="last"
						label="Phone Number"
						id="phone"
						value={this.state.phone}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						className="last"
						label="Bio"
						id="bio"
						value={this.state.bio}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
				</CardContainer>
			</div>
		);
	}
}

export default UserDetails;
