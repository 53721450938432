import styled from 'styled-components';
import Button from '../Forms/Button';

export const FilterContainer = styled.div`
	display: flex;
	flex: 1;
	margin: 0 0 15px;
	justify-content: space-between;
	align-items: flex-end;
`;

export const FilterSearch = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-size: small;
	min-width: 240px;
	width: 240px;
	justify-content: flex-start;
	flex: 1;
`;

export const TransparentButton = styled(Button)`
	background-color: transparent;
	color: #5988a6;
	padding: 4px 10px;
	font-size: 14px;

	&:hover {
		background-color: transparent;
		color: #2a546f;
	}

	&:active {
		background-color: transparent;
		color: #2a546f;
	}

	&:focus {
		outline: 0;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3em;
`;

export const ActionCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin: 1em 1em 0 0;
	flex: 1 1 auto;
	width: 0;
	min-width: 200px;

	h3 {
		font-size: 14px;
		margin: 0;
		font-weight: 500;
		margin-bottom: 4px;
		color: #444;
	}

	p {
		font-size: 14px;
	}
`;

export const Badge = styled.span`
	display: inline-block;
	padding: 2px 8px;
	color: white;
	background-color: #4a984a;
	border-radius: 4px;
	font-size: 10px;

	&.gray {
		background-color: gray;
	}
`;

export const LogsPanel = styled.div`
	padding: 6px 12px;
	background-color: #333333;
	color: #b9b9b9;
	overflow-x: auto;
	overflow-y: auto;
	font-size: 14px;
	height: 500px;

	div {
		height: 100%;
		width: 100%;
		padding: 0;
		margin: 0;
	}

	p {
		margin: 3px 0;
		padding: 0;
	}

	p span.ts {
		color: #888888;
	}

	p span.info {
		color: #58aa23;
	}

	p span.error {
		color: #ea3d3d;
	}

	p span.system {
		color: #bdce8e;
	}
`;

export const SwitchLabel = styled.label`
	float: right;

	span {
		margin-right: 6px;
	}

	.auto_log_switch {
		vertical-align: middle;
	}
`;