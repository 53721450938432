import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as Promise from 'bluebird';
import { RouterStore } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import RolesConst from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import RoutersVehicleSelector from '../../components/Routers/RoutersVehicleSelector';
import CardContainer from '../../components/Details/CardContainer';
import ButtonCard from '../../components/Details/ButtonCard';
import TrailerStore from '../../stores/TrailerStore';
import UserStore from '../../stores/UserStore';
import roles from '../../roles';

interface Props {
    trailer?: TrailerStore;
	users?: UserStore;
	store?: RouterStore;
}

interface State {
	loading?: boolean;
    errorOpen: boolean;
    errorMessage: string;
}

@inject('trailer', 'users', 'store')
@observer
class VehicleManagementContainer extends React.Component<Props, State> {

    static allowedRoles = [
		roles.admin,
		roles.customer_service,
		roles.assembly_line,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
            errorOpen: false,
            errorMessage: '',
		};
	}

    componentDidMount() {
		this.props.users.clearUsers();
		this.props.trailer.clearCurrentTrailer();
		this.loadTrailer();
	}

	loadTrailer() {
		this.setState({ loading: true });

		this.props.trailer.getTrailerById(this.props.store.router.params.vehicleId)
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Trailer: ${err.message}`,
				});
			})
			.then(() => {
				return this.props.trailer.loadResinDeviceInfo(this.props.trailer.currentTrailer.get('resinUuid'));
			})
			.then(_ => this.setState({ loading: false }))
			.catch(_ => this.setState({ loading: false }));
	}

    restartVehicle = () => {
        const vehicleId = this.props.trailer?.currentTrailer?.get('trailerId');

        this.setState({ loading: true });
        this.props.trailer.restartVehicle(vehicleId)
            .catch(response => {
                this.setState({
                    errorOpen: true,
                    errorMessage: response.message,
                });
            }).finally(() => {
                this.setState({ loading: false });
            });
    }

	render() {
		const vehicleId = this.props.trailer?.currentTrailer?.get('trailerId');

		return (
			<div>
				<div style={{  display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<h2>Manage Vehicle</h2>
                        <RoutersVehicleSelector
                            router={this.props.store.router}
                            vehicleId={vehicleId}
                        />
				</div>
				<Spinner loading={this.state.loading} />
                <ErrorModal
                    isOpen={this.state.errorOpen}
                    message={this.state.errorMessage}
                    onClose={() => this.setState({ errorOpen: false })}
                />
                <CardContainer>
                    <ButtonCard
                        label="Restart Vehicle's Smart System"
                        buttonLabel="Restart"
                        buttonAction={this.restartVehicle}
                    />
				</CardContainer>
			</div>
		);
	}
}

export default VehicleManagementContainer;
