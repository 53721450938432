import * as React from 'react';

import { ContentRow, Cell } from './styles';
import { TableColumn, RowData } from './Table';

interface Props {
	data: RowData;
	columns: TableColumn[];
}

class Row extends React.Component<Props, {}> {

	render() {
		const cells = this.props.columns.map((col, index) => {
			const cellData = this.props.data[col.key];
			const cell = col.render ? <col.render data={cellData} /> : cellData;

			return (
				<Cell key={index} style={col.cellStyle}>
					{cell}
				</Cell>
			);
		});

		return (
			<ContentRow>
				{cells}
			</ContentRow>
		);
	}
}

export default Row;
