import * as React from 'react';

const AddNoteIcon = ({ fill = null, style = {} }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 12 12"
		aria-labelledby="title"
		style={style}
	>
		<title>Add Note</title>
		<g fill={fill}>
			<path
				d="M11.35,3.15 L8.35,0.15 C8.25,0.05 8.125,0 8,0 L0.5,0 C0.225,0 0,0.225 0,0.5 L0,11.5 C0,11.775 0.225,12 0.5,12 L11,12 C11.275,12 11.5,11.775 11.5,11.5 L11.5,3.5 C11.5,3.375 11.45,3.25 11.35,3.15 Z M8.5,1.7 L9.8,3 L8.5,3 L8.5,1.7 Z M1,11 L1,1 L7.5,1 L7.5,3.5 C7.5,3.775 7.725,4 8,4 L10.5,4 L10.5,11 L1,11 Z"
				id="Shape"
				fillRule="nonzero"
			/>
			<path
				d="M6,6 L7.5,6 C7.77614237,6 8,6.22385763 8,6.5 C8,6.77614237 7.77614237,7 7.5,7 L6,7 L6,8.5 C6,8.77614237 5.77614237,9 5.5,9 C5.22385763,9 5,8.77614237 5,8.5 L5,7 L3.5,7 C3.22385763,7 3,6.77614237 3,6.5 C3,6.22385763 3.22385763,6 3.5,6 L5,6 L5,4.5 C5,4.22385763 5.22385763,4 5.5,4 C5.77614237,4 6,4.22385763 6,4.5 L6,6 Z"
				id="Combined-Shape"
			/>
		</g>
	</svg>
);

export default AddNoteIcon;