import * as React from 'react';
import { observer } from 'mobx-react';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import EditableCard from '../Details/EditableCard';
import { TransparentButton, SaveButton } from '../Users/styles';
import { EditIcon, CheckIcon } from '../Icons';

interface Props {
	model: any;
	newModel?: boolean;
	editable: boolean;
	onModelUpdate?: (changes: any) => void;
}

interface State {
	editing: boolean;
	inputsChanged: boolean;
	year: number;
	name: string;
	length: number;
	gvwr: number;
	gcwr: number;
	dryWeight: number;
	hitchWeight: number;
	hitchBallHeight: number;
	manual: string;
}

@observer
class TrailerModelDetails extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = this.getState(false, false);
	}

	onInputChange(key, value) {
		const newState = {
			inputsChanged: true,
		};
		newState[key] = value;

		this.setState(newState);
	}

	onEditClick() {
		this.setState(this.getState(true, false));
	}

	onCancelClick() {
		this.setState(this.getState(false, false));
	}

	getState(editing: boolean, changed: boolean): State {
		return {
			editing: this.props.newModel || editing,
			inputsChanged: changed,
			year: this.props.model.year,
			name: this.props.model.name,
			length: this.props.model.length,
			gvwr: this.props.model.gvwr,
			gcwr: this.props.model.gcwr,
			dryWeight: this.props.model.dryWeight,
			hitchWeight: this.props.model.hitchWeight,
			hitchBallHeight: this.props.model.hitchBallHeight,
			manual: this.props.model.manual,
		};
	}

	onSaveClick() {
		const changes: any = {};

		Object.keys(this.state).forEach(key => {
			if (key === 'editing' || key === 'inputsChanged') {
				return;
			}

			const value = this.state[key];
			if (value !== this.props.model[key]) {
				if (isNaN(value)) {
					changes[key] = value;
				} else {
					changes[key] = parseFloat(value);
				}
			}
		});

		this.props.onModelUpdate(changes);
		this.setState({
			editing: false,
			inputsChanged: false,
		});
	}

	renderButtons() {
		const cancelButton = !this.props.newModel ? (
			<TransparentButton
				onClick={this.onCancelClick.bind(this)}
			>
				Cancel
			</TransparentButton>
		) : '';

		return (this.props.newModel || this.state.editing) ? (
			<div style={{ float: 'right', marginTop: '-40px' }}>
				{cancelButton}
				<SaveButton
					onClick={this.onSaveClick.bind(this)}
					disabled={!this.state.inputsChanged}
				>
					<CheckIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Save Changes
				</SaveButton>
			</div>
		) : (
			<div style={{ float: 'right', marginTop: '-35px' }}>
				<TransparentButton
					onClick={this.onEditClick.bind(this)}
				>
					<EditIcon style={{ width: '18px', height: '18px', marginRight: '8px' }} /> Edit Model
				</TransparentButton>
			</div>
		);
	}

	render() {
		return (
			<div>
				{this.props.editable && this.renderButtons()}

				<DetailsHeader>Model Details</DetailsHeader>
				<CardContainer>
					<EditableCard
						label="Year"
						id="year"
						value={this.state.year}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Name"
						id="name"
						value={this.state.name}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Length"
						id="length"
						className="last"
						value={this.state.length}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="GVWR"
						id="gvwr"
						value={this.state.gvwr}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="GCWR"
						id="gcwr"
						value={this.state.gcwr}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Dry Weight"
						id="dryWeight"
						className="last"
						value={this.state.dryWeight}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="Hitch Weight"
						id="hitchWeight"
						className="half"
						value={this.state.hitchWeight}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
					<EditableCard
						label="Hitch Ball Height"
						id="hitchBallHeight"
						className="half last"
						value={this.state.hitchBallHeight}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>

					<EditableCard
						label="Owner's Manual"
						id="manual"
						className="half last"
						value={this.state.manual}
						editing={this.state.editing}
						onChange={this.onInputChange.bind(this)}
					/>
				</CardContainer>
			</div>
		);
	}
}

export default TrailerModelDetails;
