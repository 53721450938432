import * as React from 'react';

const CheckIcon = ({ fill = null, style = {} }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 13 10"
		aria-labelledby="title"
		style={style}
	>
		<title>Check</title>
		<g fill={fill}>
			<path
				d="M4.28343755,8.87163197 C4.2670619,8.97123089 4.22046466,9.066827 4.14364583,9.14364583 C3.96682285,9.32046882 3.69051089,9.33716672 3.49489786,9.19373953 C3.44374117,9.16977283 3.39580681,9.13666277 3.35355339,9.09440935 C3.31580091,9.05665687 3.28534758,9.01436926 3.26219342,8.9693002 L0.353553391,6.06066017 C0.158291245,5.86539803 0.158291245,5.54881554 0.353553391,5.35355339 C0.548815536,5.15829124 0.865398026,5.15829124 1.06066017,5.35355339 L3.72398137,8.01687459 L11.3873026,0.353553391 C11.5825647,0.158291245 11.8991472,0.158291245 12.0944094,0.353553391 C12.2896715,0.548815536 12.2896715,0.865398026 12.0944094,1.06066017 L4.28343755,8.87163197 Z"
				id="Combined-Shape"
			/>
		</g>
	</svg>
);

export default CheckIcon;