import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import StatusIcon from '../Details/StatusIcon';

const Status = styled.span`
	font-size: small;
	font-weight: 400;
	color: #999;
	letter-spacing: .5px;
	margin: 0 0 0 15px;
`;

interface Props {
	verified: boolean;
}

@observer
class EmailStatus extends React.Component<Props, {}> {

	render() {
		let status = this.props.verified ? 'VERIFIED' : 'UNVERIFIED';

		return (
			<Status>
				<StatusIcon online={this.props.verified} />
				{status}
			</Status>
		);
	}
}

export default EmailStatus;
