import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import { Nav, Header, ItemsContainer } from './styles';
import MenuHeader from './MenuHeader';
import MenuItem from './MenuItem';
import * as icons from '../Icons';
import routes from '../../routes';

import OverviewContainer from '../../containers/OverviewContainer';
import TrailersListContainer from '../../containers/PhysicalTrailers/TrailersListContainer';
import ProvisioningContainer from '../../containers/PhysicalTrailers/ProvisioningContainer';
import UserContainer from '../../containers/Users/UserContainer';
import TrailerModelListContainer from '../../containers/TrailerModels/TrailerModelListContainer';
import RouterListContainer from '../../containers/Routers/RouterListContainer';
import FleetLocationsContainer from '../../containers/PhysicalTrailers/FleetLocationsContainer';
import InterfaceListContainer from '../../containers/NetworkInterfaces/InterfaceListContainer';

interface Props {
	store?: { router: RouterStore };
	userRoles: string[];
}

@inject('store')
@observer
class Menu extends React.Component<Props, {}> {

	getMenuItems(currentPath: string) {
		const menuItems: JSX.Element[] = [];

		if (this.isUserInRole(OverviewContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="overview"
					icon={icons.OverviewIcon}
					route={routes.overview}
					name="Overview"
					active={currentPath === routes.overview.path}
				/>);
		}

		if (this.isUserInRole(FleetLocationsContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="fleet"
					icon={icons.FleetLocationIcon}
					route={routes.fleetLocations}
					name="Fleet Locations"
					active={currentPath === routes.fleetLocations.path}
				/>);
		}

		if (this.isUserInRole(UserContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="user"
					icon={icons.UsersIcon}
					route={routes.users}
					name="Users"
					active={currentPath === routes.users.path}
				/>);
		}

		if (this.isUserInRole(TrailersListContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="trailers"
					icon={icons.AirstreamModelsIcon}
					route={routes.trailers}
					name="Trailers"
					active={currentPath === routes.trailers.path}
				/>);
		}

		if (this.isUserInRole(ProvisioningContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="provisioning"
					icon={icons.ProvisioningIcon}
					route={routes.trailerProvisioning}
					name="Trailer Provisioning"
					active={currentPath === routes.trailerProvisioning.path}
				/>);
		}

		if (this.isUserInRole(TrailerModelListContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="trailermodels"
					icon={icons.AirstreamModelsIcon}
					route={routes.trailerModels}
					name="Trailer Models"
					active={currentPath === routes.trailerModels.path}
				/>);
		}

		if (this.isUserInRole(RouterListContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="routers"
					icon={icons.DealerNetworkIcon}
					route={routes.routers}
					name="Peplink Routers"
					active={currentPath === routes.routers.path}
				/>);
		}

		if (this.isUserInRole(InterfaceListContainer.allowedRoles)) {
			menuItems.push(
				<MenuItem
					key="interfaces"
					icon={icons.DealerNetworkIcon}
					route={routes.interfaces}
					name="Network Interfaces"
					active={currentPath === routes.interfaces.path}
				/>);
		}

		return menuItems;
	}

	private isUserInRole(allowedRoles: string[]) {
		return this.props.userRoles.some(userRole => allowedRoles.indexOf(userRole) >= 0);
	}

	render() {
		const path = this.props.store.router.currentView.path;
		const menuItems = this.getMenuItems(path);

		return (
			<Nav>
				<Header>
					<MenuHeader />
				</Header>
				<ItemsContainer>
					<section>
						{menuItems}
					</section>
					<section>
						<MenuItem
							icon={icons.LogoutIcon}
							route={routes.logout}
							name="Log out"
							active={path === routes.logout.path}
						/>
					</section>
				</ItemsContainer>
			</Nav>
		);
	}
}

export default Menu;
