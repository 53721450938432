import * as React from 'react';
import styled from 'styled-components';

export const Wrapper = styled.div`
	text-align: center;
	margin: 10px 0 20px;
	padding: 10px 0;
	color: #fff;
	background-color: red;
	font-size: 14px;
`;

interface Props {
	visible: boolean;
	message: string;
}

class LoginError extends React.Component<Props, {}> {

	render() {
		if (!this.props.visible) {
			return null;
		}

		return (
			<Wrapper>{this.props.message}</Wrapper>
		);
	}
}

export default LoginError;