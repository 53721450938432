import * as React from 'react';
import * as Modal from 'react-modal';

import { ModalStyles, OkButton } from './styles';

Modal.setAppElement('#root');

interface Props {
	isOpen: boolean;
	message: string;
	onClose: any;
}

class ErrorModal extends React.Component<Props, {}> {

	onClose() {
		this.props.onClose();
	}

	onRequestClose() {
		this.props.onClose();
	}

	render() {
		return (
			<Modal
				isOpen={this.props.isOpen}
				style={ModalStyles}
				onRequestClose={this.onRequestClose.bind(this)}
			>
				<h2>Error</h2>
				<p>{this.props.message}</p>
				<div style={{ textAlign: 'center' }}>
					<OkButton onClick={this.props.onClose}>
						Close
					</OkButton>
				</div>
			</Modal>
		);
	}

}

export default ErrorModal;
