import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';
import moment = require('moment');

export interface EventLog {
	type: string;
	userId: string;
	userName: string;
	vehicleId: string;
	timestamp: moment.Moment;
	data: { [key: string]: any };
}

export class HistoryLogStore {

	@observable
	logs: EventLog[] = [];

	@observable
	total: number;

	@observable
	totalPages: number;

	@observable
	currentPage: number;

	loadUserLogs(userId: string, page: number, take: number): Promise<void> {
		const query = Parse.Cloud.run('getEventLogs', {
			userId,
			page,
			take,
		});

		return Promise.resolve(query)
			.then(result => {
				this.setLogs(result.events.map(event => ({
					type: event.type,
					userId: event.userId,
					userName: event.userName,
					vehicleId: event.vehicleId,
					timestamp: moment.unix(event.eventDate),
					data: event.data,
				})));
				this.setTotal(result.total);
				this.setTotalPages(result.pages);
				this.setCurrentPage(result.page);
			});
	}

	loadVehicleLogs(vehicleObjectId: string, page: number, take: number): Promise<void> {
		const query = Parse.Cloud.run('getEventLogs', {
			vehicleObjectId,
			page,
			take,
		});

		return Promise.resolve(query)
			.then(result => {
				this.setLogs(result.events.map(event => ({
					type: event.type,
					userId: event.userId,
					userName: event.userName,
					vehicleId: event.vehicleId,
					timestamp: moment.unix(event.eventDate),
					data: event.data,
				})));
				this.setTotal(result.total);
				this.setTotalPages(result.pages);
				this.setCurrentPage(result.page);
			});
	}

	saveDataRevealedLog(vehicleId: string, field: string): Promise<void> {
		const query = Parse.Cloud.run('saveLog', {
			field,
			action: 'data_revealed',
			trailerId: vehicleId,
		});

		return Promise.resolve(query);
	}

	saveCustomNote(userId: string, vehicleId: string, note: string): Promise<void> {
		const query = Parse.Cloud.run('saveLog', {
			userId,
			trailerId: vehicleId,
			action: 'free_note',
			note,
		});

		return Promise.resolve(query);
	}

	@action
	setLogs(logs: EventLog[]) {
		this.logs.length = 0;

		this.logs.push(...logs);
	}

	@action
	insertLog(log: EventLog) {
		this.logs.unshift(log);
	}

	@action
	setTotal(total: number) {
		this.total = total;
	}

	@action
	setTotalPages(totalPages: number) {
		this.totalPages = totalPages;
	}

	@action
	setCurrentPage(currentPage: number) {
		this.currentPage = currentPage;
	}

	@action
	clearLogs() {
		this.logs.length = 0;
	}
}
