import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';

import AccountStore from '../stores/AccountStore';
import { Background, Panel, Copyright } from '../components/Login/styles';
import * as backgroundImage from '../components/Login/login-background.jpg';
import InlineLogoPNG from '../components/Logo/InlineLogoPNG';

interface Props {
	account?: AccountStore;
	store?: RouterStore;
}

@inject('account', 'store')
@observer
class NotAuthorizedContainer extends React.Component<Props, {}> {

	render() {
		return (
			<Background style={{ backgroundImage: `url("${backgroundImage}")` }}>
				<Panel>
					<div className="logoHeaderSection">
						<InlineLogoPNG className="logo" />
						<div className="title" style={{ color: 'red', fontSize: '20px' }}>Not Authorized</div>
					</div>

					<div style={{ textAlign: 'center', marginTop: '16px' }}>
						<p>You are not authorized to access this page</p>
					</div>

					<Copyright>
						© 2018 Airstream, Inc.
						<br />
						A subsidiary of Thor Industries, Inc. All Rights Reserved.
					</Copyright>
				</Panel>
			</Background>
		);
	}
}

export default NotAuthorizedContainer;
