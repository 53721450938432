import styled from 'styled-components';

export const CardListItem = styled.li`
	flex: 1 1 auto;
	width: calc(33.3% - .5em);
	padding: 1em;
	margin: 0 10px 10px 0;
	background-color: #f3f1f1;
	height: 80px;

	&.half {
		width: calc(50% - .5em);
		flex: 1 1 auto;
	}

	&.last {
		margin-right: 0;
	}

	&:hover {
		background-color: #fff9dc;
		transition: all .16s ease;
	}
`;

export const CardLabel = styled.div`
	color: #617684;
	font-size: 12px;
	line-height: 1.4;
`;

export const CardValue = styled.div`
	color: #364854;
	font-size: medium;
	margin-top: 5px;

	em {
		font-style: normal;
		color: #ccc;
	}
`;