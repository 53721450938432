import * as React from 'react';

import { HeaderRow, Cell } from './styles';
import { TableColumn } from './Table';

interface Props {
	columns: TableColumn[];
}

class Header extends React.Component<Props, {}> {

	render() {
		const columns = this.props.columns.map((col, index) => (
			<Cell key={index} style={col.cellStyle}>
				{col.name}
			</Cell>
		));

		return (
			<HeaderRow>
				{columns}
			</HeaderRow>
		);
	}
}

export default Header;
