import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';

import { Table } from '../Table/Table';
import StatusIcon from '../Details/StatusIcon';
import routes from '../../routes';

interface Props {
	store?: RouterStore;
	onPrev?: any;
	onNext?: any;
	pagination?: boolean;
	trailers: any[];
	skip?: number;
	take?: number;
	total?: number;
}

@inject('store')
@observer
class TrailersTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: '',
				key: 'online',
				cellStyle: { flex: 0.35, overflowX: 'hidden' },
				render: ({ data }) => {
					if (typeof data === undefined) {
						data = 'n/a';
					}
					
					return (
						<StatusIcon online={data} />
					);
				},
			},
			{
				name: 'Trailer ID',
				key: 'id',
				cellStyle: { flex: 4, overflowX: 'hidden' },
				render: ({ data }) => (
					<Link
						view={routes.trailerDetail}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.trailerId}
					</Link>
				),
			},
			{
				name: 'QR Code',
				key: 'qr',
				cellStyle: { flex: 2, overflowX: 'hidden' },
			},
			{
				name: 'Trailer VIN',
				key: 'vin',
				cellStyle: { flex: 2, overflowX: 'hidden' },
			},
			{
				name: 'Model',
				key: 'model',
				cellStyle: { flex: 2, overflowX: 'hidden' },
				render: ({ data }) => {
					if (!data) {
						return (<span>none</span>);
					}

					return (
						<span>{data.year} {data.name} {data.length}</span>
					);
				},
			},
			{
				name: 'Router Serial',
				key: 'router',
				cellStyle: { flex: 2, overflowX: 'hidden' },
				render: ({ data }) => {
					if (!data) {
						return (<span>none</span>);
					}

					return (
						<span>{data.serial}</span>
					);
				},
			},
			{
				name: 'Owner',
				key: 'user',
				cellStyle: { flex: 3, overflowX: 'hidden' },
				render: ({ data }) => {
					if (!data) {
						return (
							<em style={{ color: '#999' }}>Unclaimed</em>
						);
					}

					return (
						<Link
							view={routes.userDetail}
							store={this.props.store}
							params={{ id: data.objectId }}
						>
							{data.lastName}, {data.firstName}
						</Link>
					);
				},
			},
		];
	}

	render() {
		const data = this.props.trailers.map(trailer => ({
			online: trailer.online,
			id: { id: trailer.objectId, trailerId: trailer.trailerId },
			qr: trailer.qrcode,
			vin: trailer.vin,
			model: trailer.model,
			router: trailer.router,
			user: trailer.user,
		}));

		return (
			<Table
				label="Trailers"
				data={data}
				columns={this.getColumns()}
				skip={this.props.skip}
				take={this.props.take}
				total={this.props.total}
				pagination={this.props.pagination}
				onPrev={this.props.onPrev}
				onNext={this.props.onNext}
			/>
		);
	}
}

export default TrailersTable;
