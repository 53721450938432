import * as React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
	color: #7f919c;
	cursor: pointer;
	background-color: #c8d3da;
	min-width: 100px;
	width: 100%;
	padding: 3px 6px;
	font-size: 19px;
	border-radius: 4px;
	text-transform: none;
	-webkit-transition: 0.2s ease-in-out;
	transition: 0.2s ease-in-out;

	&:hover {
		background-color: #095e90;
		color: #fff;
	}

	&:active {
		background-color: #095e90;
		color: #fff;
	}

	&:focus {
		outline: 0;
	}
`;

const AddButton = ({ onClick, disabled = false }) => (
	<StyledButton type="button" onClick={onClick} disabled={disabled}>
		<i className="far fa-plus-square"></i>
	</StyledButton>
);

export default AddButton;