import * as React from 'react';

import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Forms/Button';
import { ActionContainer, ActionCard, ActionButtonRow } from './styles';
import EmailStatus from './EmailStatus';

interface Props {
	emailVerified: boolean;
	onEmailVerification: () => void;
	onVerifyUserEmail: () => void;
	onPasswordReset: () => void;
	onDelete: () => void;
}

interface State {
	confirmOpen: boolean;
	confirmLabel: string;
	confirmTitle: string;
	confirmMessage: string;
	onConfirm: () => void;
}

class UserActions extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			confirmOpen: false,
			confirmLabel: '',
			confirmTitle: '',
			confirmMessage: '',
			onConfirm: null,
		};
	}
	
	onEmailVerificationClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Send Email',
			confirmTitle: 'Send Verification Email',
			confirmMessage: 'This will send a verification email to the user’s current, unverified email. Clicking the link in the email verifies its accuracy.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onEmailVerification();
			},
		});
	}

	onVerifyUserEmailClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Verify User Email',
			confirmTitle: 'Verify User Email Address',
			confirmMessage: 'This will manually verify the user\'s email address. Only use this if you are certain the user is using the email on file.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onVerifyUserEmail();
			},
		});
	}

	onPasswordResetClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Send Reset',
			confirmTitle: 'Send Password Reset',
			confirmMessage: 'An email will be sent to the client with instructions for resetting their password. A log message will be added to the User History.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onPasswordReset();
			},
		});
	}

	onDeleteClick() {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Delete User',
			confirmTitle: 'Delete User',
			confirmMessage: 'This will permanently and irrecoverably this delete this user’s account, and unlink any trailers from it. A log message will be added to the User History.',
			onConfirm: () => {
				this.setState({ confirmOpen: false });
				this.props.onDelete();
			},
		});
	}

	render() {
		return (
			<ActionContainer>
				<ActionCard>
					<h3>Password Reset</h3>
					<p>This will not reset the user’s password, but will send an email giving them the opportunity to reset it themselves.</p>
					<Button
						className="small"
						onClick={this.onPasswordResetClick.bind(this)}
					>
						Send Password Reset
					</Button>
				</ActionCard>
				<ActionCard>
					<h3>
						Email Verification Status
						<EmailStatus verified={this.props.emailVerified} />
					</h3>
					<p>Any new or modified email requires verification. The user is asked to click a link in the body of an email sent to the provided address.</p>
					<ActionButtonRow>
						<Button
							className="small"
							onClick={this.onEmailVerificationClick.bind(this)}
							disabled={this.props.emailVerified}
							>
							Send Verification Email
						</Button>
						<Button
							className="small"
							onClick={this.onVerifyUserEmailClick.bind(this)}
							disabled={this.props.emailVerified}
							style={{marginLeft: '10px'}}
							>
							Verify User Email Address
						</Button>
					</ActionButtonRow>
				</ActionCard>
				<ActionCard>
					<h3>Delete User</h3>
					<p>Deleting the user removes their record from the system, and unlinks them from all trailers permanently.</p>
					<Button
						className="small warning"
						onClick={this.onDeleteClick.bind(this)}
					>
						Delete User
					</Button>
				</ActionCard>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel={this.state.confirmLabel}
					onOk={this.state.onConfirm}
					onCancel={() => this.setState({ confirmOpen: false })}
					cancelButton={true}
				/>
			</ActionContainer>
		);
	}
}

export default UserActions;
