import * as React from 'react';

import { CardListItem, CardLabel, CardValue } from './styles';
import Input from '../Forms/Input';
import RevealableCard from './RevealableCard';

const EditableCard = ({
	id,
	className = '',
	label,
	value,
	editing = false,
	onChange,
	revealable = false,
	onReveal = null,
}) => {

	if (revealable && !editing) {
		return (
			<RevealableCard label={label} value={value} onReveal={onReveal} />
		);
	}

	const content = editing ?
		<Input
			id={id}
			placeholder={label}
			value={value}
			onChange={e => onChange(id, e.target.value)}
			style={{ marginTop: '4px', fontSize: '14px', padding: '5px' }}
		/> :
		<CardValue>{value || <em>N/A</em>}</CardValue>;

	return (
		<CardListItem className={className}>
			<CardLabel>{label}</CardLabel>
			{content}
		</CardListItem>
	);
};

export default EditableCard;