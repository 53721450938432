import styled from 'styled-components';

export const FilterContainer = styled.div`
	display: flex;
	flex: 1;
	margin: 0 0 15px;
	justify-content: space-between;
	align-items: flex-end;
`;

export const FilterSearch = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	font-size: small;
	min-width: 240px;
	width: 240px;
	justify-content: flex-start;
	flex: 1;
`;