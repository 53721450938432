import * as React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import routes from '../../routes';

const Selector = styled(Select)`
	width: 100%;
	max-width: 200px;
	background-color: white;
	font-weight: 300;
	font-size: small;
    margin-top: 10px;
    padding-bottom: 10px;

	.Select-value {
		color: #095e90 !important;
		font-weight: 400;
	}
`;

enum TrailerSubpage {
    Root = 'root',
    Logs = 'logs',
    Manage = 'manage',
}

export default ({ router, vehicleId }) => {
    const onSelectorChange = (event) => {
		switch (event.value) {
			case TrailerSubpage.Logs:
				router.goTo(routes.trailerHistoryLogs, { vehicleId });
				break;
			case TrailerSubpage.Manage:
				router.goTo(routes.vehicleManagement, { vehicleId });
				break;
			case TrailerSubpage.Root:
			default:
				router.goTo(routes.trailerDetail, { id: vehicleId });
		}
	};

    const getOptions = () => {
        const options = [];
        options.push({ label: 'Vehicle Overview', value: TrailerSubpage.Root });
        options.push({ label: 'View History Logs', value: TrailerSubpage.Logs });
        options.push({ label: 'Manage Vehicle', value: TrailerSubpage.Manage });

        return options;
    };

    const getCurrentView = () => {
        if (router.currentView.path.includes(TrailerSubpage.Logs)) {
            return 'logs';
        } else if (router.currentView.path.includes(TrailerSubpage.Manage)) {
            return 'manage';
        }
        return 'root';
    };

    return (
        <Selector
            name="role-selector"
            value={getCurrentView()}
            onChange={event => onSelectorChange(event)}
            options={getOptions()}
            clearable={false}
            searchable={false}
        />
    );
};
