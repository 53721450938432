import * as React from 'react';
import styled from 'styled-components';

const InfoPanel = styled.div`
	padding: 12px 20px;
	margin: 20px 0;
	border: 1px solid #aaaaaa;
	border-radius: 8px;
`;

export default InfoPanel;
