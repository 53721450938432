import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore } from 'mobx-router';
import Select from 'react-select';
import styled from 'styled-components';

import Spinner from '../../components/Spinner/Spinner';
import roles from '../../roles';
import ErrorModal from '../../components/Modal/ErrorModal';
import CzoneConfigStore from '../../stores/CzoneConfigStore';
import TrailerModelStore from '../../stores/TrailerModelStore';
import ConfigActions from '../../components/Configs/ConfigActions';
import ConfigDetails from '../../components/Configs/ConfigDetails';
import TrailerStore from '../../stores/TrailerStore';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import TrailerModelDetails from '../../components/TrailerModels/TrailerModelDetails';

const Selector = styled(Select)`
	width: 50%;
	background-color: white;
	font-weight: 300;
	font-size: small;
	margin-bottom: 16px;
`;

interface Props {
	store?: RouterStore;
	config?: CzoneConfigStore;
	models?: TrailerModelStore;
	trailer?: TrailerStore;
}

interface State {
	loading: boolean;
	selectedModel: any;
	confirmTitle: string;
	confirmOpen: boolean;
	confirmMessage: string | JSX.Element;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('config', 'store', 'models', 'trailer')
@observer
class ConfigContainer extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			loading: false,
			selectedModel: null,
			confirmTitle: '',
			confirmOpen: false,
			confirmMessage: '',
			errorOpen: false,
			errorMessage: '',
		};
	}

	static allowedRoles = [
		roles.admin,
	];

	componentDidMount() {
		this.props.config.setCurrent(null);
		this.load();
	}

	load() {
		this.setState({ loading: true });

		return this.props.config.getById(this.props.store.router.params.id)
			.then(_ => this.setState({ loading: false }))
			.catch(err => {
				this.setState({ loading: false });
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Czone Config: ${err.message}`,
				});
			});
	}

	onUpdate(values) {
		this.setState({ loading: true });

		return this.props.config.update(this.props.config.current.id, values)
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Updating Czone Config: ${err.message}`,
				});
			});
	}

	updateTrailer(trailerId: string) {
		this.setState({ loading: true });

		return this.props.trailer.updateTrailerConfig(this.props.config.current, trailerId)
			.then(_ => {
				this.setState({
					loading: false,
					confirmOpen: true,
					confirmTitle: 'Trailer Updated',
					confirmMessage: `Trailer ID '${trailerId}' has been set to update to this config.`,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error updating trailer: ${err.message}`,
				});
			});
	}

	onDownloadClick() {
		this.setState({ loading: true });

		return this.props.config.getDownloadLink(this.props.config.current.id)
			.then(url => {
				this.setState({
					loading: false,
					confirmOpen: true,
					confirmTitle: 'Download Link',
					confirmMessage: <a href={url}>Download Config</a>,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error generating download link: ${err.message}`,
				});
			});
	}

	render() {
		let config = this.props.config.current;

		if (!config) {
			return (
				<div>
					<h2>About Czone Config</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		return (
			<div>
				<h2>About Czone Config</h2>
				<Spinner loading={this.state.loading} />
				<ConfigDetails config={config.toJSON()} onUpdate={this.onUpdate.bind(this)} />

				<ConfigActions
					updateTrailer={this.updateTrailer.bind(this)}
					onDownloadClick={this.onDownloadClick.bind(this)}
				/>

				<h2>About Trailer Model</h2>
				<TrailerModelDetails
					editable={false}
					model={config.get('model').toJSON()}
				/>

				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel="Ok"
					onOk={() => this.setState({ confirmOpen: false })}
					cancelButton={false}
				/>
			</div>
		);
	}
}

export default ConfigContainer;
