import { observable, action } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';

interface ResinDevice {
	id: number;
	uuid: string;
	commit: string;
	online: boolean;
	last_connected: string;
	os: string;
	downloading: number | null;
	app_id: string;
	app_commt: string;
}

class TrailerStore {

	@observable
	trailers: Parse.Object[] = [];

	@observable
	userTrailers: Parse.Object[] = [];

	@observable
	currentTrailer: Parse.Object;

	@observable
	resinDevice: ResinDevice;

	@observable
	count: number = 0;

	loadTrailers(skip: number, take: number, filter: string): Promise<boolean> {
		const options: any = {
			skip: skip,
			limit: take,
		};

		if (filter) {
			options.searchTerm = filter;
		}

		const query = Parse.Cloud.run('getPhysicalTrailers', options);

		return Promise.resolve(query)
			.then(result => {
				this.setTrailers(result.trailers);
				this.setCount(result.count);

				return true;
			});
	}

	provisionTrailer(router: string, qrcode: string, czone: string, vin: string): Promise<any> {
		const query = Parse.Cloud.run('provisionTrailer', {
			router,
			qrcode,
			czone,
			vin,
		});

		return Promise.resolve(query);
	}

	// This can be used for initial provision and for updating
	provisionAftermarketTrailerWithRouter(router: string, qrcode: string, vin: string): Promise<any> {
		const query = Parse.Cloud.run('provisionAftermarketTrailerWithRouter', {
			serial: router,
			qrcode,
			vin,
		});

		return Promise.resolve(query);
	}

	updateTrailer(trailerId: string, router: string, qrcode: string, czone: string, vin: string): Promise<any> {
		const query = Parse.Cloud.run('updateTrailerProvision', {
			trailerId,
			qrcode,
			vin,
			serial: router,
			czoneSerial: czone,
		});

		return Promise.resolve(query);
	}

	getTrailerById(trailerId: string): Promise<Parse.Object> {
		const query = Parse.Cloud.run('getPhysicalTrailerById', {
			id: trailerId,
		});

		return Promise.resolve(query)
			.then(trailer => {
				if (!trailer) {
					throw new Error('Trailer not found');
				}

				this.setCurrentTrailer(trailer);
				return trailer;
			});
	}

	getTrailersForUser(userId: string): Promise<void> {
		const user = new Parse.User();
		user.id = userId;

		const query = new Parse.Query('physical_trailer')
			.include('model')
			.include('router')
			.equalTo('user', user)
			.find();

		return Promise.resolve(query)
			.then(trailers => {
				this.setUserTrailers(trailers);
			});
	}

	claimTrailer(qrcode: string, userId: string): Promise<boolean> {
		const query = Parse.Cloud.run('claimTrailer', {
			userId,
			code: qrcode,
		});

		return Promise.resolve(query)
			.then(result => {
				return result.success;
			});
	}

	unclaimTrailer(trailerId: string): Promise<boolean> {
		const query = Parse.Cloud.run('unclaimTrailer', {
			objectId: trailerId,
		});

		return Promise.resolve(query)
			.then(result => {
				return true;
			});
	}

	lookupTrailerByVin(vin: string): Promise<void> {
		const query = new Parse.Query('physical_trailer')
			.equalTo('vin', vin)
			.include('model')
			.include('router')
			.include('user')
			.first();

		return Promise.resolve(query)
			.then(trailer => {
				if (!trailer) {
					throw new Error('Trailer not found');
				}

				this.setCurrentTrailer(trailer);
			});
	}

	loadResinDeviceInfo(uuid: string): Promise<void> {
		const query = Parse.Cloud.run('getResinDeviceDetails', {
			uuid,
		});

		return Promise.resolve(query)
			.then(result => {
				this.setResinDevice(result);
			});
	}

	updateTrailerConfig(config: Parse.Object, trailerId: string) {
		const query = new Parse.Query('physical_trailer')
			.equalTo('trailerId', trailerId)
			.first();

		return Promise.resolve(query)
			.then(trailer => {
				if (!trailer) {
					throw new Error('Trailer not found');
				}

				return Promise.resolve(trailer.save({
					pinned_config: config,
				}));
			});
	}

	restartVehicle(vehicleId: string) {
		return new Promise((fulfilled, rejected) => {
			Parse.Cloud.run('restartVehicleFromPortal', {
				vehicleId,
			}).then(restartResponse => {
				const responseBody = JSON.parse(restartResponse.result);

				if (responseBody[0].statusCode !== 'Success') {
					rejected(new Error('Unable to restart vehicle'));
				} else {
					fulfilled();
				}
			});
		});
	}

	@action
	setTrailers(trailers: Parse.Object[]) {
		this.trailers.length = 0;

		this.trailers.push(...trailers);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

	@action
	setCurrentTrailer(trailer: Parse.Object) {
		this.currentTrailer = trailer;
	}

	@action
	setUserTrailers(trailers: Parse.Object[]) {
		this.userTrailers.length = 0;
		this.userTrailers.push(...trailers);
	}

	@action
	setResinDevice(device: ResinDevice) {
		this.resinDevice = device;
	}

	@action
	clearCurrentTrailer() {
		this.currentTrailer = null;
		this.resinDevice = null;
	}
}

export default TrailerStore;
