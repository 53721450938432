import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';
import * as Promise from 'bluebird';

import UserStore from '../../stores/UserStore';
import TrailerStore from '../../stores/TrailerStore';
import Spinner from '../../components/Spinner/Spinner';
import UserDetails from '../../components/Users/UserDetails';
import TrailersTable from '../../components/Trailers/TrailersTable';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import roles from '../../roles';
import UserActions from '../../components/Users/UserActions';
import routes from '../../routes';
import ErrorModal from '../../components/Modal/ErrorModal';
import Button from '../../components/Forms/Button';
import InputModal from '../../components/Modal/InputModal';
import { AddNoteIcon } from '../../components/Icons';
import { HistoryLogStore } from '../../stores/HistoryLogStore';

interface Props {
	users?: UserStore;
	store?: RouterStore;
	trailer?: TrailerStore;
	logs?: HistoryLogStore;
}

interface State {
	loading?: boolean;
	doneTitle: string;
	doneMessage: string;
	doneOpen: boolean;
	errorOpen: boolean;
	errorMessage: string;
	noteModalOpen: boolean;
}

@inject('users', 'store', 'trailer', 'logs')
@observer
class UserContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.customer_service,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: true,
			doneOpen: false,
			doneTitle: '',
			doneMessage: '',
			errorOpen: false,
			errorMessage: '',
			noteModalOpen: false,
		};

		this.props.users.setCurrentUser(null);
		this.props.trailer.setUserTrailers([]);
	}

	componentDidMount() {
		Promise.all([
			this.props.users.getUserById(this.props.store.router.params.id),
			this.props.trailer.getTrailersForUser(this.props.store.router.params.id),
		])
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Page: ${err.message}`,
				});
			});
	}

	onUserUpdate(values) {
		this.setState({ loading: true });

		this.props.users.updateUser(this.props.users.currentUser.id, values)
			.then(() => this.setState({
				loading: false,
				doneOpen: true,
				doneTitle: 'User Updated',
				doneMessage: 'User has been updated successfully.',
			}))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error updating user: ${err.message}`,
				});
			});
	}

	onEmailVerification() {
		this.setState({ loading: true });

		this.props.users.sendEmailVerification(this.props.users.currentUser.getEmail())
			.then(() => this.setState({
				loading: false,
				doneOpen: true,
				doneTitle: 'Email Sent',
				doneMessage: 'Verification email has been sent successfully.',
			}))
			.catch(err => {
				const error = err.response.data.error;

				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error sending verification email: ${error}`,
				});
			});
	}

	onManuallyVerifyUserEmail() {
		this.setState({ loading: true });

		this.props.users.setUserEmailVerified(this.props.users.currentUser.id)
			.then(() => this.setState({
				loading: false,
				doneOpen: true,
				doneTitle: 'Email Verified',
				doneMessage: 'User\'s email has been set to verified.',
			}))
			.catch(err => {
				const error = err.response.data.error;

				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error setting email to verified: ${error}`,
				});
			});
	}

	onPasswordReset() {
		this.setState({ loading: true });

		this.props.users.resetPassword(this.props.users.currentUser.getEmail())
			.then(() => this.setState({
				loading: false,
				doneOpen: true,
				doneTitle: 'Reset Sent',
				doneMessage: 'Password reset has been sent successfully.',
			}))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error resetting password: ${err.message}`,
				});
			});
	}

	onDelete() {
		this.setState({ loading: true });

		this.props.users.deleteUser(this.props.users.currentUser.id)
			.then(() => {
				this.props.store.router.goTo(routes.users);
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error deleting user: ${err.message}`,
				});
			});
	}

	onSaveCustomNote(note: string) {
		this.setState({ loading: true, noteModalOpen: false });

		this.props.logs.saveCustomNote(this.props.users.currentUser.id, null, note)
			.then(() => this.setState({ loading: false }))
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Adding Note: ${err.message}`,
				});
			});
	}

	render() {
		const user = this.props.users.currentUser;
		const userTrailers = this.props.trailer.userTrailers;
		let trailers = [];

		if (user === null) {
			return (
				<div>
					<h2>User</h2>
					<Spinner loading={this.state.loading} />
					<ErrorModal
						isOpen={this.state.errorOpen}
						message={this.state.errorMessage}
						onClose={() => this.setState({ errorOpen: false })}
					/>
				</div>
			);
		}

		if (userTrailers) {
			trailers = userTrailers.map(t => {
				const trailer = t.toJSON();
				trailer.user = user.toJSON();

				return trailer;
			});
		}

		return (
			<div>
				<Link
					view={routes.userHistoryLogs}
					store={this.props.store}
					style={{ float: 'right', marginTop: '8px', marginRight: '110px' }}
					params={{ userId: user.id }}
				>
					View History Logs
				</Link>
				<Button
					className="transparent medium link"
					style={{ float: 'right', marginTop: '5px' }}
					onClick={() => this.setState({ noteModalOpen: true })}
				>
					<AddNoteIcon style={{ width: '16px', height: '16px', marginRight: '8px' }} /> Add Note
				</Button>
				<h2>{user.get('lastName')}, {user.get('firstName')}</h2>
				<Spinner loading={this.state.loading} />

				<UserDetails
					user={user.toJSON()}
					onUserUpdate={this.onUserUpdate.bind(this)}
				/>
				
				<UserActions
					emailVerified={user.get('emailVerified').toString() === 'true'}
					onEmailVerification={this.onEmailVerification.bind(this)}
					onVerifyUserEmail={this.onManuallyVerifyUserEmail.bind(this)}
					onPasswordReset={this.onPasswordReset.bind(this)}
					onDelete={this.onDelete.bind(this)}
				/>

				<h2>Trailers paired to {user.get('username')}</h2>
				<TrailersTable
					trailers={trailers}
					pagination={false}
				/>

				<ConfirmModal
					isOpen={this.state.doneOpen}
					title={this.state.doneTitle}
					message={this.state.doneMessage}
					okLabel="Ok"
					onOk={() => this.setState({ doneOpen: false })}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
				<InputModal
					isOpen={this.state.noteModalOpen}
					onCancel={() => this.setState({ noteModalOpen: false })}
					okLabel="Save Note"
					title="Add Note"
					message="This note will be stored in the History, and will be visible by Customer Service Representatives."
					onOk={this.onSaveCustomNote.bind(this)}
					type="textarea"
				/>
			</div>
		);
	}
}

export default UserContainer;
