import { observable, action, runInAction } from 'mobx';
import * as Parse from 'parse';
import * as Promise from 'bluebird';
import axios from 'axios';
import { resolve } from 'url';

interface User {
	user: Parse.User;
	trailers: number;
	roles: Parse.Role[];
}

class UserStore {

	@observable
	users: User[] = [];

	@observable
	currentUser: Parse.User;

	@observable
	roles: Parse.Role[] = [];

	@observable
	count: number = 0;

	loadUsers(skip: number, take: number, filter: string, role: Parse.Role): Promise<boolean> {
		let options: any = {
			skip: skip,
			limit: take,
		};

		if (filter) {
			options.filter = filter;
		}

		if (role) {
			options.role = role.id;
		}

		const query = Parse.Cloud.run('getAllUsersWithRolesAndTrailersCount', options);

		return Promise.resolve(query)
			.then(result => {
				this.setCount(result.count);
				this.setUsers(result.users.map(user => {
					return {
						user: user[0],
						trailers: user[2],
						roles: user[1],
					};
				}));

				return true;
			});
	}

	getUserById(id: string): Promise<void> {
		const query = Parse.Cloud.run('adminGetUserById', {
			id,
		});

		return Promise.resolve(query)
			.then(user => {
				this.setCurrentUser(user);
			});
	}

	loadRoles(): Promise<boolean> {
		const query = new Parse.Query(Parse.Role)
			.find();

		return Promise.resolve(query)
			.then(roles => {
				this.setRoles(roles);

				return true;
			});
	}

	getRoleById(id: string): Parse.Role {
		let role: Parse.Role;

		this.roles.forEach(r => {
			if (r.id === id) {
				role = r;
			}
		});

		return role;
	}

	updateUserRoles(userId: string, roles: string[]): Promise<boolean> {
		let user: User;
		this.users.forEach(u => {
			if (u.user.id === userId) {
				user = u;
			}
		});

		if (roles.length > user.roles.length) {
			return this.addUserToRoles(user, roles);
		}

		return this.removeUserFromRoles(user, roles);
	}

	addUserToRoles(user: User, newRoles: string[]): Promise<boolean> {
		let promises = this.roles.map(role => {
			if (newRoles.indexOf(role.id) === -1) {
				return null;
			}

			role.getUsers().add(user.user);
			return Promise.resolve(role.save());
		});

		return Promise.all(promises)
			.then((savedRoles: Parse.Role[]) => {
				runInAction(() => {
					user.roles.length = 0;
					user.roles.push(...savedRoles.filter(r => r));
				});
				return true;
			});
	}

	removeUserFromRoles(user: User, newRoles: string[]): Promise<boolean> {
		let promises = this.roles.map(role => {
			if (newRoles.indexOf(role.id) !== -1) {
				return null;
			}

			role.getUsers().remove(user.user);
			return Promise.resolve(role.save());
		});

		return Promise.all(promises)
			.then(() => {
				runInAction(() => {
					user.roles.length = 0;
					user.roles.push(...this.roles.filter(r => newRoles.indexOf(r.id) !== -1));
				});

				return true;
			});
	}

	updateUser(id: string, values): Promise<void> {
		const query = Parse.Cloud.run('saveUserDetails', {
			userId: id,
			firstName: values.firstName,
			lastName: values.lastName,
			email: values.email,
			zipcode: values.zipcode,
			phone: values.phone,
			bio: values.bio,
		});

		return Promise.resolve(query)
			.then(response => {
				this.setCurrentUser(response.savedUser);
			});
	}

	deleteUser(id: string): Promise<void> {
		const query = Parse.Cloud.run('adminDeleteUserById', {
			userId: id,
		});

		return Promise.resolve(query)
			.then(() => {
				this.setCurrentUser(null);
			});
	}

	resetPassword(email: string): Promise<void> {
		return Promise.resolve(Parse.User.requestPasswordReset(email))
			.then(() => {
				return;
			});
	}

	sendEmailVerification(email: string): Promise<void> {
		return Promise.resolve(Parse.Session.current())
			.then(session => {
				return Promise.resolve(axios({
					url: `${Parse.serverURL}/verificationEmailRequest`,
					method: 'post',
					data: {
						email: email,
					},
					headers: {
						'X-Parse-Application-Id': Parse.applicationId,
						'X-Parse-Session-Token': session.getSessionToken(),
					},
				}));
			})
			.then(() => {
				return;
			});
	}

	setUserEmailVerified(id: string): Promise<void> {
		const query = Parse.Cloud.run('manuallyVerifyUserEmail', {
			userId: id,
		});

		return Promise.resolve(query)
			.then(response => {
				this.setCurrentUser(response.savedUser);
			});
	}

	@action
	clearUsers() {
		this.users.length = 0;
	}

	@action
	setRoles(roles: Parse.Role[]) {
		this.roles.length = 0;

		this.roles.push(...roles);
	}

	@action
	setUsers(users: User[]) {
		this.users.length = 0;

		this.users.push(...users);
	}

	@action
	setCount(count: number) {
		this.count = count;
	}

	@action
	setCurrentUser(user: Parse.User) {
		this.currentUser = user;
	}
}

export default UserStore;
