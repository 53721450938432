import styled from 'styled-components';
import { Link } from 'mobx-router';

export const UploadFormContainer = styled.div`
	.field {
		margin: 0 0 20px;

		label {
			margin: 0;
			display: inline-block;
			width: 200px;
			text-align: left;
			box-sizing: border-box;
			padding: 12px 0 0 12px;
			vertical-align: top;
			font-weight: 400;
			font-size: 14px;
			height: 40px;
		}

		input {
			margin: 0;
			display: inline-block;
			background-color: #fff;
			height: 40px;
			width: 50%;
			max-width: 500px;
			margin-right: 20px;
			border: 1px solid #ccc;
			font-weight: 400;
			font-size: 14px;
			padding: 0 14px;
			outline: 0;

			&:focus {
				background-color: #f5f4f4;
				outline: 0;
			}
		}
	}
`;

export const TextArea = styled.textarea`
	margin: 0;
	background-color: #fff;
	height: 150px;
	width: 50%;
	max-width: 500px;
	margin-right: 20px;
	border: 1px solid #ccc;
	font-weight: 400;
	font-size: 14px;
	padding: 14px;
	outline: 0;

	&:focus {
		background-color: #f5f4f4;
		outline: 0;
	}
`;

export const ActionContainer = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 3em;
`;

export const ActionCard = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	margin: 1em 1em 0 0;
	flex: 1 1 auto;
	width: 0;
	min-width: 200px;

	h3 {
		font-size: 14px;
		margin: 0;
		font-weight: 500;
		margin-bottom: 4px;
		color: #444;
	}

	p {
		font-size: 14px;
	}
`;

export const ModelLink = styled(Link)`
	border: 1px solid #c2e0ff;
	border-radius: 4px;
	background-color: #ebf5ff;
	padding: 4px 5px;
	margin-right: 4px;
	text-decoration: none;
`;