import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';

import Spinner from '../../components/Spinner/Spinner';
import TrailerModelsTable from '../../components/TrailerModels/TrailerModelsTable';
import roles from '../../roles';
import TrailerModelStore from '../../stores/TrailerModelStore';
import routes from '../../routes';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import { AddNoteIcon } from '../../components/Icons';
import ErrorModal from '../../components/Modal/ErrorModal';

interface Props {
	store?: RouterStore;
	models?: TrailerModelStore;
}

interface State {
	loading?: boolean;
	skip: number;
	take: number;
	confirmOpen: boolean;
	confirmLabel: string;
	confirmMessage: string;
	modelToDelete: string;
	errorOpen: boolean;
	errorMessage: string;
}

@inject('models', 'store')
@observer
class TrailerModelListContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			skip: 0,
			take: 15,
			confirmOpen: false,
			confirmLabel: '',
			confirmMessage: '',
			modelToDelete: '',
			errorOpen: false,
			errorMessage: '',
		};
	}

	componentDidMount() {
		this.loadModels(this.state.skip, this.state.take);
	}

	loadModels(skip: number, take: number) {
		if (this.state.loading) {
			return;
		}

		this.setState({ loading: true });

		this.props.models.loadTrailerModels(skip, take)
			.then(() => {
				this.setState({
					loading: false,
					skip,
					take,
				});
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Loading Models: ${err.message}`,
				});
			});
	}

	onPrev() {
		let skip = this.state.skip - this.state.take;
		if (skip < 0) {
			skip = 0;
		}

		this.loadModels(skip, this.state.take);
	}

	onNext() {
		let skip = this.state.skip + this.state.take;
		if (skip > this.props.models.count) {
			skip = this.props.models.count - this.state.take;
		}

		this.loadModels(skip, this.state.take);
	}

	onDelete(id: string) {
		this.setState({
			confirmOpen: true,
			confirmLabel: 'Delete',
			confirmMessage: 'Delete this trailer model?',
			modelToDelete: id,
		});
	}

	deleteModel() {
		this.setState({ loading: true, confirmOpen: false });

		this.props.models.deleteModel(this.state.modelToDelete)
			.then(() => {
				this.setState({ loading: false, modelToDelete: '' });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Deleting Model: ${err.message}`,
					modelToDelete: '',
				});
			});
	}

	render() {
		const models = this.props.models.models.map(m => m.toJSON());

		return (
			<div>
				<h1>Trailer Models</h1>
				<Link
					view={routes.newTrailerModel}
					store={this.props.store}
					style={{ float: 'right', marginTop: '-40px', textDecoration: 'none' }}
				>
					<AddNoteIcon style={{ width: '16px', height: '16px', marginRight: '4px' }} /> Add New Trailer Model
				</Link>
				<Spinner loading={this.state.loading} />
				<TrailerModelsTable
					models={models}
					pagination={true}
					skip={this.state.skip}
					take={this.state.take}
					total={this.props.models.count}
					onPrev={this.onPrev.bind(this)}
					onNext={this.onNext.bind(this)}
					onDelete={this.onDelete.bind(this)}
				/>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title="Delete Model"
					message={this.state.confirmMessage}
					okLabel={this.state.confirmLabel}
					onOk={this.deleteModel.bind(this)}
					onCancel={() => this.setState({ confirmOpen: false })}
					cancelButton={true}
				/>
				<ErrorModal
					isOpen={this.state.errorOpen}
					message={this.state.errorMessage}
					onClose={() => this.setState({ errorOpen: false })}
				/>
			</div>
		);
	}
}

export default TrailerModelListContainer;
