import * as React from 'react';

const InlineLogo = ({ fill = null, className = '' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 900 85"
    aria-labelledby="title"
    className={className}
    height="22px"
    width="228px"
  >
    <title>Airstream Logo</title>
    <g fill={fill}>
      <path d="M21.82,66.19,46.4,0S25,1.26,20.86,2.44C18.12,3,6.42,4.66,1.76,18.66,1.31,20.43-.83,37.61.35,46.2.94,49.53,1.83,63.89,7.76,65A115.29,115.29,0,0,0,21.82,66.19Z" />
      <path d="M123.4,62c-1.63-.11-9,0-9,0h-.31c2.76-4.67,3.37-13.35,3.82-15.84,1.18-8.59-1-25.76-1.41-27.54C111.8,4.66,100.1,3,97.36,2.44,93.21,1.26,71.82,0,71.82,0l23,62h-.13l1.52,4.36h27.39a1.92,1.92,0,0,0,1.63-2.09S125,62.15,123.4,62Z" />
      <polygon points="58.99 20.83 66 43.93 51.88 43.93 58.99 20.83" />
      <path d="M58.94,54.06A15.52,15.52,0,1,0,74.46,69.58,15.52,15.52,0,0,0,58.94,54.06Zm0,21.89a6.37,6.37,0,1,1,6.37-6.37A6.37,6.37,0,0,1,58.94,75.95Z" />
      <path d="M164.5,2.59h19l23,63.25h-20.2l-2.66-9H163.62l-2.84,9H141.21Zm15.33,40.66-5.67-19.49H174l-6.11,19.49Z" />
      <path d="M214.48,2.59H234V65.85H214.48Z" />
      <path d="M249.29,2.59h37.12c11,0,20.64,6.11,20.64,18.07,0,6.56-3,13.47-9.48,15.86,5.32,2,8.59,7.88,9.3,15.77.26,3,.26,10.81,2.12,13.56H289.51a45.67,45.67,0,0,1-1.59-9.75c-.44-6-1.06-12.31-8.77-12.31H268.78V65.85H249.29Zm19.49,27.64H279c3.63,0,8.59-.62,8.59-6.29,0-4-2.21-6.29-9.65-6.29h-9.13Z" />
      <path d="M335.77,44.59a12.44,12.44,0,0,0,.71,4.16c1.42,3.81,5.67,4.61,9.3,4.61,3.19,0,8.06-1.06,8.06-5.76,0-3.19-2.75-4.16-13.64-7.26-10-2.84-21.44-5.58-21.44-18.34,0-14.62,12.49-20.91,25.51-20.91,13.73,0,26,5.31,26.4,20.64H352.24a4.71,4.71,0,0,0-2.3-5.05,10.12,10.12,0,0,0-5.76-1.59c-2.66,0-7,.71-7,4.25.27,4.43,9.48,5.67,18.34,8,8.95,2.57,17.81,6.56,17.81,18.25,0,16.48-15.06,21.8-29.24,21.8-7.09,0-27.82-2.57-27.82-22.77Z" />
      <path d="M397.7,18.81H380V2.59H434.9V18.81H417.19v47H397.7Z" />
      <path d="M445.63,2.59h37.12c11,0,20.64,6.11,20.64,18.07,0,6.56-3,13.47-9.48,15.86,5.32,2,8.59,7.88,9.3,15.77.26,3,.26,10.81,2.12,13.56H485.85a45.66,45.66,0,0,1-1.59-9.75c-.45-6-1.07-12.31-8.77-12.31H465.12V65.85H445.63Zm19.49,27.64h10.19c3.63,0,8.59-.62,8.59-6.29,0-4-2.21-6.29-9.65-6.29h-9.13Z" />
      <path d="M514.83,2.59h52.36V18.81H534.32v7.89h29.86V41.75H534.32v7.88h33.84V65.85H514.83Z" />
      <path d="M598.29,2.59h19l23,63.25h-20.2l-2.66-9H597.4l-2.84,9H575Zm15.33,40.66-5.67-19.49h-.18l-6.11,19.49Z" />
      <path d="M648.17,2.59h27.73l9,37.21h.18l9-37.21h27.73V65.85H703.45V25.27h-.17l-11,40.58H677.76l-11-40.58h-.18V65.85H648.17Z" />
    </g>
  </svg>
);

export default InlineLogo;
