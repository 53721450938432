const config = {
	dev: {
		url: 'http://localhost:1337/parse',
		applicationId: '5417a55b-bae2-40f5-87fb-16eac5ccd1a7',
	},
	staging: {
		url: 'https://api.smartcontrol-dev.airstream.com/parse',
		applicationId: '71786842-819a-4bad-a587-6ab32dd31c5c',
	},
	production: {
		url: 'https://api.smartcontrol.airstream.com/parse',
		applicationId: '5417a55b-bae2-40f5-87fb-16eac5ccd1a7',
	},
	production2: {
		url: 'https://api.smartcontrol-prod2.airstream.com/parse',
		applicationId: '5417a55b-bae2-40f5-87fb-16eac5ccd1a7',
	},
};

export default config;
