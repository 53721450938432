import * as React from 'react';

import { Container, Content } from './styles';
import Menu from '../SideMenu/Menu';

interface Props {
	content: any;
	userRoles: string[];
}

class SideMenuLayout extends React.Component<Props, {}> {

	render() {
		return (
			<Container>
				<aside>
					<Menu userRoles={this.props.userRoles} />
				</aside>
				<Content>
					<this.props.content />
				</Content>
			</Container>
		);
	}
}

export default SideMenuLayout;
