import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, RouterStore } from 'mobx-router';

import TrailerStore from '../../stores/TrailerStore';
import Spinner from '../../components/Spinner/Spinner';
import ProvisionForm from '../../components/Trailers/ProvisionForm';
import Button from '../../components/Forms/Button';
import roles from '../../roles';
import UpdateTrailerForm from '../../components/Trailers/UpdateTrailerForm';
import Input from '../../components/Forms/Input';
import ScannerInput from '../../components/Forms/ScannerInput';
import ConfirmModal from '../../components/Modal/ConfirmModal';
import routes from '../../routes';
import ErrorModal from '../../components/Modal/ErrorModal';
import TrailerRouterStore from '../../stores/TrailerRouterStore';
import TrailerModelStore from '../../stores/TrailerModelStore';
import InfoPanel from '../../components/Layout/InfoPanel';

interface Props {
	trailer?: TrailerStore;
	routers?: TrailerRouterStore;
	models?: TrailerModelStore;
	store?: RouterStore;
}

interface State {
	loading?: boolean;
	lookupvin: string;
	updating: boolean;
	doneOpen: boolean;
	doneMessage: string;
	errorOpen: boolean;
	errorMessage: string;
	trailerModel: string;
	balenaId: string;
	showDeviceNotFoundInfo: boolean;
}

@inject('trailer', 'routers', 'models', 'store')
@observer
class ProvisioningContainer extends React.Component<Props, State> {

	static allowedRoles = [
		roles.admin,
		roles.assembly_line,
	];

	constructor(props: Props) {
		super(props);

		this.state = {
			loading: false,
			updating: false,
			lookupvin: '',
			doneOpen: false,
			doneMessage: '',
			errorOpen: false,
			errorMessage: '',
			trailerModel: '',
			balenaId: '',
			showDeviceNotFoundInfo: false,
		};

		props.trailer.setCurrentTrailer(null);
	}

	onProvisionSubmit(router: string, qrcode: string, czone: string, vin: string, aftermarket: boolean) {
		if (this.state.loading) {
			return;
		}

		if (!router || !qrcode || !vin || !(aftermarket || czone)) {
			this.setState({
				errorOpen: true,
				errorMessage: 'Router SN, QR code, and VIN are required. CZone SN is required unless this is an Aftermarket installation.',
				showDeviceNotFoundInfo: false,
			});
			return;
		}
		
		this.setState({ loading: true });

		if (aftermarket) {
			this.props.trailer.provisionAftermarketTrailerWithRouter(router, qrcode, vin)
				.then(results => {
					this.setState({
						loading: false,
						doneOpen: true,
						doneMessage: 'Provision Aftermarket trailer completed successfully!',
						showDeviceNotFoundInfo: false,
					});
				})
				.catch(err => {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: `Error Provisioning Trailer: ${err.message}`,
						showDeviceNotFoundInfo: false,
					});
				});
		} else {
			this.props.trailer.provisionTrailer(router, qrcode, czone, vin)
				.then(results => {
					this.setState({
						loading: false,
						doneOpen: true,
						doneMessage: results.online ? 'Provision trailer completed successfully!' : 'WARNING: Provision completed successfully, but the trailer is offline. Please ensure the trailer is online so the process can complete.',
						balenaId: results.balenaId,
						showDeviceNotFoundInfo: false,
					});
				})
				.catch(err => {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: `Error Provisioning Trailer: ${err.message}`,
						showDeviceNotFoundInfo: err.message === 'Device not found in Balena',
					});
				});
		}

	}

	onUpdateSubmit(trailerId: string, router: string, qrcode: string, czone: string, vin: string, aftermarket: boolean) {
		if (this.state.loading) {
			return;
		}

		if (!router || !qrcode || !vin || !(aftermarket || czone)) {
			this.setState({
				errorOpen: true,
				errorMessage: 'Router SN, QR code, and VIN are required. CZone SN is required unless this is an Aftermarket installation.',
				showDeviceNotFoundInfo: false,
			});
			return;
		}

		this.setState({ loading: true });

		if (aftermarket) {
			this.props.trailer.provisionAftermarketTrailerWithRouter(router, qrcode, vin)
				.then(results => {
					this.setState({
						loading: false,
						doneOpen: true,
						doneMessage: 'Update Aftermarket trailer completed successfully!',
						showDeviceNotFoundInfo: false,
					});
				})
				.catch(err => {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: `Error Updating Trailer: ${err.message}`,
						showDeviceNotFoundInfo: false,
					});
				});
		} else {
			this.props.trailer.updateTrailer(trailerId, router, qrcode, czone, vin)
				.then(results => {
					this.setState({
						loading: false,
						doneOpen: true,
						doneMessage: results.online ? 'Update trailer completed successfully!' : 'WARNING: Provision completed successfully, but the trailer is offline. Please ensure the trailer is online so the process can complete.',
						showDeviceNotFoundInfo: false,
					});
				})
				.catch(err => {
					this.setState({
						loading: false,
						errorOpen: true,
						errorMessage: `Error Updating Trailer: ${err.message}`,
						showDeviceNotFoundInfo: err.message === 'Device not found in Balena',
					});
				});
		}

	}

	onUpdating() {
		this.props.trailer.setCurrentTrailer(null);
		this.setState({ updating: true, lookupvin: '' });
	}

	onProvisioning() {
		this.props.trailer.setCurrentTrailer(null);
		this.setState({ updating: false, lookupvin: '' });
	}

	onLookupVinChange(vin: string) {
		this.setState({ lookupvin: vin });
	}

	onVinLookup() {
		this.setState({ loading: true });

		this.props.trailer.lookupTrailerByVin(this.state.lookupvin)
			.then(() => {
				this.setState({ loading: false });
			})
			.catch(err => {
				this.setState({
					loading: false,
					errorOpen: true,
					errorMessage: `Error Looking up Trailer: ${err.message}`,
				});
			});
	}

	onRouterSerialChange(serial: string) {
		this.setState({ loading: true });

		this.props.routers.isValidRouterSerial(serial)
			.then(valid => {
				this.setState({ loading: false });

				if (!valid) {
					this.setState({
						errorOpen: true,
						errorMessage: `Router serial '${serial}' is not a valid Router.`,
					});
				}
			});
	}

	onTrailerVinChange(vin: string) {
		this.setState({ loading: true });

		this.props.models.getModelStringByVin(vin)
			.then(model => {
				this.setState({
					loading: false,
					trailerModel: model ? model : 'Unknown Trailer Model',
				});
			});
	}

	getDeviceNotFoundInfo() {
		if (!this.state.showDeviceNotFoundInfo) {
			return null;
		}

		return (
			<InfoPanel style={{ backgroundColor: '#ffeeee' }}>
				<h3 style={{ fontWeight: 'bold' }}>Provisioning Error</h3>
				<h3>The Touch7 or Network Interface device was not found in our system</h3>
				<p>This is an error because if we cannot locate the device in our system, we cannot complete the provisioning process. To fix this issue, please try the following steps:</p>
				<ul>
					<li>Make sure the device has internet.</li>
					<li>Make sure the device was powered on while connected to the internet. If the internet was connected after it was powered on, then power cycle the device.</li>
					<li>Wait at least 10-15 minutes after the device was powered on with internet before provisioning. This will give it time to finish downloading any updates and configure itself.</li>
				</ul>
				<p>After trying any of the above steps, please try to submit the "Provision a New Trailer" form again with the same values. Submitting it more than once will not cause any issues.</p>
				<p>If it is still not working, then escalate the issue to either Ryan Henderson or Jason Kriesel.</p>
			</InfoPanel>
		);
	}

	openVehicleInfo() {
		this.setState({ doneOpen: false });
		this.state.balenaId && this.props.store.router.goTo(routes.interfaceDetail, { id: this.state.balenaId });
	}

	render() {
		const trailer = this.props.trailer.currentTrailer;
		const lookupvin = this.state.lookupvin;

		const provisionButton = (
			<Button
				style={{ float: 'right' }}
				className="small secondary"
				onClick={this.onProvisioning.bind(this)}
			>
				Provision a Trailer
			</Button>
		);

		const modal = (
			<ConfirmModal
				isOpen={this.state.doneOpen}
				title="Completed"
				message={this.state.doneMessage}
				okLabel={this.state.balenaId ? "Open Vehicle Info" : "Ok"}
				onOk={this.openVehicleInfo.bind(this)}
			/>
		);
		const errorModal = (
			<ErrorModal
				isOpen={this.state.errorOpen}
				message={this.state.errorMessage}
				onClose={() => this.setState({ errorOpen: false })}
			/>
		);

		// If they clicked the update button, we show the lookup trailer by vin form.
		if (this.state.updating && !trailer) {
			return (
				<div>
					{provisionButton}

					<h1>Update a Trailer</h1>
					<p>Lookup a trailer by VIN number to update it's hardware.</p>
					<Input
						placeholder="Lookup trailer by VIN"
						value={lookupvin}
						style={{ width: '70%', marginRight: '16px' }}
						onChange={(e) => this.onLookupVinChange(e.target.value)} />
					<ScannerInput
						type="barcode"
						label="Trailer VIN Number"
						onScan={this.onLookupVinChange.bind(this)}
					/>
					<br /><br />

					<Button onClick={this.onVinLookup.bind(this)}>Lookup Trailer</Button>

					<Spinner loading={this.state.loading} />
					{modal}
					{errorModal}
				</div>
			);
		}

		// If they looked up a trailer, show the update form
		if (this.state.updating && trailer) {
			const router = trailer.get('router') ? trailer.get('router').get('serial') : '';

			return (
				<div>
					{provisionButton}

					<h1>Update a Trailer</h1>
					<p>Update this trailer's hardware by changing any value below</p>
					<p>
						<Link
							view={routes.trailerDetail}
							store={this.props.store}
							params={{ id: trailer.id }}
						>
							Trailer Details Page <i className="fas fa-external-link-alt" />
						</Link>
					</p>
					<UpdateTrailerForm
						router={router}
						qrcode={trailer.get('qrcode')}
						czone={trailer.get('czoneSerial')}
						vin={trailer.get('vin')}
						aftermarket={!!(trailer.get('czoneSerial'))}
						onRouterSerialChange={this.onRouterSerialChange.bind(this)}
						onTrailerVinChange={this.onTrailerVinChange.bind(this)}
						onSubmit={this.onUpdateSubmit.bind(this, trailer.id)}
						trailerModel={this.state.trailerModel}
					/>

					<Spinner loading={this.state.loading} />
					{this.getDeviceNotFoundInfo()}
					{modal}
					{errorModal}
				</div>
			);
		}

		// Show the provision a new trailer form.
		return (
			<div>
				<Button
					style={{ float: 'right' }}
					className="small secondary"
					onClick={this.onUpdating.bind(this)}
				>
					Lookup a Trailer
				</Button>

				<h1>Provision a New Trailer</h1>
				<p>Associate hardware to a new trailer.</p>
				<ProvisionForm
					onRouterSerialChange={this.onRouterSerialChange.bind(this)}
					onTrailerVinChange={this.onTrailerVinChange.bind(this)}
					onSubmit={this.onProvisionSubmit.bind(this)}
					trailerModel={this.state.trailerModel}
				/>

				<Spinner loading={this.state.loading} />
				{this.getDeviceNotFoundInfo()}
				{modal}
				{errorModal}
			</div>
		);
	}
}

export default ProvisioningContainer;
