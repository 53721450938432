import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { Link } from 'mobx-router';
import { RouterStore } from 'mobx-router';

import { Table } from '../Table/Table';
import routes from '../../routes';

interface Props {
	store?: RouterStore;
	onPrev?: any;
	onNext?: any;
	pagination?: boolean;
	routers: any[];
	skip?: number;
	take?: number;
	total?: number;
}

@inject('store')
@observer
class RoutersTable extends React.Component<Props, {}> {

	getColumns() {
		return [
			{
				name: 'Name',
				key: 'name',
				cellStyle: { flex: 3, overflowX: 'hidden' },
				render: ({ data }) => (
					<Link
						view={routes.routerDetail}
						store={this.props.store}
						params={{ id: data.id }}
					>
						{data.name}
					</Link>
				),
			},
			{
				name: 'Serial Number',
				key: 'serial',
				cellStyle: { flex: 2, overflowX: 'hidden' },
			},
			{
				name: 'IMEI',
				key: 'imei',
				cellStyle: { flex: 2, overflowX: 'hidden' },
			},
			{
				name: 'MAC Address',
				key: 'mac',
				cellStyle: { flex: 2, overflowX: 'hidden' },
			},
		];
	}

	render() {
		const data = this.props.routers.map(router => ({
			name: { name: router.name, id: router.objectId },
			serial: router.serial,
			imei: router.imei,
			mac: router.mac,
		}));

		return (
			<Table
				label="Routers"
				data={data}
				columns={this.getColumns()}
				skip={this.props.skip}
				take={this.props.take}
				total={this.props.total}
				pagination={this.props.pagination}
				onPrev={this.props.onPrev}
				onNext={this.props.onNext}
			/>
		);
	}
}

export default RoutersTable;
