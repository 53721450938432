import * as React from 'react';
import * as moment from 'moment';
import Switch from 'react-toggle-switch';
import 'react-toggle-switch/dist/css/switch.min.css';

import { LogsPanel, SwitchLabel } from './styles';

interface Props {
	logs: any;
}

interface State {
	autoScrollLogs: boolean;
}

const logRegex = new RegExp(/^[\d\s\-:,]+(INFO|ERROR)\s(.*)$/);
const logTsFormat = 'YYYY-MM-DD HH:mm:ss';

class InterfaceLogs extends React.Component<Props, State> {

	logDivRef: any = React.createRef();

	constructor(props) {
		super(props);

		this.state = {
			autoScrollLogs: true,
		};
	}

	setLogDivRef = element => {
		this.logDivRef = element;
	}

	renderLogLine(logjson) {
		const log = JSON.parse(logjson);

		if (log.message.startsWith('\t') ||
			log.message.startsWith('org.eclipse') ||
			log.message.startsWith('Caused by:')
		) {
			return null;
		}
		
		const ts = moment(log.ts);
		const matches = logRegex.exec(log.message);

		if (matches) {
			const level = matches[1];
			return (
				<p key={log.ts}>
					<span className="ts">{ts.format(logTsFormat)}</span>
					&nbsp;
				<span className={level.toLowerCase()}>{level}</span>
					&nbsp;
				{matches[2]}
				</p>
			);
		}

		if (log.system) {
			return (
				<p key={log.ts}>
					<span className="ts">{ts.format(logTsFormat)}</span>
					&nbsp;
					<span className="system">{log.message}</span>
				</p>
			);
		}

		return (
			<p key={log.ts}>
				<span className="ts">{ts.format(logTsFormat)}</span>
				&nbsp;
				{log.message}
			</p>
		);
	}

	componentDidUpdate() {
		var self = this;

		window.requestAnimationFrame(() => {
			if (self.logDivRef && self.state.autoScrollLogs) {
				this.logDivRef.scrollTop = this.logDivRef.scrollHeight;
			}
		});
	}

	onAutoScrollToggle() {
		this.setState(prevState => ({
			autoScrollLogs: !prevState.autoScrollLogs,
		}));
	}

	render() {
		return (
			<React.Fragment>
				<SwitchLabel htmlFor="auto-log-switch">
					<span>Auto-scroll logs</span>
					<Switch
						id="auto-log-switch"
						className="auto_log_switch"
						onClick={this.onAutoScrollToggle.bind(this)} on={this.state.autoScrollLogs}
					/>
				</SwitchLabel>
				<h3>Device Logs</h3>
				<LogsPanel innerRef={this.setLogDivRef}>
					{this.props.logs.get().map(this.renderLogLine)}
				</LogsPanel>
			</React.Fragment>
		);
	}
}

export default InterfaceLogs;
