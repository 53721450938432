import * as React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import StatusIcon from '../Details/StatusIcon';

const Status = styled.span`
	display: inline-block;
	align-items: center;
	font-size: small;
	color: #999;
	text-transform: uppercase;
	letter-spacing: .5px;
	margin: 12px 10px 0 0;
`;

interface Props {
	online: boolean | string;
}

@observer
class TrailerStatus extends React.Component<Props, {}> {

	render() {
		let status = this.props.online ? 'online' : 'offline';
		if (this.props.online === 'n/a') {
			status = 'unknown';
		}

		return (
			<Status>
				<StatusIcon online={this.props.online} />
				{status}
			</Status>
		);
	}
}

export default TrailerStatus;
