import * as React from 'react';
import styled from 'styled-components';

const Header = styled.h4`
	background-color: #095e90;
	color: white;
	margin: 0;
	padding: 3px 15px;
`;

const DetailsHeader = ({ children }) => (
	<Header>
		{children}
	</Header>
);

export default DetailsHeader;