import * as React from 'react';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';

import SubmitButton from '../Forms/SubmitButton';
import { UploadFormContainer, TextArea } from './styles';

interface FormValues {
	name: string;
	version: string;
	description: string;
}

interface Props {
	onSubmit: (name: string, version: string, description: string) => void;
}

class UploadConfigForm extends React.Component<Props, {}> {

	render() {
		const onSubmit = this.props.onSubmit;

		return (
			<UploadFormContainer>
				<Formik
					initialValues={{ name: '', version: '', description: '' }}
					onSubmit={(values: FormValues) => onSubmit(values.name, values.version, values.description)}
					render={(props: FormikProps<FormValues>) => {
						return (
							<Form>
								<Field
									name="name"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="name">Name</label>
											<input id="name" type="text" {...field} placeholder="Enter a name for this config" />
										</div>
									)}
								/>

								<Field
									name="version"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="version">Version Number</label>
											<input id="version" type="text" {...field} placeholder="Enter a version number" />
										</div>
									)}
								/>

								<Field
									name="description"
									render={({ field }: FieldProps<FormValues>) => (
										<div className="field">
											<label htmlFor="description">Description</label>
											<TextArea id="description" {...field} placeholder="Description of changes" />
										</div>
									)}
								/>

								<SubmitButton>Upload Config</SubmitButton>
							</Form>
						);
					}}
				/>
			</UploadFormContainer>
		);
	}
}

export default UploadConfigForm;
