import * as React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
	width: 100%;
	border: 1px solid #ccc;
	background-color: #fff;
	padding: 9px;
	border-radius: 3px;
	outline: 0;

	&:focus {
		outline: 0;
	}
`;

const Input = ({ onChange, id = '', placeholder = '', value = '', type = 'text', style = {} }) => (
	<StyledInput
		id={id}
		type={type}
		placeholder={placeholder}
		value={value}
		onChange={onChange}
		style={style}
	/>
);

export default Input;