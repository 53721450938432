import * as React from 'react';
import styled from 'styled-components';

import { CardListItem, CardLabel, CardValue } from './styles';

const RevealButton = styled.button`
	color: #666666;
	text-decoration: underline;
	cursor: pointer;
	font-size: 16px;
	padding: 0;
	margin: 5px 0 0 0;

	&:hover {
		color: #333333;
		text-decoration: underline;
	}

	&:active {
		color: #333333;
		text-decoration: underline;
		outline: 0;
	}
`;

interface Props {
	className?: string;
	label: string;
	value: string;
	onReveal: () => void;
}

interface State {
	visible: boolean;
}

class RevealableCard extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			visible: false,
		};
	}

	onReveal() {
		this.setState({ visible: true });

		this.props.onReveal();
	}

	render() {
		const value = this.state.visible ?
			<CardValue>{this.props.value}</CardValue> :
			<RevealButton type="button" onClick={this.onReveal.bind(this)}>
Click to Reveal</RevealButton>;

		return (
			<CardListItem className={this.props.className}>
				<CardLabel>{this.props.label}</CardLabel>
				{value}
			</CardListItem>
		);
	}
}

export default RevealableCard;