import * as React from 'react';
import { inject, observer } from 'mobx-react';
import * as moment from 'moment';
import { RouterStore, Link } from 'mobx-router';

import DetailsHeader from '../Details/DetailsHeader';
import CardContainer from '../Details/CardContainer';
import Card from '../Details/Card';
import RevealableCard from '../Details/RevealableCard';
import routes from '../../routes';

interface Props {
	store?: RouterStore;
	trailer?: any;
	resinDevice: any;
	onInfoReveal: (field: string) => void;
}

@inject('store')
@observer
class TrailerDetails extends React.Component<Props, {}> {

	renderResinDevice(resinDevice) {
		if (!resinDevice) {
			return (
				<h3>No Device Information Available</h3>
			);
		}

		let resinDeviceRelease = resinDevice.commit?.substr(0, 8) ?? 'None';
		if (resinDevice.downloading) {
			resinDeviceRelease += ` (Downloading ${resinDevice.downloading}%)`;
		} else if (resinDevice.commit === resinDevice.app_commit) {
			resinDeviceRelease += ` (current)`;
		} else {
			resinDeviceRelease += ` (not current)`;
		}

		return (
			<CardContainer>
				<Card label="Device ID" value={(<a href={`https://dashboard.resin.io/devices/${resinDevice.uuid}/summary`} target="_blank">{resinDevice.id} <i className="fas fa-external-link-alt" /></a>)} />
				<Card label="Current Release" value={resinDeviceRelease} />
				<Card className="last" label="OS Version" value={resinDevice.os} />
			</CardContainer>
		);
	}

	renderCzoneConfigLink(config: any, modelId: string) {
		if (!config) {
			return (<em>none</em>);
		}

		return (
			<Link
				view={routes.czoneConfigDetail}
				store={this.props.store}
				params={{ id: config.objectId, modelId }}
			>
				{config.name}
			</Link>
		);
	}

	render() {
		const trailer = this.props.trailer;
		const model = trailer.model;
		const location = trailer.location ? `${trailer.location.latitude}, ${trailer.location.longitude}` : 'none';
		const createdAt = moment(trailer.createdAt);
		const updatedAt = moment(trailer.updatedAt);
		const resinDevice = this.props.resinDevice;

		const configCardValue = this.props.trailer.current_config ? this.renderCzoneConfigLink(this.props.trailer.current_config, model.objectId) : null;
		const modelValue = model ? `${model.year} ${model.name} ${model.length}` : null;

		return (
			<div>
				<DetailsHeader>Trailer Details</DetailsHeader>
				<CardContainer>
					<Card label="Trailer ID" value={trailer.trailerId} />
					<Card label="Trailer QR Code" value={trailer.qrcode} />
					<Card className="last" label="Czone Config" value={configCardValue} />

					<Card label="Model" value={modelValue} />
					<Card label="Record Created" value={createdAt.format('llll')} />
					<Card className="last" label="Record Last Updated" value={updatedAt.format('llll')} />

					<RevealableCard label="Last Known Location" value={location} onReveal={() => this.props.onInfoReveal('location')} />
					<Card label="CZone Serial" value={trailer.czoneSerial} />
					<Card className="last" label="Trailer VIN" value={trailer.vin} />
				</CardContainer>

				<DetailsHeader>Trailer Device Details</DetailsHeader>
				{this.renderResinDevice(resinDevice)}
			</div>
		);
	}
}

export default TrailerDetails;
