import * as React from 'react';

import { CardListItem, CardLabel } from './styles';
import Button from '../Forms/Button';

const ButtonCard = ({
	className = '',
	label,
    buttonLabel,
    buttonAction,
}) => {

	const content = (
		<Button
            onClick={buttonAction}
            style={{ marginTop: 16 }}
        >
            {buttonLabel}
        </Button>
    );

	return (
		<CardListItem className={className} style={{ height: 'auto' }}>
			<CardLabel>{label}</CardLabel>
			{content}
		</CardListItem>
	);
};

export default ButtonCard;
