import * as React from 'react';
import * as Parse from 'parse';

import Input from '../Forms/Input';
import RoleSelector from '../Forms/RoleSelector';
import { FilterContainer, FilterSearch, FilterRole } from './styles';

interface Props {
	roles: Parse.Role[];
	selectedRole: Parse.Role;
	searchValue: string;
	onRoleFilterChange: (roles: any) => void;
	onSearchFilterChange: (value: any) => void;
}

interface State {
	filterValue: string;
}

class UsersFilter extends React.Component<Props, State> {

	private debounceTimer;

	constructor(props) {
		super(props);

		this.state = {
			filterValue: props.searchValue,
		};
	}

	renderRolesOptions() {
		return this.props.roles.map(item => <option>{item.getName()}</option>);
	}

	onFilterChange(e) {
		this.setState({ filterValue: e.target.value });

		// Debounce the props change callback
		if (this.debounceTimer) {
			clearTimeout(this.debounceTimer);
		}

		this.debounceTimer = setTimeout(() => {
			this.props.onSearchFilterChange(this.state.filterValue);
		}, 1000); // Only call the callback after no more changes in 1 second
	}

	render() {
		return (
			<FilterContainer>
				<FilterSearch>
					<Input
						id="search"
						placeholder="Search by name or email"
						onChange={this.onFilterChange.bind(this)}
						value={this.state.filterValue}
					/>
				</FilterSearch>
				<FilterRole>
					<label htmlFor="roles">Filter by role</label>
					<RoleSelector
						id="roles"
						roles={this.props.roles}
						selectedRoles={[this.props.selectedRole]}
						onRoleChange={this.props.onRoleFilterChange.bind(this)}
						multi={false}
						isFilter={true}
					/>
				</FilterRole>
			</FilterContainer>
		);
	}
}

export default UsersFilter;
