import styled from 'styled-components';

export const Background = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: #888888 no-repeat center/100%
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
`;

export const LoadingPanel = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 60px;
	height: 60px;
`;

export const Panel = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: 'Open Sans', sans-serif;
	background-color: #fcfcfc;
	border: 1px solid #eff0f1;
	border-radius: 5px;
	width: 400px;
	margin: 0 auto;
	overflow: hidden;

	.logo-header-section {
		padding: 10px;
	}

	.logo {
		display: block;
		margin: 30px 0px -10px 75px
		width: 60%;
		height: auto;
	}
	
	.title {
		color: #53BB4C;
		font-size: 16px;
		font-weight: 400;
		text-align: center;
		margin: 30px 0 0 0;
	}
`;

export const LoginContainer = styled.div`
	border-top: 1px solid #ebebeb;
	margin: 20px 0 20px 0;
	text-align: center;

	.field {
		margin: 0;
		border-bottom: 1px solid #ebebeb;

		label {
			margin: 0;
			display: inline-block;
			width: 30%;
			text-align: left;
			box-sizing: border-box;
			background-color: #fff;
			padding: 12px 0 0 20px;
			vertical-align: top;
			font-weight: 400;
			font-size: 14px;
			height: 40px;
			border-right: 1px solid #ebebeb;
		}

		input {
			margin: 0;
			display: inline-block;
			background-color: #f8f8f8;
			height: 40px;
			width: 70%;
			border: 0;
			font-weight: 400;
			font-size: 14px;
			padding: 0 14px;

			&:focus {
				background-color: #e2e2e2;
				outline: 0;
			}
		}
	}
`;

export const Copyright = styled.div`
	text-align: center;
	margin: 20px 0 20px 0;
	color: #9d9fab;
	font-size: 12px;
`;

export const FormLink = styled.button`
	color: #9497a0;
	font-size: 14px;
	text-decoration: none;
	cursor: pointer;
	margin: 20px 0 0 0;

	&:hover {
		text-decoration: underline;
	}

	&:focus {
		outline: 0;
	}
`;