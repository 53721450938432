import * as React from 'react';

import ConfirmModal from '../Modal/ConfirmModal';
import Button from '../Forms/Button';
import { ActionContainer, ActionCard } from './styles';
import InputModal from '../Modal/InputModal';

interface Props {
	updateTrailer: (id: string) => void;
	onDownloadClick: () => void;
}

interface State {
	confirmOpen: boolean;
	confirmLabel: string;
	confirmTitle: string;
	confirmMessage: string;
	onConfirm: () => void;
	inputModalOpen: boolean;
}

class ConfigActions extends React.Component<Props, State> {

	constructor(props) {
		super(props);

		this.state = {
			inputModalOpen: false,
			confirmOpen: false,
			confirmLabel: '',
			confirmTitle: '',
			confirmMessage: '',
			onConfirm: null,
		};
	}

	onUpdateTrailerClick(id: string) {
		this.setState({ inputModalOpen: false });
		this.props.updateTrailer(id);
	}

	render() {
		return (
			<ActionContainer>
				<ActionCard>
					<h3>Update Specific Trailer</h3>
					<p>This will update a specific trailer only with this config.</p>
					<Button
						className="small hollow"
						onClick={() => this.setState({ inputModalOpen: true })}
					>
						Update Trailer
					</Button>
				</ActionCard>
				<ActionCard>
					<h3>Generate Download Link</h3>
					<p>This will generate a temporary link that can be used to download a copy of the config.</p>
					<Button
						className="small hollow"
						onClick={this.props.onDownloadClick}
					>
						Get Download Link
					</Button>
				</ActionCard>

				<InputModal
					isOpen={this.state.inputModalOpen}
					onCancel={() => this.setState({ inputModalOpen: false })}
					okLabel="Update Trailer"
					title="Update Specific Trailer"
					message="Enter the Trailer ID of the trailer to update"
					onOk={this.onUpdateTrailerClick.bind(this)}
					type="text"
				/>
				<ConfirmModal
					isOpen={this.state.confirmOpen}
					title={this.state.confirmTitle}
					message={this.state.confirmMessage}
					okLabel={this.state.confirmLabel}
					onOk={this.state.onConfirm}
					onCancel={() => this.setState({ confirmOpen: false })}
					cancelButton={true}
				/>
			</ActionContainer>
		);
	}
}

export default ConfigActions;
