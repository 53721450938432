import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { RouterStore, Link } from 'mobx-router';
import * as Leaflet from 'leaflet';
import { Map, Marker, Popup, TileLayer } from 'react-leaflet';

import 'leaflet/dist/leaflet.css';

import routes from '../../routes';
import * as bluedot from '../Icons/BlueDot.svg';
import * as reddot from '../Icons/RedDot.svg';
import { PopupFieldLabel, PopupField } from './styles';

interface Props {
	store?: RouterStore;
	trailers: any;
}

interface State {
	lat: number;
	long: number;
	zoom: number;
	bounds: any;
}

@inject('store')
@observer
class LocationMap extends React.Component<Props, State> {

	private blueIcon: Leaflet.Icon;
	private redIcon: Leaflet.Icon;

	constructor(props) {
		super(props);

		this.state = {
			lat: 35.5,
			long: -95,
			zoom: 12,
			bounds: [[47.8, -125], [31.5, -67.8]],
		};

		this.blueIcon = Leaflet.icon({
			iconUrl: bluedot,
			iconSize: [12, 12], // size of the icon
		});
		this.redIcon = Leaflet.icon({
			iconUrl: reddot,
			iconSize: [12, 12], // size of the icon
		});
	}

	renderMarkers(trailers) {
		return trailers.map(trailer => {
			const position: Leaflet.LatLngExpression = [trailer.location.latitude, trailer.location.longitude];
			const icon = trailer.online ? this.blueIcon : this.redIcon;
			const email = trailer.user ?
				(
					<Link
						view={routes.userDetail}
						store={this.props.store}
						params={{ id: trailer.user.objectId }}
					>
						{trailer.user.firstName} {trailer.user.lastName}
					</Link>
				) : (<em>Unclaimed</em>);

			return (
				<Marker key={trailer.objectId} position={position} icon={icon} >
					<Popup minWidth={150} maxWidth={200} closeButton={false}>
						<div>
							<PopupFieldLabel>Customer:</PopupFieldLabel>
							<PopupField>{email}</PopupField>

							<PopupFieldLabel>Trailer:</PopupFieldLabel>
							<PopupField>
								<Link
									view={routes.trailerDetail}
									store={this.props.store}
									params={{ id: trailer.objectId }}
								>
									{trailer.model.year} {trailer.model.name} {trailer.model.length}
								</Link>
							</PopupField>
						</div>
					</Popup>
				</Marker>
			);
		});
	}

	render() {
		const trailers = this.props.trailers.filter(t => t.location && t.model);
		const position: Leaflet.LatLngExpression = [this.state.lat, this.state.long];

		return (
			<Map
				center={position}
				bounds={this.state.bounds}
				zoom={this.state.zoom}
				style={{ height: '500px', width: '100%', margin: '0' }}
			>
				<TileLayer
					attribution='&copy; <a href="http://osm.org/copyright" target="_blank">OpenStreetMap</a> contributors'
					url="http://{s}.tile.osm.org/{z}/{x}/{y}.png"
				/>
				{this.renderMarkers(trailers)}
			</Map>
		);
	}
}

export default LocationMap;
